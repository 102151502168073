import { useEffect, useLayoutEffect, useRef } from 'react';
import { ListItem } from '../Listitem';
import { VerticalMenu } from '../Menu/VerticalMenu';
import { DropdownSkeleton } from './DropdownSkeleton';
import { DropdownItem, useDropdownState } from './hooks';
import { isGroup } from './utils';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

type Props = {
  value: string;
  options: (DropdownItem | { group: string })[];
  onChange: (value?: string) => void;
  error?: string;
  isLoading?: boolean;
  backendSearch?: boolean;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
  clearable?: boolean;
  zIndex?: number;
  fullWidth?: boolean;
  disabled?: boolean;
};

export const Autocomplete = ({
  onChange,
  value,
  options,
  error,
  zIndex,
  clearable,
  backendSearch,
  isLoading,
  disabled,
  fullWidth,
  onSearchChange,
  searchValue,
}: Props) => {
  const {
    filter,
    filteredOptions,
    handleKeydown,
    isOpen,
    longestOption,
    selectionIndex,
    setFilter,
    setIsOpen,
    option,
  } = useDropdownState({
    onChange,
    options,
    value,
    filterEnabled: !backendSearch,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOpen = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1);
    }
  };

  return (
    <DropdownSkeleton
      error={error}
      zIndex={zIndex}
      disabled={disabled}
      fullWidth={fullWidth}
      selection={
        <div className="pointer-events-none relative flex flex-col text-gray-800">
          <div className="h-0 overflow-hidden truncate">
            <ListItem
              {...longestOption}
              subtitle={undefined}
              title={
                <>{longestOption?.title ?? longestOption?.value ?? ''}&nbsp;</>
              }
            />
          </div>
          <ListItem
            {...option}
            subtitle={undefined}
            title={<>{option?.title ?? option?.value ?? ''}&nbsp;</>}
          />
          {isOpen && (
            <div className="absolute inset-0 flex items-center">
              <input
                ref={inputRef}
                size={1}
                onClick={(e) => e.stopPropagation()}
                autoFocus
                onFocus={(e) => e.target.select()}
                value={searchValue ?? filter}
                className="w-full min-w-0 border-0 bg-primary-50 text-base outline-none ring-0"
                onKeyDown={(e) => {
                  if (
                    e.key !== 'ArrowDown' &&
                    e.key !== 'ArrowUp' &&
                    e.key !== 'Enter' &&
                    e.key !== 'Escape'
                  ) {
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  if (onSearchChange) return onSearchChange(e.target.value);
                  setFilter(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      }
      clearable={clearable}
      onClear={
        clearable && value.length > 0 ? () => onChange(undefined) : undefined
      }
      isOpen={isOpen}
      isEmpty={
        filteredOptions.filter((option) => !isGroup(option)).length === 0
      }
      onClose={() => handleOpen(false)}
      onOpen={() => handleOpen(true)}
      onKeyDown={handleKeydown}
      dropdown={
        isLoading ? (
          <div className="flex flex-col gap-1 p-1">
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </div>
        ) : filteredOptions.filter((option) => !isGroup(option)).length ===
          0 ? (
          <div className="bg-white">
            <ListItem title="No results..." disabled />
          </div>
        ) : (
          <VerticalMenu
            items={filteredOptions.map((option, index) => ({
              ...(isGroup(option)
                ? { group: option.group }
                : {
                    ...option,
                    title: option.title ?? option.value,
                    isFocused:
                      selectionIndex === index || value === option.value,
                    onClick: () => {
                      onChange(option.value);
                      setIsOpen(false);
                    },
                  }),
            }))}
          />
        )
      }
    />
  );
};
