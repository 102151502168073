import { useEffect, useState } from 'react';
import { isGroup } from './utils';
import { ListItemProps } from '../Listitem';

export type DropdownItem = ListItemProps & {
  value: any;
};

export const useDropdownState = ({
  onChange,
  options,
  value,
  filterEnabled = false,
}: {
  filterEnabled?: boolean;
  value: string;
  options: (DropdownItem | { group: string })[];
  onChange: (value: string) => void;
}) => {
  const [filter, setFilter] = useState('');
  const filteredOptions = options.filter(
    (item) =>
      isGroup(item) ||
      ('' + (item.title ?? item.value ?? ''))
        .toLowerCase()
        .includes(filter.toLowerCase()),
  );

  const valueIndex = filteredOptions.findIndex(
    (item) => !isGroup(item) && item.value === value,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState<number | undefined>(
    valueIndex === -1 ? undefined : valueIndex,
  );

  const longestOption = (
    options.filter((item) => !isGroup(item)) as { value: string }[]
  ).reduce<DropdownItem | undefined>(
    (acc, option) =>
      (acc?.value?.length ?? 0) > option.value?.length ? acc : option,
    undefined,
  );

  const handleKeydown = (e: React.KeyboardEvent) => {
    e.preventDefault();

    if (!isOpen && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
      setIsOpen(true);
    }

    if (e.key === 'ArrowDown') {
      if (
        selectionIndex === undefined ||
        selectionIndex === filteredOptions.length - 1
      ) {
        setSelectionIndex(isGroup(filteredOptions[0]) ? 1 : 0);
      } else {
        setSelectionIndex(
          Math.min(
            selectionIndex +
              (isGroup(filteredOptions[selectionIndex + 1]) ? 2 : 1),
            filteredOptions.length - 1,
          ),
        );
      }
    }

    if (e.key === 'ArrowUp') {
      if (
        selectionIndex === undefined ||
        selectionIndex === 0 ||
        (isGroup(filteredOptions[0]) && selectionIndex === 1)
      ) {
        setSelectionIndex(filteredOptions.length - 1);
      } else {
        setSelectionIndex(
          Math.max(
            selectionIndex -
              (isGroup(filteredOptions[selectionIndex - 1]) ? 2 : 1),
            0,
          ),
        );
      }
    }

    if (e.key === 'Enter' || e.code === 'Space') {
      if (selectionIndex !== undefined && isOpen) {
        onChange(filteredOptions[selectionIndex].value);
        setIsOpen(false);
      }
    }

    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (filterEnabled) {
      setFilter(
        options.find((option) => option.value === value)?.title ?? value,
      );
    }
  }, [value]);

  useEffect(() => {
    if (!isOpen || !filterEnabled) {
      setSelectionIndex(valueIndex === -1 ? undefined : valueIndex);
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    filter,
    setFilter,
    selectionIndex,
    filteredOptions,
    longestOption,
    handleKeydown,
    option: options.find(
      (option) => !isGroup(option) && option.value === value,
    ) as DropdownItem | undefined,
  };
};
