import { useState } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from 'recharts';
import { CustomGraphTooltip } from './CustomGraphTooltip';
import dayjs from 'dayjs';
import ToggleButton from '@/Components/ToggleButton';
import {
  useGetTrackedKeywordPositionTimeline,
  useProjectTrackedKeywordGraph,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Select } from '@/Components/v2/Select';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';

type Props = {
  keywordId: number;
};

export const KeywordGraph = ({ keywordId }: Props) => {
  const appState = useAppStore();

  const [chosenInterval, setChosenInterval] = useState<
    'daily' | 'weekly' | 'monthly' | 'yearly'
  >('daily');
  const [chosenPeriod, setChosenPeriod] = useState<'1M' | '1Y' | '2Y' | '6M'>(
    '1M',
  );
  const graphDataQuery = useGetTrackedKeywordPositionTimeline({
    pathParams: {
      trackedKeyword: keywordId,
      project: appState.currentProject!.id,
    },
    queryParams: {
      range: 'P' + chosenPeriod,
      interval: 'P1' + chosenInterval.slice(0, 1).toUpperCase(),
    },
  });

  return (
    <div>
      <div className="mb-4 flex justify-between gap-2">
        <div className="flex w-full justify-end gap-2">
          <ToggleButton
            value={chosenPeriod}
            onChange={setChosenPeriod}
            options={[
              { displayName: '1M', value: '1M' },
              { displayName: '6M', value: '6M' },
              { displayName: '1Y', value: '1Y' },
            ]}
          />
          <Select
            onChange={setChosenInterval}
            options={[
              {
                title: 'Daily',
                value: 'daily',
              },
              {
                title: 'Weekly',
                value: 'weekly',
              },
              {
                title: 'Monthly',
                value: 'monthly',
              },
              {
                title: 'Yearly',
                value: 'yearly',
              },
            ]}
            value={chosenInterval}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={graphDataQuery.data?.data ?? []}>
          <Tooltip
            content={CustomGraphTooltip}
            isAnimationActive={false}
            position={{ y: 50 }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(value: string) => dayjs(value).format('MMM D YYYY')}
          />
          <YAxis
            reversed
            allowDataOverflow
            domain={[0, 100]}
            orientation="right"
          />
          <Line
            animationDuration={300}
            type="linear"
            name="Highest ranking"
            dataKey="position"
            stroke="#4D984D"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
