import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { RankTracking } from './RankTracking';
import { useAppStore } from '../AppLoader/stores';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/rank-tracking',
    component: () => {
      const projectId = useAppStore((state) => state.currentProject!.id);

      return <RankTracking key={projectId} />;
    },
  }),
];
