import * as React from 'react';
import { Plus, Settings } from 'lucide-react';
import { Dialog } from '@/Components/Dialog';
import { useQueryClient } from '@tanstack/react-query';
import ValidationErrors from '@/Components/ValidationErrors';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Errors } from '@/Components/Errors';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import {
  useProjectsDomainUrlsStore,
  useProjectsShow,
  useProjectsUpdate,
} from '@/api/openapiComponents';

const initialState = {
  isOpen: false,
  url: '',
  title: '',
  primary_keyword: '',
};

type Props = {
  projectId: number;
};

export const ProjectUrlAddButton = ({ projectId }: Props) => {
  const client = useQueryClient();
  const appState = useAppStore();
  const [{ isOpen, primary_keyword, title, url }, setDialogOptions] =
    React.useState(initialState);
  const [crawlDialog, setCrawlDialog] = React.useState({
    input: '',
    open: false,
  });
  const {
    mutate: addUrl,
    isPending: isAddingUrl,
    isError: addUrlHasError,
    error: addUrlErrors,
  } = useProjectsDomainUrlsStore();

  const {
    mutate: updateCrawls,
    isPending: isUpdatingCrawls,
    error: crawlValidationErrors,
    isError: crawlValidationHasError,
  } = useProjectsUpdate({
    onSuccess: () => {
      setCrawlDialog((prev) => ({ ...prev, open: false }));
    },
  });

  const { data: project } = useProjectsShow({
    pathParams: { project: projectId },
  });

  React.useEffect(() => {
    if (!project) return;
    setCrawlDialog((prev) => ({
      ...prev,
      input: (project.data?.crawl_settings?.patterns || []).join('\n'),
    }));
  }, [project]);
  const handleCloseDialog = () => setDialogOptions(initialState);
  const handleOpenDialog = () =>
    setDialogOptions((prev) => ({ ...prev, isOpen: true }));

  const handleCloseCrawlFilterDialog = () =>
    setCrawlDialog((prev) => ({ ...prev, open: false }));
  const handleOpenCreateCrawlFilterDialog = () =>
    setCrawlDialog((prev) => ({ ...prev, open: true }));

  const handleChangeCrawlFilter = (value: string) => {
    setCrawlDialog((prev) => ({ ...prev, input: value }));
  };
  const handleOnChange = (value: string, id: string) => {
    setDialogOptions((prev) => ({ ...prev, [id]: value }));
  };

  const handleSaveCrawlFilter = () => {
    if (!appState.currentProject) return;
    updateCrawls({
      pathParams: {
        project: projectId,
      },
      body: {
        crawl_settings: {
          patterns: crawlDialog.input
            .split('\n')
            .filter((line) => line.length > 0),
        },
        name: appState.currentProject?.name,
      },
    });
  };

  const handleOnClick = () => {
    addUrl(
      {
        pathParams: {
          project: projectId,
        },
        body: {
          primary_keyword,
          title,
          url,
        },
      },
      {
        onSuccess: () => {
          const queryKey =
            client.getQueryCache().find({
              predicate: (query) =>
                query.queryKey.includes('projectsDomainUrlsIndex'),
            })?.queryKey ?? [];
          setDialogOptions({ primary_keyword, title, url, isOpen: false });
          client.refetchQueries({
            queryKey,
          });
        },
      },
    );
  };

  return (
    <>
      <Dialog
        isOpen={crawlDialog.open}
        handleClose={handleCloseCrawlFilterDialog}
        title="Crawl Filters"
        size={'sm'}
      >
        <form onSubmit={handleOnClick} className="flex flex-col gap-2">
          <div className={'text-sm'}>
            Customize which URLs our crawler targets or avoids on your website
            with our crawl filter functionality.
          </div>
          <div className={'text-sm'}>Use simple rules to:</div>

          <ul className={'mb-3 ml-8 list-disc text-sm'}>
            <li>Whitelist paths for inclusion (e.g., /blog/)</li>
            <li>Blacklist paths for exclusion (e.g., !/contact/)</li>
          </ul>

          <TextArea
            value={crawlDialog.input}
            onChange={(value) => handleChangeCrawlFilter(value)}
            placeholder="/some-url/*"
          />
          <div className="flex w-full justify-center">
            <Button
              color="secondary"
              fullWidth
              onClick={handleSaveCrawlFilter}
              isLoading={isUpdatingCrawls}
              text="Save"
            />
          </div>
          {crawlValidationHasError && (
            <ValidationErrors
              apiError={crawlValidationErrors}
              errors={crawlValidationErrors.errors}
            />
          )}
        </form>
      </Dialog>
      <Dialog
        isOpen={isOpen}
        handleClose={handleCloseDialog}
        title="Add website URL"
        size={'sm'}
      >
        <form onSubmit={handleOnClick} className="flex flex-col gap-4">
          <InputDecoration label="Title" required>
            <Input
              onChange={(value) => handleOnChange(value, 'title')}
              value={title}
              placeholder="SEO tips for beginners"
            />
          </InputDecoration>
          <InputDecoration label="URL" required>
            <Input
              onChange={(value) => handleOnChange(value, 'url')}
              value={url}
              placeholder="https://seo.ai/blog/seo-tips"
            />
          </InputDecoration>
          <InputDecoration label="Target Keyword" required>
            <Input
              onChange={(value) => handleOnChange(value, 'primary_keyword')}
              placeholder="seo tips"
              value={primary_keyword}
            />
          </InputDecoration>
          <div className="flex flex-col items-center justify-center">
            <Button
              color="secondary"
              fullWidth
              onClick={handleOnClick}
              isLoading={isAddingUrl}
              text="Add URL"
              prependIcon={Plus}
            />
            {addUrlHasError && (
              <div className="mt-2">
                <Errors error={addUrlErrors} />
              </div>
            )}
          </div>
        </form>
      </Dialog>
      <div className="mb-4 flex gap-2">
        <Button
          color="primary"
          variant="ghost"
          onClick={handleOpenCreateCrawlFilterDialog}
          text="Crawl filters"
          prependIcon={Settings}
        />
        <Button
          color="secondary"
          onClick={handleOpenDialog}
          text="Add URL"
          prependIcon={Plus}
        />
      </div>
    </>
  );
};
