type Props = {
  name: string;
  description?: string;
  usage: {
    limit: string;
    used: string;
  };
};

export const UsageItem = ({ usage, name, description }: Props) => {
  const percentage = (Number(usage.used) / Number(usage.limit)) * 100;

  return (
    <div className="my-2 flex w-full flex-col">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <span className="">{name}</span>
          &nbsp;
          {description && (
            <span className={'text-gray-400'}>
              • <span className="text-sm text-gray-500">{description}</span>
            </span>
          )}
        </div>
        <div
          className={`${percentage > 90 ? 'text-red-500' : 'text-gray-500'}`}
        >
          {usage.used}/{usage.limit}
        </div>
      </div>
      <div className="h-4 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700">
        <div
          className={`h-4 rounded-full ${
            percentage > 90 ? 'bg-red-400' : 'bg-secondary-400'
          }`}
          style={{
            width: `${Math.min(100, percentage)}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
