import { useEffect, useState } from 'react';
import { ProductItem } from './components/SubscriptionItem';
import { ChevronLeft } from 'lucide-react';
import { ProductResource } from '@/api/openapiSchemas';
import { getFeatures } from './util';
import { Menus } from '@/Components/Menus';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { ChangeSubscriptionDialog } from './components/ChangeSubscriptionDialog';
import { Button } from '@/Components/v2/Button';
import ToggleButton from '@/Components/ToggleButton';
import { useFeatureFlagging } from '../AppLoader';
import { Card } from '@/Components/v2/Card';
import PageContainer from '@/Components/PageContainer';
import {
  useOrganisationSubscriptionIndex,
  useProductsIndex,
} from '@/api/openapiComponents';
import { CardHeader } from '@/Components/v2/Card/components/CardTitle';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { SubscriptionItem } from '../Auth/Finalize/SubscriptionItem';

export const Subscription = () => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const { hasFeature } = useFeatureFlagging();

  const [intervalFilter, setIntervalFilter] = useState('month');
  const [productToBeChangedTo, setProductToBeChangedTo] =
    useState<ProductResource>();

  const productsQuery = useProductsIndex({
    queryParams: {
      limit: 100,
      sort_by: 'position',
      sort_direction: 'ASC',
    },
  });
  const products = productsQuery.data?.data;

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
  });

  const subscription = subscriptionQuery.data?.data;
  const plan = subscription?.products.find((p) => p.type === 'plan');

  const subscriptionInterval = plan?.interval;

  // Change subscription endpoints

  useEffect(() => {
    if (subscriptionInterval) {
      setIntervalFilter(subscriptionInterval);
    }
  }, [subscriptionInterval]);

  const isLoading = productsQuery.isLoading || subscriptionQuery.isLoading;

  appState.pageTitle('Account');

  return (
    <Menus hideBanners>
      <ChangeSubscriptionDialog
        intervalFilter={intervalFilter}
        plan={plan}
        productToBeChangedTo={productToBeChangedTo}
        setProductToBeChangedTo={setProductToBeChangedTo}
        subscription={subscription}
      />

      <PageContainer>
        <div className="my-2">
          <Button
            size="sm"
            text="Back to account"
            onClick={() => navigate({ to: '/account' })}
            prependIcon={ChevronLeft}
            color="primary"
            variant="ghost"
          />
        </div>
        <div className="flex flex-col gap-4">
          <Card fullWidth>
            <CardHeader title="Subscription" />
            <CardBody>
              <div className={'flex w-full flex-col flex-nowrap items-center'}>
                {subscriptionInterval !== 'year' && (
                  <ToggleButton
                    options={[
                      {
                        displayName: 'Monthly',
                        value: 'month',
                      },
                      {
                        displayName: 'Yearly',
                        value: 'year',
                      },
                    ]}
                    size="sm"
                    value={intervalFilter}
                    onChange={(value) => setIntervalFilter(value)}
                  />
                )}
                {isLoading && (
                  <div className="mt-8 flex w-full items-end justify-center gap-8 pl-4">
                    <div className="h-96 w-full animate-pulse rounded-lg bg-gray-300" />
                    <div className="h-96 w-full animate-pulse rounded-lg bg-gray-300" />
                    <div className="h-96 w-full animate-pulse rounded-lg bg-gray-300" />
                  </div>
                )}
                <div className="mt-8 flex w-full justify-between gap-8">
                  {!isLoading &&
                    products
                      ?.filter((product) => product.interval === intervalFilter)
                      .map((product, index) => (
                        <>
                          <SubscriptionItem
                            key={product.id}
                            name={
                              product.name.slice(0, 1).toUpperCase() +
                              product.name.slice(1)
                            }
                            isCurrent={product.id === plan?.id}
                            disabled={product.id === plan?.id}
                            description={(() => {
                              switch (index) {
                                case 0:
                                  return 'For starters, small websites and freelancers.';
                                case 1:
                                  return 'For small e-commerce sites, mid-sized websites and small teams.';
                                default:
                                  return 'Ideal for large e-commerce sites, agencies and enterprises.';
                              }
                            })()}
                            headline={`$${
                              product.name !== 'basic' &&
                              product.id === plan?.id
                                ? subscription.products.reduce(
                                    (prev, curr) =>
                                      prev + curr.price * curr.quantity,
                                    0,
                                  )
                                : product.price
                            }`}
                            headlineAppendix={` / ${product.interval}`}
                            actionText={
                              subscription.is_trialing
                                ? 'Start paid plan'
                                : product.name === 'premium' &&
                                    plan?.id === product.id
                                  ? 'Manage addons'
                                  : 'Choose'
                            }
                            bulletPoints={[
                              ...getFeatures(
                                product.settings ?? [],
                                product.name !== 'basic' &&
                                  product.id === plan?.id
                                  ? subscription
                                  : undefined,
                              ),
                              null,
                              {
                                text: 'Brand voice training',
                                disabled: false,
                              },
                              {
                                text: 'AI keyword research tools',
                                disabled: false,
                              },

                              ...(hasFeature('keyword-gaps')
                                ? [
                                    {
                                      text: 'Keyword gaps (Search console)',
                                      disabled:
                                        (product.settings?.find(
                                          (setting) =>
                                            setting.setting?.name ===
                                            'keyword_gaps',
                                        )?.default_value ?? 0) === 0,
                                    },
                                  ]
                                : []),
                              ...(hasFeature('bulk-auto-generate')
                                ? [
                                    {
                                      text: 'Bulk generate',
                                      disabled:
                                        (product.settings?.find(
                                          (setting) =>
                                            setting.setting?.name ===
                                            'bulk_document_generate',
                                        )?.default_value ?? 0) === 0,
                                    },
                                  ]
                                : []),
                              ...(hasFeature('create-custom-templates')
                                ? [
                                    {
                                      text: 'Custom templates',
                                      disabled:
                                        (product.settings?.find(
                                          (setting) =>
                                            setting.setting?.name ===
                                            'custom_document_templates',
                                        )?.default_value ?? 0) === 0,
                                    },
                                  ]
                                : []),
                              ...(hasFeature('content-scanner')
                                ? [
                                    {
                                      text: 'Content scanner',
                                      disabled:
                                        (product.settings?.find(
                                          (setting) =>
                                            setting.setting?.name ===
                                            'content_scanner',
                                        )?.default_value ?? 0) === 0,
                                    },
                                  ]
                                : []),
                              null,
                              {
                                text: (() => {
                                  switch (index) {
                                    case 0:
                                      return 'Self guided setup';
                                    case 1:
                                      return '1-1 onboarding & setup';
                                    default:
                                      return '1-1 onboarding & setup';
                                  }
                                })(),
                                disabled: false,
                              },
                              {
                                text: (() => {
                                  switch (index) {
                                    case 0:
                                      return 'E-mail support';
                                    case 1:
                                      return 'E-mail support';
                                    default:
                                      return 'E-mail + video call support';
                                  }
                                })(),
                                disabled: false,
                              },
                            ]}
                            onClick={() => setProductToBeChangedTo(product)}
                          />
                        </>
                      ))}
                </div>
                <p className="mx-auto mt-6 text-center italic text-primary">
                  You can purchase additional credits if you need to increase
                  your allowance on the Plus and Enterprise plans.
                  <br /> Unlimited is within fair usage guidelines. Usage beyond
                  typical levels might lead to certain restrictions.
                </p>
              </div>
            </CardBody>
          </Card>

          <div className={'flex justify-end'}>
            <Button
              text="Cancel subscription"
              color="red"
              onClick={() => navigate({ to: '/account/subscription/cancel' })}
              variant="outline"
            />
          </div>
        </div>
      </PageContainer>
    </Menus>
  );
};
