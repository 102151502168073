import { useAppStore } from '@/Pages/AppLoader/stores';
import { useGetDocument, useUpdateDocument } from '@/api/openapiComponents';
import { CreatePageContainer } from '../../../components';
import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '@/Hooks/useDebounce';
import { Button } from '@/Components/v2/Button';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { TitleScoreSlideOver } from '@/Pages/Document/components/DocumentSlideOvers/components/TitleScoreSlideOver';
import { useQueryClient } from '@tanstack/react-query';
import { SendSearchResult } from '@/types';
import { useGenerateDocumentV2 } from '../../hooks';
import { hasher, useFeatureFlagging } from '@/Pages/AppLoader';
import { ArrowRight } from 'lucide-react';

export const TitleEditor = () => {
  const { hasFeature } = useFeatureFlagging();
  const params = useParams({
    from: '/create-content/title-editor/$documentId',
  });
  const search = useSearch({
    from: '/create-content/title-editor/$documentId',
  });
  const documentId = Number(params.documentId);
  const appState = useAppStore();

  const navigate = useNavigate();

  const client = useQueryClient();

  const { handleGenerateDocument, isLoading } = useGenerateDocumentV2();
  const updateDocumentMutation = useUpdateDocument({
    onSuccess: (data) => {
      const queryKey =
        client.getQueryCache().find({
          predicate: (query) => query.queryKey.includes('getDocument'),
        })?.queryKey ?? [];
      client.setQueryData(queryKey, data);
    },
  });
  const documentQuery = useGetDocument(
    {
      pathParams: {
        project: appState.currentProject!.id,
        document: documentId,
      },
    },
    {
      gcTime: 0,
    },
  );

  const hasNewTemplatesFeature = () => {
    if (hasFeature('new_templates')) {
      return !search.document_template && search.review_subheadings;
    }
    return search.review_subheadings;
  };
  const document = documentQuery.data?.data;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState('');
  const titleRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (documentQuery.isSuccess) {
      setTitle(document!.title);
    }
  }, [documentQuery.isSuccess]);

  useEffect(() => {
    const url = `documents.${documentId}`;
    window.Echo.private(url).listen<SendSearchResult>(
      `DocumentUpdatedEvent`,
      (e) => {
        documentQuery.refetch();
      },
    );
    return () => {
      window.Echo.leave(url);
    };
  }, []);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = '1px';
      titleRef.current.style.height =
        (titleRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  useDebounce(
    () =>
      updateDocumentMutation.mutate({
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          title: title,
        },
      }),
    [title],
    500,
  );

  const handleSubmit = () => {
    setIsSubmitting(true);
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          title: title,
        },
      },
      {
        onError: () => {
          setIsSubmitting(false);
        },
        onSuccess: () => {
          if (hasNewTemplatesFeature()) {
            navigate({
              to: '/create-content/outline-editor/$documentId',
              params: { documentId: '' + documentId },
              search: {
                live_generate: search.live_generate,
                is_new: search.is_new,
                review_title: true,
              },
            });
          } else {
            handleGenerateDocument({
              document: document!,
              liveGenerate: search.live_generate === true,
              projectId: appState.currentProject!.id,
              onError: () => {
                setIsSubmitting(false);
              },
              onSuccess: () => {
                if (search.live_generate) {
                  navigate({
                    to: '/documents/$documentId',
                    params: { documentId: hasher.encode(documentId) },
                    search: {
                      generate: true,
                      ...(search.document_template
                        ? {
                            document_template: search.document_template,
                          }
                        : {}),
                    },
                  });
                } else {
                  navigate({ to: '/created-content' });
                }
              },
            });
          }
        },
      },
    );
  };

  const handleClose = () => {
    navigate({
      to: '/documents/$documentId',
      params: { documentId: hasher.encode(documentId) },
    });
  };

  appState.pageTitle('Title Editor');

  return (
    <CreatePageContainer
      hasSlideover
      canGoBack={false}
      onClose={handleClose}
      closeText={search.is_new ? 'Go to editor' : 'Return to editor'}
      closeIcon={ArrowRight}
    >
      {document && (
        <TitleScoreSlideOver
          document={document}
          onTitleChange={setTitle}
          isOpen
          projectId={appState.currentProject!.id}
        />
      )}
      <div className="flex w-full flex-col justify-start">
        <div className="flex max-w-full flex-col items-center">
          <div className="w-full max-w-6xl px-6 pt-4">
            <Card>
              <CardBody>
                <div className="mb-10 flex w-full items-center justify-between">
                  <div>
                    <b>{title.length}</b> characters{' '}
                  </div>
                  <div>
                    Target keyword: <b>{document?.keyword.name}</b>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <div className="mt-6 text-lg text-primary-400">H1</div>
                  <textarea
                    disabled={documentQuery.isLoading}
                    placeholder="Title of your document..."
                    ref={titleRef}
                    className="flex-grow resize-none overflow-hidden border-none text-6xl font-bold placeholder:text-primary-400 hover:bg-primary-50 focus:bg-primary-50"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="mx-auto mt-4 flex w-full max-w-xs justify-center">
          <Button
            isLoading={
              isLoading || (isSubmitting && updateDocumentMutation.isPending)
            }
            disabled={title.length === 0}
            text={search.review_subheadings ? 'Continue' : 'Generate'}
            color="secondary"
            fullWidth
            onClick={handleSubmit}
          />
        </div>
      </div>
    </CreatePageContainer>
  );
};
