import { useURLState } from '@/Pages/Document/hooks';
import { CollapsableInputField } from './collapsable-input-field';

export const UrlInput = () => {
  const [url, setUrl] = useURLState();
  return (
    <CollapsableInputField
      label="URL"
      placeholder="https://example.com/your-url"
      value={url}
      onChange={(e) => {
        setUrl(e.target.value);
      }}
    />
  );
};
