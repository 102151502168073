import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { SeoAiLogoBlack } from '../../../Components/SVGs/SeoAiLogoBlack';
import { SubscriptionItem } from './SubscriptionItem';
import ToggleButton from '../../../Components/ToggleButton';
import { ProductResource } from '@/api/openapiSchemas';
import { getFeatures } from '@/Pages/Account/util';
import LogoutLink from './LogoutLink';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import {
  useOrganisationSubscriptionList,
  useProductsIndex,
} from '@/api/openapiComponents';
import Toggle from '@/Components/Toggle';
import underlineSvg from '../../../../../public/svg/orange-underline.svg';

export const Finalize = () => {
  const navigate = useNavigate();
  const appState = useAppStore();

  const { hasFeature } = useFeatureFlagging();

  const [type, setType] = useState<'monthly' | 'yearly'>('monthly');
  const subscriptionsQuery = useOrganisationSubscriptionList({
    pathParams: {
      organisation: appState.auth?.user.org_id!,
    },
  });
  const productsQuery = useProductsIndex({
    queryParams: {
      limit: 100,
      sort_by: 'position',
      sort_direction: 'ASC',
      filters: {
        type: 'plan',
      },
    },
  });

  const isElligibleForTrial = subscriptionsQuery.data?.data?.length === 0;

  const monthlyProducts = (productsQuery.data?.data ?? []).filter(
    (product) => product.interval === 'month',
  );
  const yearlyProducts = (productsQuery.data?.data ?? []).filter(
    (product) => product.interval === 'year',
  );

  appState.pageTitle('Choose a plan');

  return (
    <div className="flex h-screen w-full justify-center overflow-y-auto pb-6 lg:pb-0">
      <div className="my-auto flex flex-col items-center justify-center">
        <div className="left-0 right-0 top-2 mb-6 flex w-full justify-center lg:absolute">
          <SeoAiLogoBlack />
        </div>
        <div className="flex w-full items-start justify-between">
          <p className="text-xl font-bold">Choose a plan</p>
          <div className="flex gap-2">
            <Toggle
              value={type === 'yearly'}
              onChange={(value) => setType(value ? 'yearly' : 'monthly')}
            />
            <div className="-mt-0.5 flex items-start gap-1">
              Pay annually and get
              <div className="flex flex-col">
                <span className="font-bold text-orange-500">
                  2 months for free
                </span>
                <div className="flex">
                  <div className="w-0 flex-grow overflow-hidden">
                    <img src={underlineSvg} className="max-w-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-8 pt-6 lg:w-[60rem] lg:flex-row">
          {productsQuery.isLoading || subscriptionsQuery.isLoading ? (
            <>
              <div className="h-[508px] w-full animate-pulse rounded-xl bg-gray-100" />
              <div className="h-[508px] w-full animate-pulse rounded-xl bg-gray-100" />
              <div className="h-[508px] w-full animate-pulse rounded-xl bg-gray-100" />
            </>
          ) : (
            <>
              {(type === 'monthly' ? monthlyProducts : yearlyProducts).map(
                (product: ProductResource, index) => (
                  <SubscriptionItem
                    key={product.id}
                    name={
                      product.name.slice(0, 1).toUpperCase() +
                      product.name.slice(1)
                    }
                    description={(() => {
                      switch (index) {
                        case 0:
                          return 'For starters, small websites and freelancers.';
                        case 1:
                          return 'For small e-commerce sites, mid-sized websites and small teams.';
                        default:
                          return 'Ideal for large e-commerce sites, agencies and enterprises.';
                      }
                    })()}
                    headline={`$${product.price}`}
                    headlineAppendix={` / ${product.interval}`}
                    actionText={
                      isElligibleForTrial ? 'Start free trial' : 'Subscribe'
                    }
                    bulletPoints={[
                      ...getFeatures(product.settings ?? []),
                      null,
                      {
                        text: 'Brand voice training',
                        disabled: false,
                      },
                      {
                        text: 'AI keyword research tools',
                        disabled: false,
                      },

                      ...(hasFeature('keyword-gaps')
                        ? [
                            {
                              text: 'Keyword gaps (Search console)',
                              disabled:
                                (product.settings?.find(
                                  (setting) =>
                                    setting.setting?.name === 'keyword_gaps',
                                )?.default_value ?? 0) === 0,
                            },
                          ]
                        : []),
                      ...(hasFeature('bulk-auto-generate')
                        ? [
                            {
                              text: 'Bulk generate',
                              disabled:
                                (product.settings?.find(
                                  (setting) =>
                                    setting.setting?.name ===
                                    'bulk_document_generate',
                                )?.default_value ?? 0) === 0,
                            },
                          ]
                        : []),
                      ...(hasFeature('create-custom-templates')
                        ? [
                            {
                              text: 'Custom templates',
                              disabled:
                                (product.settings?.find(
                                  (setting) =>
                                    setting.setting?.name ===
                                    'custom_document_templates',
                                )?.default_value ?? 0) === 0,
                            },
                          ]
                        : []),
                      ...(hasFeature('content-scanner')
                        ? [
                            {
                              text: 'Content scanner',
                              disabled:
                                (product.settings?.find(
                                  (setting) =>
                                    setting.setting?.name === 'content_scanner',
                                )?.default_value ?? 0) === 0,
                            },
                          ]
                        : []),
                      null,
                      {
                        text: (() => {
                          switch (index) {
                            case 0:
                              return 'Self guided setup';
                            case 1:
                              return '1-1 onboarding & setup';
                            default:
                              return '1-1 onboarding & setup';
                          }
                        })(),
                        disabled: false,
                      },
                      {
                        text: (() => {
                          switch (index) {
                            case 0:
                              return 'E-mail support';
                            case 1:
                              return 'E-mail support';
                            default:
                              return 'E-mail + video call support';
                          }
                        })(),
                        disabled: false,
                      },
                    ]}
                    onClick={() => {
                      navigate({
                        to: '/checkout',
                        search: { product_id: product.id },
                      });
                    }}
                  />
                ),
              )}
            </>
          )}
          <div className="absolute bottom-2 right-2">
            <LogoutLink />
          </div>
        </div>
        <p className="mx-auto mt-6 text-center italic text-primary">
          You can purchase additional credits if you need to increase your
          allowance on the Plus and Enterprise plans.
          <br /> Unlimited is within fair usage guidelines. Usage beyond typical
          levels might lead to certain restrictions.
        </p>
      </div>
    </div>
  );
};
