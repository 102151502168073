import React from 'react';
import {
  MARK_BOLD,
  MARK_ITALIC,
} from '@udecode/plate-basic-marks';
import { useEditorReadOnly } from '@udecode/plate-common';
import { Icons } from './icons';
import { MarkToolbarButton } from './mark-toolbar-button';
import { ToolbarButton, ToolbarGroup } from './toolbar';
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu';
import { LinkToolbarButton } from './link-toolbar-button';
import { ListStyleType, ListToolbarButton } from './list-toolbar-button';
import { Redo2, Undo2 } from 'lucide-react';
import {
  collapseSelection,
  focusEditor,
  toggleNodeType,
  useEditorState,
} from '@udecode/plate-common';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { Generating } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { insertTable } from '@udecode/plate-table';
import { isInsideTableCell } from '../lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { useGetEditorFocusElement } from '@/Pages/Document/hooks';

type Props = {
  children?: React.ReactNode;
}

export function FixedToolbarButtons({ children }: Props) {
  const navigate = useNavigate();
  const readOnly = useEditorReadOnly();
  const focusEditorRef = useGetEditorFocusElement();

  const editor = useEditorState();
  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  const toggleBlockQuote = () => {
    toggleNodeType(editor, { activeType: ELEMENT_BLOCKQUOTE });

    collapseSelection(editor);
    focusEditor(editor);
  };

  return (
    <div className="w-full overflow-hidden px-5">
      <div className="flex flex-wrap justify-between">
        <div className="flex justify-between">
          {!readOnly && (
            <>
              <ToolbarGroup noSeparator={true}>
                <ToolbarButton
                  tooltip="Undo"
                  onClick={() => {
                    focusEditorRef.focus();
                    editor.undo();
                  }}
                  disabled={!!isGenerating}
                >
                  <Undo2 color="black" />
                </ToolbarButton>
                <ToolbarButton
                  tooltip="Redo"
                  onClick={() => {
                    editor.redo();
                  }}
                  disabled={!!isGenerating}
                >
                  <Redo2 color="black" />
                </ToolbarButton>
              </ToolbarGroup>
              <ToolbarGroup noSeparator>
                <TurnIntoDropdownMenu />
              </ToolbarGroup>

              <ToolbarGroup>
                <MarkToolbarButton
                  tooltip="Bold (⌘+B)"
                  nodeType={MARK_BOLD}
                  disabled={!!isGenerating}
                >
                  <Icons.bold />
                </MarkToolbarButton>
                <MarkToolbarButton
                  tooltip="Italic (⌘+I)"
                  nodeType={MARK_ITALIC}
                  disabled={!!isGenerating}
                >
                  <Icons.italic />
                </MarkToolbarButton>
                <ToolbarButton
                  tooltip="Quote (⌘+⇧+.)"
                  onClick={toggleBlockQuote}
                  disabled={!!isGenerating || isInsideTableCell(editor)}
                >
                  <Icons.blockquote />
                </ToolbarButton>
              </ToolbarGroup>
            </>
          )}
          <ToolbarGroup>
            <ListToolbarButton
              nodeType={ListStyleType.UL}
              disabled={!!isGenerating || isInsideTableCell(editor)}
            />
            <ListToolbarButton
              nodeType={ListStyleType.OL}
              disabled={!!isGenerating || isInsideTableCell(editor)}
            />
          </ToolbarGroup>
          <ToolbarGroup>
            <LinkToolbarButton disabled={!!isGenerating} />
            <ToolbarButton
              disabled={!!isGenerating || isInsideTableCell(editor)}
              tooltip="Table"
              onClick={() => {
                insertTable(editor, {
                  rowCount: 2,
                });
              }}
            >
              <Icons.table />
            </ToolbarButton>
          </ToolbarGroup>
        </div>
        {children && (
          <div>
            <ToolbarGroup noSeparator gap="0" className="flex">
              {children}
            </ToolbarGroup>
          </div>
        )}
      </div>
    </div>
  );
}
