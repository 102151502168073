import { Link } from '@tanstack/react-router';
import { Tooltip } from './v2/Tooltip';

type Props = {
  item: {
    name: string;
    href: string;
    current: boolean;
    disabled?: boolean;
    tooltip?: {
      text: string;
      navLink?: string;
      navLinkText?: string;
    };
    icon: any;
  };
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const SidebarMenuItem = ({ item }: Props) => {
  return (
    <Tooltip
      asChild
      title={item.tooltip?.text}
      disabled={!item.tooltip}
      navLink={item.tooltip?.navLink}
      navLinkText={item.tooltip?.navLinkText}
    >
      <li key={item.name}>
        <Link
          to={item.href}
          href={item.href}
          disabled={item.disabled}
          className={classNames(
            item.current
              ? 'bg-primary-125 text-primary-800'
              : 'text-primary-500',
            !item.current && !item.disabled
              ? 'hover:bg-primary-125 hover:text-primary-800'
              : '',
            'group flex gap-x-3 rounded-md p-2 font-bold leading-6',
            item.disabled ? 'cursor-not-allowed opacity-60' : '',
          )}
        >
          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
          {item.name}
        </Link>
      </li>
    </Tooltip>
  );
};
