import { serializeToMarkdown } from '@/Pages/Document/components/PlateEditor/lib/serialize';
import {
  StreamDataJSON,
  StreamPackage,
  StreamPackageJson,
  AIStreamChatMessage,
  BlockType,
  isLeafNode,
  Generating,
} from '@/types';
export const URL = '/api/v2';
import { useQuery, QueryClient, useQueryClient } from '@tanstack/react-query';
import { PlateEditor } from '@udecode/plate-common';
import deserialize from '@/Pages/Document/components/PlateEditor/lib/deserialize';
import { useGetEditorFocusElement } from '@/Pages/Document/hooks';
import { tracking } from '@/Services/tracking/Tracking';
import {
  DocumentsChatShowResponse,
  useDocumentsChatShow,
  useDocumentsChatUpdate,
  useGetDocument,
  useProjectDocumentWriteMoreStore,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { DocumentResource } from '@/api/openapiSchemas';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      gcTime: 0,
    },
    queries: {
      gcTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

export const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return headers;
};

/**
 * Throws the body if statuscode indicates an error
 * @param response fetch api response
 * @returns the body of the response
 */
export const handleErrors = async <T extends any>(
  response: Response,
): Promise<T | undefined> => {
  let body: T | undefined = undefined;

  try {
    body = await response.json();
  } catch (_) {
    return undefined as T;
  }

  if (response.status >= 500) {
    throw {
      message: 'Please try again.',
      internalError: true,
    } as T;
  }

  if (response.status >= 400) {
    throw body!;
  }
  return body;
};

/**
 * Throws the body if statuscode indicates an error
 * @param response fetch api response
 * @returns the body of the response
 */
export const parseSuccessBody = async <T extends any>(
  response: Response,
): Promise<T | undefined> => {
  let body: T | undefined = undefined;

  try {
    body = await response.json();
  } catch (_) {
    return undefined as T;
  }

  if (response.status >= 500) {
    return undefined;
  }

  if (response.status >= 400) {
    return undefined;
  }
  return body;
};

export const useStartMessageStreamMutation = (
  documentId: number,
  queryKey: unknown[],
) => {
  const client = useQueryClient();

  const initialState = {
    content: null,
    isLoadingMessage: false,
    isStreamingMessage: false,
  };

  const { data } = useQuery<AIStreamChatMessage>({
    queryKey: ['stream'],
    initialData: initialState,
  });

  const handleSetLoadingMessage = (value: boolean) => {
    client.setQueryData<AIStreamChatMessage>(['stream'], (prev) => {
      if (prev) {
        return {
          ...prev,
          isLoadingMessage: value,
          isStreamingMessage: value,
        };
      }
      return prev;
    });
  };

  const handleStartStream = () => {
    const stream = new EventSourcePolyfill(
      `${URL}/documents/${documentId}/chat/stream`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
        },
        withCredentials: true,
      },
    );

    stream.addEventListener('close', () => {
      client.invalidateQueries({ queryKey }).then(() => {
        client.setQueryData<AIStreamChatMessage>(['stream'], initialState);
      });
      stream.close();
    });

    window.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') {
        const event = { type: 'close', target: undefined };
        stream.dispatchEvent(event);
        tracking.event('document_chat_early_cancel');
      }
    });

    stream.addEventListener('update' as any, ({ data }: StreamPackage) => {
      if (!data) {
        return;
      }
      if (data === '[DONE]') {
        client.invalidateQueries({ queryKey }).then(() => {
          client.setQueryData<AIStreamChatMessage>(['stream'], initialState);
        });
        stream.close();
        return;
      }
      const { content }: StreamDataJSON = JSON.parse(data);
      if (content) {
        client.setQueryData<AIStreamChatMessage>(['stream'], (prev) => {
          if (prev) {
            return {
              ...prev,
              content: `${prev.content || ''}${content}`,
              isLoadingMessage: false,
            };
          }
          return prev;
        });
      }
    });
  };
  return {
    handleStartStream,
    data,
    handleSetLoadingMessage,
  };
};

export const useCheckWordCount = () => {
  const { data } = useQuery({
    queryKey: ['checkWordCount'],
    initialData: false,
  });
  return data;
};

const getPath = (index: number, path: number[], children: BlockType[]) => {
  const i = index;
  const node = children[Math.max(0, i)];
  path.push(Math.max(0, i));

  if (isLeafNode(node)) {
    return path;
  }

  return getPath(0, path, node.children as BlockType[]);
};

const removeEmptyBlocks = (editor: PlateEditor) => {
  for (let i = 0; i < (editor?.children.length ?? 0) - 1; i++) {
    const element = editor?.children[i];

    if (element?.type === 'p' && element.children[0].text === '') {
      editor?.removeNodes({
        at: {
          anchor: {
            offset: 0,
            path: getPath(i, [], editor.children as BlockType[]),
          },
          focus: {
            offset: 0,
            path: getPath(i + 1, [], editor.children as BlockType[]),
          },
        },
      });
    }
  }
};

/**
 * Handle strem update for markdown text. Replaces the current block with the markdown text given
 * @param stream
 * @param editor
 * @param data Current chunk
 * @param text Markdown text for current block
 * @returns
 */
const handleMarkdownStreamUpdate = (
  editor: PlateEditor,
  data: string,
  blockIndex: number,
  text: string,
  appendedText = '',
) => {
  if (!data) {
    return;
  }
  if (data === '[DONE]') {
    return { finished: true };
  }

  // These  chunks must be skipped in deserializaion by themselves, otherwise the editor thinks its the start of a list even though its italic text
  const skips = ['*', '>*'];

  const linebreak = 0;
  let endOfBlock = false;
  const { content }: StreamPackageJson = JSON.parse(data);
  if (editor) {
    const incoming = deserialize(text + content);
    if (incoming.length > 2) {
      // Check if the incoming text will result in a new block
      text = '';
      appendedText = '';
      if (incoming[0]?.type === 'ol' || incoming[0]?.type === 'ul') {
        // If we are in a list, break out of it
        editor.insertBreak();
        // linebreak = 2; // We need to replace the linebreak with the incoming text, otherwise it will cause duplication of the next word
      } else {
        endOfBlock = true;
      }
    }

    if (
      (text !== '' || linebreak) &&
      !skips.includes(text.trim()) &&
      !skips.includes(content.trim())
    ) {
      editor.select({
        anchor: {
          offset: 0,
          path: getPath(
            blockIndex - Math.max(linebreak, 2),
            [],
            editor.children as BlockType[],
          ),
        },
        focus: {
          offset: 0,
          path: getPath(blockIndex - 1, [], editor.children as BlockType[]),
        },
      });
      editor.delete();
    }
    text += content;

    const insertedText = endOfBlock ? text : text + appendedText;
    if (
      !skips.includes(insertedText.trim()) &&
      !skips.includes(content.trim())
    ) {
      editor.insertFragment(deserialize(insertedText));
    }
  }
  return { block: text, appendedText, endOfBlock };
};

export const useGenerateLiveDocument = (
  documentId: number,
  projectId: number,
  editor: PlateEditor | null,
) => {
  const client = useQueryClient();

  const generate = (options?: {
    clear?: boolean;
    templateDocumentId?: number;
  }) => {
    client.setQueryData<Generating>(['autoGenerationStream'], 'live');
    const stream = new EventSourcePolyfill(
      `${URL}/projects/${projectId}/documents/${documentId}/generate-stream${
        options?.templateDocumentId !== undefined
          ? '?document_template_id=' + options?.templateDocumentId
          : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
        },
        withCredentials: true,
      },
    );
    stream.onerror = (e) => {
      stream.close();
      throw e;
    };

    const closeStream = () => {
      client.setQueryData<Generating>(['autoGenerationStream'], false);
      if (editor && editor.selection) {
        const selection = editor.selection;
        editor.select(selection);
      }
      stream.close();
    };

    let text = '';

    if (options?.clear && editor) {
      editor.select({
        anchor: {
          offset: 0,
          path: getPath(0, [], editor.children as BlockType[]),
        },
        focus: {
          offset: 0,
          path: getPath(
            editor.children.length - 1,
            [],
            editor.children as BlockType[],
          ),
        },
      });
      editor.delete();
    }

    stream.addEventListener('update' as any, ({ data }: StreamPackage) => {
      if (!editor || !data) {
        return;
      }
      try {
        removeEmptyBlocks(editor);
        const update = handleMarkdownStreamUpdate(
          editor,
          data,
          editor.children.length,
          text,
        );

        if (update?.block) {
          text = update.block;
        }
        if (update?.finished) {
          closeStream();
        }
      } catch (err) {
        closeStream();
        throw err;
      }
    });
  };

  return { generate };
};

export const usePostAutoGenerateMutation = (
  editor: PlateEditor | null,
  projectId: number,
  documentId: number,
) => {
  const editorFocusRef = useGetEditorFocusElement();
  const client = useQueryClient();
  const { data: isLoading } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
    initialData: false,
  });
  const { mutate } = useProjectDocumentWriteMoreStore({
    onMutate: () => {
      client.setQueryData<Generating>(['autoGenerationStream'], 'write-more');
    },
    onSuccess: () => {
      if (!editor || !editor.selection) {
        return;
      }
      const initialPath = getPath(
        editor.selection.anchor.path[0],
        [],
        editor.children as BlockType[],
      );

      const blockIndex = initialPath[0];
      const block = editor.children[blockIndex];

      const startedInList = block?.type === 'ol' || block?.type === 'ul';

      const stream = new EventSourcePolyfill(
        `${URL}/projects/${projectId}/documents/${documentId}/write-more-stream`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
          },
          withCredentials: true,
        },
      );

      const escapeCallback = ({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
          closeStream();
        }
      };

      stream.onerror = (e) => {
        closeStream();
        throw e;
      };

      const closeStream = () => {
        window.removeEventListener('keydown', escapeCallback);
        client.setQueryData<Generating>(['autoGenerationStream'], false);
        if (editor && editor.selection) {
          editor.deleteBackward('character');
          editorFocusRef.focus();
        }
        stream.close();
      };

      let allText = '';
      let path = editor?.selection || null;
      window.addEventListener('keydown', escapeCallback);

      stream.addEventListener('update' as any, ({ data }: StreamPackage) => {
        if (!data) {
          return;
        }
        if (data === '[DONE]') {
          closeStream();
          return;
        }
        const { content: text }: StreamPackageJson = JSON.parse(data);

        if (editor) {
          if (editor && path === null && editor.prevSelection) {
            path = {
              anchor: editor.prevSelection.anchor,
              focus: editor.prevSelection.focus,
            };
          }
          allText += text;
          editor.select(path);
          if (text.includes('\n\n')) {
            let insertText = text.replaceAll('\n\n', '');
            if (startedInList) {
              insertText = insertText.replaceAll('*', '');
              insertText = insertText.replaceAll('#', '');
            }
            editor.insertText(insertText);
            editor.insertBreak();

            if (startedInList) {
              path = editor.selection;
              return;
            }

            const initialPath = getPath(
              editor.selection!.anchor.path[0],
              [],
              editor.children as BlockType[],
            );
            const blockIndex = initialPath[0];
            const block = editor.children[blockIndex];
            const isInList = block?.type === 'ol' || block?.type === 'ul';

            const selectionOffset = isInList
              ? { start: -1, end: 0 }
              : { start: -1, end: -1 };

            const selections = {
              anchor: {
                offset: 0,
                path: getPath(
                  blockIndex + selectionOffset.start,
                  [],
                  editor.children as BlockType[],
                ),
              },
              focus: {
                offset: 0,
                path: getPath(
                  blockIndex + 1 + selectionOffset.end,
                  [],
                  editor.children as BlockType[],
                ),
              },
            };
            editor.select(selections);
            const fragments = editor.getFragment();
            editor.delete();
            const mdText = serializeToMarkdown(fragments);
            const deserializedMdText = deserialize(mdText);
            editor.insertFragment(
              isInList
                ? deserializedMdText.slice(0, deserializedMdText.length - 1)
                : deserializedMdText,
            );
            removeEmptyBlocks(editor);
          } else {
            editor.insertText(text);
          }
          path = editor.selection;
        }
      });
    },
  });
  return { mutate, isLoading };
};

export const useChangeDocumentModel = (documentId: number) => {
  const client = useQueryClient();

  const variables = { pathParams: { document: documentId } };
  const { data, isPending } = useDocumentsChatShow(variables);
  const queryKey = queryKeyFn({
    path: '/documents/{document}/chat',
    operationId: 'documentsChatShow',
    variables,
  });

  const { mutate } = useDocumentsChatUpdate();

  const changeQuality = async (quality: boolean) => {
    client.setQueryData<DocumentsChatShowResponse | undefined>(
      queryKey,
      (prev) => {
        if (!prev) return prev;
        return { ...prev, data: { ...prev.data, quality: quality } };
      },
    );

    mutate({ pathParams: { document: documentId }, body: { quality } });
  };

  return {
    quality: data?.data?.quality,
    changeQuality,
    qualityIsPending: isPending,
  };
};

export const useEditor = () =>
  useQuery<PlateEditor | null>({ queryKey: ['editor-test'], initialData: null })
    .data;

export const useSetEditor = () => {
  const client = useQueryClient();

  const updateEditor = (value: PlateEditor | null) => {
    client.setQueryData(['editor-test'], { ...value });
  };

  return updateEditor;
};

export const usePollIsGeneratingDocument = (
  projectId: number,
  documentId: number,
) => {
  const client = useQueryClient();

  const mutation = useGetDocument(
    {
      pathParams: {
        document: documentId,
        project: projectId,
      },
    },
    { enabled: false },
  );

  const startPolling = (options?: {
    onFinish?: (document: DocumentResource) => void;
    onPoll?: (document: DocumentResource | undefined) => void;
  }) => {
    client.setQueryData<Generating>(['autoGenerationStream'], 'offline');
    const timerId = setInterval(
      () =>
        mutation.refetch().then((response) => {
          if (!response) {
            return;
          }
          const data = response.data?.data;
          if (data?.is_generating === false) {
            client.setQueryData<Generating>(['autoGenerationStream'], false);
            clearInterval(timerId);
            if (options?.onFinish) options.onFinish(data);
          } else if (options?.onPoll) {
            options.onPoll(data);
          }
        }),
      10000,
    );

    client.setQueryData(['document-generation-poll-timer-id'], timerId);
  };

  const stopPolling = () => {
    const timerId = client.getQueryData<number>([
      'document-generation-poll-timer-id',
    ]);
    clearInterval(timerId);
  };

  return { startPolling, stopPolling };
};
