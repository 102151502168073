import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import {
  DocumentElementActionType,
  DocumentElementResource,
  HeadingType,
} from '@/api/openapiSchemas';
type Props = {
  onSelect: (
    el: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => void;
  el: DocumentElementResource & { uniqueId: string };
  onChange: (
    type: DocumentElementActionType,
    el: DocumentElementResource & { uniqueId: string },
    value: string,
    isSubheading?: boolean,
  ) => void;
};
export const TemplateItemInputs = ({ onSelect, el, onChange }: Props) => (
  <>
    {el.action_type === 'prompt_with_subheading' && (
      <InputDecoration label="Subheading">
        <div className="mt-2">
          <Input
            prependIcon={
              (
                <div className="-ml-2">
                  <Select
                    options={[
                      { value: 'H2', title: 'H2' },
                      { value: 'H3', title: 'H3' },
                    ]}
                    onChange={(value) => {
                      onSelect(el, value as HeadingType);
                    }}
                    value={el.values?.subheading?.type ?? 'H2'}
                  />
                </div>
              ) as any
            }
            value={el.values?.subheading?.title ?? ''}
            onChange={(value) => {
              onChange(el.action_type, el, value, true);
            }}
            placeholder="Leave blank to let AI generate"
          />
        </div>
      </InputDecoration>
    )}
    <InputDecoration label={'Instruction'}>
      <div className="mt-2">
        <TextArea
          rows={5}
          minHeight={5}
          maxHeight={20}
          value={
            el.action_type === 'raw'
              ? el.values?.raw_markdown ?? ''
              : el.values?.prompt_instruction ?? ''
          }
          onChange={(value) => {
            onChange(el.action_type, el, value);
          }}
          resize
          placeholder="Leave blank to give AI full control"
        />
      </div>
    </InputDecoration>
  </>
);
