import { ContentTypeResource } from '@/api/openapiSchemas';
import { InputDecoration } from './v2/Input/InputDecoration';
import { Select } from './v2/Select';

type Props = {
  onChange: (value: number | undefined) => void;
  contentTypes: ContentTypeResource[];
  isLoading?: boolean;
  value: number | undefined;
};

export const ContentTypeSelect = ({
  contentTypes,
  isLoading,
  onChange,
  value,
}: Props) => {
  return (
    <InputDecoration label="Content type" tooltip="Refines the AI">
      <Select
        fullWidth
        disabled={isLoading}
        placeholder={'Blog post'}
        onChange={(value) => onChange(value ? Number(value) : undefined)}
        options={[
          {
            value: undefined,
            title: 'Blog post',
            subtitle: 'Compelling and informative blog post that captivates.',
          },
          ...(contentTypes.map((type) => ({
            value: type.id,
            title: type.display_name,
            subtitle: type.display_description,
          })) ?? []),
        ]}
        value={value}
      />
    </InputDecoration>
  );
};
