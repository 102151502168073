import { useMetaDescriptionState } from '@/Pages/Document/hooks';
import { CollapsableInputField } from './collapsable-input-field';

type Props = { handleOpenSlideOver: () => void };
export const MetaDescriptionInput = (props: Props) => {
  const [metaDescription, setMetaDescription] = useMetaDescriptionState();
  return (
    <CollapsableInputField
      {...props}
      placeholder="Click here to get meta description ideas"
      label="DESC."
      value={metaDescription}
      onChange={(e) => {
        setMetaDescription(e.target.value);
      }}
    />
  );
};
