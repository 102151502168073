export type ApiError = {
  message: string;
  errors: Record<string, string[]>;
};

export class ErrorHelper {
  private error: (ApiError & { status: number | null }) | null = null;

  constructor(error: ApiError | undefined | null, status?: number) {
    if (error) {
      this.error = {
        ...error,
        status: status ?? null,
      };
    }
  }

  public message() {
    return this.error?.message;
  }

  public status() {
    return this.error?.status;
  }

  public has(name: string): string[] | null {
    if (
      (!this.error?.body?.errors ||
        this.error?.body?.errors[name] === undefined) &&
      this.error?.errors?.[name] === undefined
    ) {
      return null;
    }

    if (this.error.errors) {
      return this.error.errors[name];
    }

    return this.error?.body?.errors[name];
  }

  public hasFirst(name: string): string | null {
    if (this.error?.errors?.[name] === undefined) {
      return null;
    }

    return this.error.errors[name][0] ?? null;
  }

  public isError(): boolean {
    return this.error !== null;
  }
}

export const useErrorHelper = (error: ApiError | undefined | null) => {
  return new ErrorHelper(error);
};
