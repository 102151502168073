import SimpleTitle from '@/Components/SimpleTitle';
import { Button } from '@/Components/v2/Button';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tooltip } from '@/Components/v2/Tooltip';
import { Plus } from 'lucide-react';
import * as React from 'react';
import BrandVoiceEmptyIcon from '../../../../public/svg/brand-voice-empty-icon.png';

type Props = {
  title: string;
  buttonProps?: {
    onClick: () => void;
    disabled: boolean;
    toolTip: {
      title: string;
      description: string;
    };
    text: string;
    isLoading?: boolean;
  };
  children: React.ReactNode;
  isLoading?: boolean;
  isEmptyProps?: {
    isEmpty: boolean;
    text: string;
    onClick: () => void;
    buttonText: string;
  };
};

export const CardSection = ({
  title,
  buttonProps,
  children,
  isLoading,
  isEmptyProps,
}: Props) => {
  return (
    <div className="my-12">
      <div className="flex w-full items-center justify-between">
        <SimpleTitle>{title}</SimpleTitle>
        {buttonProps && (
          <Tooltip
            disabled={!buttonProps.disabled}
            title={buttonProps.toolTip.title}
            description={buttonProps.toolTip.description}
            side="top"
          >
            <Button
              isLoading={buttonProps.isLoading}
              disabled={buttonProps.disabled}
              color="secondary"
              prependIcon={Plus}
              onClick={buttonProps.onClick}
              text={buttonProps.text}
            />
          </Tooltip>
        )}
      </div>
      <div className="mt-2 h-0.5 w-full bg-gray-200" />
      <div className="grid grid-cols-3 gap-2 pt-10">
        {isLoading ? (
          <>
            <>
              <span>
                <SkeletonLoader height="xl" />
              </span>
              <span>
                <SkeletonLoader height="xl" />
              </span>
              <span>
                <SkeletonLoader height="xl" />
              </span>
            </>
          </>
        ) : !isEmptyProps ? (
          children
        ) : isEmptyProps.isEmpty ? (
          <>
            <div />
            <div className="flex w-full flex-col items-center justify-center gap-4">
              <img className="h-40" src={BrandVoiceEmptyIcon} />
              <h1 className="italic text-gray-400">{isEmptyProps.text}</h1>
              <div>
                <Button
                  variant="outline"
                  color="secondary"
                  prependIcon={Plus}
                  text={isEmptyProps.buttonText}
                  onClick={isEmptyProps.onClick}
                />
              </div>
            </div>
            <div />
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
