import { AnimatedCollapsibleContainer } from '@/Components/AnimatedCollapsibleContainer';
import { CurrentSlideOver } from '@/types';
import { useEffect, useState } from 'react';
import { UrlInput } from './PlateEditor/components/url-input';
import { TitleInput } from './PlateEditor/components/title-input';
import { MetaDescriptionInput } from './PlateEditor/components/meta-description-input';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useMetaDescriptionState, useMetaTitleState } from '../hooks';
import { DocumentResource } from '@/api/openapiSchemas';

type Props = {
  document: DocumentResource;
  onMetaTitleFocus: () => void;
  onMetaDescriptionFocus: () => void;
  currentSlideOver?: CurrentSlideOver;
};

export const CollapsibleMetadata = ({
  currentSlideOver,
  document,
  onMetaDescriptionFocus,
  onMetaTitleFocus,
}: Props) => {
  const [isOpen, setIsOpen] = useState(
    !document.meta_description || !document.meta_title,
  );

  useEffect(() => {
    if (
      currentSlideOver === 'meta-title' ||
      currentSlideOver === 'meta-description'
    ) {
      setIsOpen(true);
    }
  }, [currentSlideOver]);

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer items-center gap-2 p-2 text-lg text-primary-600 hover:bg-primary-50"
      >
        URL, title and meta description
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isOpen && (
        <div className="flex flex-col gap-3 pt-6">
          <UrlInput />
          <TitleInput handleOpenSlideOver={onMetaTitleFocus} />
          <MetaDescriptionInput handleOpenSlideOver={onMetaDescriptionFocus} />
        </div>
      )}
    </div>
  );
};
