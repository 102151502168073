import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDroppable } from '@dnd-kit/core';
import { UploadCloud } from 'lucide-react';

type Props = { el: DocumentElementResource | null };
export const TemplateDrop = ({ el }: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'add-new-droppable',
    data: { el },
  });

  const color = isOver ? 'secondary' : 'white';

  const variant = isOver ? 'filled-outline' : 'outline';

  return (
    <div ref={setNodeRef}>
      <Card border="dashed" variant={variant} color={color}>
        <CardBody>
          <div className="flex items-center justify-center gap-2">
            <UploadCloud />
            <p className="font-extrabold text-primary">
              DRAG ELEMENT HERE TO ADD SECTION
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
