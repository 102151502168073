type Props = {
  height?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
  round?: boolean;
  square?: boolean;
};

export const SkeletonLoader = ({ height = 'sm', round, square }: Props) => {
  const getHeight = () => {
    switch (height) {
      case '2xs':
        return 'h-4';
      case 'xs':
        return 'h-8';
      case 'sm':
        return 'h-12';
      case 'md':
        return 'h-20';
      case 'lg':
        return 'h-32';
      case 'xl':
        return 'h-44';
      case '2xl':
        return 'h-64';
      case 'full':
        return 'h-full';
      default:
        return '';
    }
  };

  return (
    <div
      className={`relative ${
        square ? 'aspect-square' : 'w-full'
      } overflow-hidden bg-gray-100 ${
        round ? 'rounded-full' : 'rounded'
      } ${getHeight()}`}
    >
      <div className="absolute inset-0 animate-shine bg-gradient-to-r from-transparent from-0% via-gray-50 via-45% to-transparent to-100% bg-no-repeat" />
    </div>
  );
};
