import { useState } from 'react';
import { useSnackbar } from '../v2/Snackbar';

type SortDirection = 'ASC' | 'DESC';

export type TableState<SortingKeys, Filters> = {
  sortBy?: SortingKeys;
  sortDirection?: SortDirection;
  sortName?: string;
  filters?: Filters;
  page: number;
};

export type SortParameters<T> = {
  sortBy?: T;
  sortDirection?: SortDirection;
  sortName?: string;
};

type SelectionTableItem<T> = T & { id: number };

export const useCopyTable = (selection: string) => {
  const { showSnackbar } = useSnackbar();
  return () => {
    navigator.clipboard.writeText(selection).then(() => {
      showSnackbar({
        color: 'secondary',
        message: 'Copied to clipboard',
      });
    });
  };
};

export const useTableSelection = <T = any>() => {
  const [selection, setSelection] = useState<SelectionTableItem<T>[]>([]);

  const handleSetSelection = (
    added: SelectionTableItem<T>[],
    removed: SelectionTableItem<T>[],
  ) => {
    const newSelection = [...selection];
    for (const element of added) {
      const index = newSelection.findIndex(
        (item) => item['id'] === element['id'],
      );
      if (index === -1) {
        newSelection.push(element);
      }
    }

    for (const element of removed) {
      const index = newSelection.findIndex(
        (item) => item['id'] === element['id'],
      );
      if (index !== -1) {
        newSelection.splice(index, 1);
      }
    }

    setSelection(newSelection);
  };
  return {
    selection,
    setSelection: handleSetSelection,
    resetSelection: () => setSelection([]),
  };
};

export const useTable = <T, F, E = any>(
  initialState?: Partial<TableState<T, Partial<F>>>,
) => {
  const [submittedState, setSubmittedState] = useState<
    TableState<T, Partial<F>>
  >({
    ...initialState,
    page: 1,
  });
  const { selection, setSelection, resetSelection } = useTableSelection<E>();

  const [state, setState] = useState<TableState<T, Partial<F>>>({
    ...initialState,
    page: 1,
  });

  const setSorting = (
    sorting: SortParameters<T>,
    options?: { submit: boolean },
  ) => {
    setState((p) => ({
      ...p,
      ...sorting,
    }));
    if (options?.submit) {
      setSubmittedState((p) => ({
        ...p,
        ...sorting,
      }));
    }
  };

  const resetSorting = (options?: { submit: boolean }) => {
    setSorting(
      {
        sortBy: undefined,
        sortDirection: undefined,
        sortName: undefined,
      },
      options,
    );
  };

  const setFilters = (filters?: Partial<F>, options?: { submit?: boolean }) => {
    setState((p) => ({
      ...p,
      page: 1,
      filters: filters
        ? { ...(p.filters ?? {}), ...(filters as F) }
        : undefined,
    }));

    if (options?.submit) {
      setSubmittedState((p) => ({
        ...p,
        page: 1,
        filters: filters
          ? { ...(p.filters ?? {}), ...(filters as F) }
          : undefined,
      }));
    }
  };

  const setPage = (page: number) => {
    setState((p) => ({
      ...p,
      page,
    }));
  };

  const submit = () => {
    setSubmittedState(() => ({
      ...state,
    }));
  };

  return {
    setSelection,
    selection,
    setSorting,
    setFilters,
    setPage,
    resetSorting,
    resetSelection,
    tableState: state,
    submit,
    submittedTableState: submittedState,
  };
};
