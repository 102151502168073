import {
  Activity,
  ArrowDown,
  GripHorizontal,
  Maximize2,
  Minimize2,
  Minus,
  Zap,
} from 'lucide-react';
import * as React from 'react';
import { prompts } from '@/data/prompts';
import {
  HumanMessage,
  SelectedTextMessage,
  AIMessageOverlay,
  AIMessage,
  GetStartedChatBubbleText,
  ChatInputIcons,
} from './components';
import {
  BlockSelection,
  Generating,
  Message,
  PromptInfo,
  isHumanMessage,
} from '@/types';
import {
  useChangeDocumentModel,
  useStartMessageStreamMutation,
} from '@/Components/Utils/v2/api';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';
import { useGetDraggableState } from '@/Hooks/useGetDraggableState';
import { Trash2 } from 'lucide-react';
import ToggleButton from '@/Components/ToggleButton';
import { useWindowSize } from '@/Hooks/useWindowSize';
import ReactMarkdown from 'react-markdown';
import { useScrollIntoView } from './hooks/useScrollIntoView';
import { useTextAreaResize } from './hooks/useTextAreaResize';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import remarkGfm from 'remark-gfm';
import { Movable, MoveEvent } from '@/Components/Movable';
import { useFeatureFlagging } from '@/Pages/AppLoader/AppLoader';
import { tracking } from '@/Services/tracking/Tracking';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Button } from '@/Components/v2/Button';
import {
  DocumentsChatMessagesIndexResponse,
  useDocumentChatClear,
  useDocumentsChatMessagesDestroy,
  useDocumentsChatMessagesIndex,
  useDocumentsChatMessagesStore,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { useSnackbar } from '@/Components/v2/Snackbar';

type Props = {
  documentId: number;
  selection: BlockSelection | null;
  deselectSelection: () => void;
  onMaximize: () => void;
  isMaximized: boolean;
};

const limit = 15000;
export function ChatTab({
  documentId,
  selection,
  deselectSelection,
  onMaximize,
  isMaximized,
}: Props) {
  const { showSnackbar } = useSnackbar();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { hasFeature } = useFeatureFlagging();
  const [isMoving, setIsMoving] = React.useState(false);
  const [marginLeft, setMarginLeft] = React.useState(
    Number(
      localStorage.getItem('chattab_position') ??
        '' + (window.innerWidth - 600),
    ),
  );
  const client = useQueryClient();

  const { draggableState } = useGetDraggableState();
  const [{ isChatOpen, highlight }, setChat] = React.useState({
    highlight: false,
    isChatOpen: false,
  });
  const [text, setText] = React.useState('');
  const { handleScrollIntoView, isInView, scrollToDiv } =
    useScrollIntoView(isChatOpen);
  const { textAreaRef, bottomChatRef } = useTextAreaResize(text);
  const variables = {
    pathParams: { document: documentId },
  };
  const queryKey = queryKeyFn({
    path: '/documents/{document}/chat-messages',
    operationId: 'documentsChatMessagesIndex',
    variables,
  });
  const { data: chatMessages, isLoading } =
    useDocumentsChatMessagesIndex(variables);

  const { mutateAsync: sendMessageAsync, isPending: isSendingMessage } =
    useDocumentsChatMessagesStore({
      onMutate: (variables) => {
        if (variables.body.content.length <= limit) {
          const message: Message = {
            content: variables.body.content,
            display_type: variables.body.display_type,
            is_moderated: false,
            id: variables.body.display_type === 'SELECTION' ? -1 : 0,
            role: 'user',
          };
          client.setQueryData<DocumentsChatMessagesIndexResponse>(
            queryKey,
            (prev) => {
              if (!prev) {
                return prev;
              }
              return { data: [message, ...prev.data] };
            },
          );
        }
      },
    });

  const windowSize = useWindowSize();
  const {
    handleStartStream,
    data: { isLoadingMessage, content, isStreamingMessage },
    handleSetLoadingMessage,
  } = useStartMessageStreamMutation(documentId, queryKey);

  const { mutate: clearChat, isPending: isClearingChat } = useDocumentChatClear(
    {
      onMutate: () => {
        client.setQueryData(queryKey, () => []);
      },
      onSuccess: () => {
        client.invalidateQueries({ queryKey });
      },
    },
  );
  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  const deleteChatMessageMutation = useDocumentsChatMessagesDestroy();

  const { changeQuality, quality, qualityIsPending } =
    useChangeDocumentModel(documentId);

  const handleSendMessage = async (predefinedText?: string) => {
    handleScrollIntoView();
    if (selection && selection.selected.showInChat) {
      try {
        await sendMessageAsync({
          pathParams: { document: documentId },
          body: {
            content: selection.selected.text,
            display_type: 'SELECTION',
          },
        });
        if (!predefinedText) {
          if (!text) return;
          setText('');
        }
        deselectSelection();
      } catch (error: any) {
        showSnackbar({
          alignment: 'bottom',
          message: error.message,
          color: 'red',
        });
        return;
      }
    }
    handleSetLoadingMessage(true);

    try {
      await sendMessageAsync({
        pathParams: { document: documentId },
        body: {
          content: predefinedText || text,
          display_type: 'MESSAGE',
        },
      });
      if (!predefinedText) {
        if (!text) return;
        setText('');
      }
      handleStartStream();
    } catch (error: any) {
      showSnackbar({
        alignment: 'top',
        message: error.message,
        color: 'red',
      });
      handleSetLoadingMessage(false);
    }
  };

  const createPrompts = (): (PromptInfo & { content: string })[] =>
    prompts.map((prompt) => {
      return {
        content: prompt.prompt,
        title: prompt.title,
        type: 'custom',
        Icon: prompt.Icon,
      };
    });

  const handlePrompt = (
    prompt: Omit<PromptInfo, 'Icon'> & { content?: string },
  ) => {
    tracking.event('document_chat_quick_prompt', {
      title: prompt.title,
      prompt: prompt.content,
    });
    if (!isChatOpen) {
      setChat((prev) => ({ ...prev, isChatOpen: true }));
    }
    handleSendMessage(prompt.content);
  };

  const handleSubmitEditedMessage = (id: number, text: string) => {
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: documentId,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleSendMessage(text);
          });
        },
      },
    );
  };

  const handleRefreshMessage = (id: number) => {
    handleSetLoadingMessage(true);
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: documentId,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleStartStream();
          });
        },
      },
    );
  };

  const displayMessages = () => {
    let messages: (string | Message)[] = [];
    if (!selection && !chatMessages?.data) {
      return;
    }

    if (chatMessages?.data) {
      messages = [...messages, ...chatMessages?.data];
    }

    return messages.map((message, i) => {
      if (typeof message === 'string') {
        return <SelectedTextMessage key={message} text={message} />;
      }
      if (isHumanMessage(message)) {
        if (message.display_type === 'SELECTION') {
          return (
            <SelectedTextMessage key={message.id} text={message.content} />
          );
        }
        return (
          <HumanMessage
            key={message.id}
            id={message.id}
            isLoading={
              message.id === 0 &&
              isLoadingMessage &&
              deleteChatMessageMutation.isPending
            }
            disabled={
              isStreamingMessage ||
              !!isGenerating ||
              deleteChatMessageMutation.isPending
            }
            text={message.content}
            onSubmitEdit={handleSubmitEditedMessage}
          />
        );
      }
      return (
        <AIMessage
          isLastAIMessage={i === 0}
          key={message.id}
          text={message.content}
          id={message.id}
          handleRefreshMessage={handleRefreshMessage}
        />
      );
    });
  };

  const noMessages = !chatMessages?.data || chatMessages.data.length === 0;

  const isValidChatMessage = () => {
    return (
      text.trim().length > 0 &&
      !isLoadingMessage &&
      content === null &&
      !isGenerating
    );
  };

  React.useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as HTMLDivElement)) {
        const element = e.target as HTMLDivElement;
        if (
          typeof element.className === 'string' &&
          !element.className.includes('submit-edited-message-button')
        ) {
          setChat({ highlight: false, isChatOpen: false });
        }
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [containerRef]);

  const showSelectionInChat =
    selection !== null && selection.selected.showInChat;

  const getBackgroundColor = () => {
    if (!isChatOpen || showSelectionInChat) {
      return 'bg-green-50';
    }
    return '';
  };

  // *** Chat dragging ***

  const limitChatPositionInWindow = (position: number, chatWidth: number) => {
    if (position < 40) {
      return 40;
    }
    if (position > window.innerWidth - chatWidth - 40) {
      return window.innerWidth - chatWidth - 40;
    }

    return position;
  };

  const handleMove = (e: MoveEvent) => {
    setMarginLeft((p) => {
      const position = limitChatPositionInWindow(
        p + e.offset.x,
        e.dimensions.x,
      );
      localStorage.setItem('chattab_position', '' + position);
      return position;
    });
  };

  // *** Chat dragging end ****

  const getChatSize = () => {
    if (isMaximized && isChatOpen) {
      return 'w-5/12';
    }
    if (isChatOpen) {
      return 'w-4/12';
    }
    return 'w-80';
  };

  React.useEffect(() => {
    if (!isSendingMessage && isChatOpen) {
      textAreaRef.current?.focus();
    }
  }, [isSendingMessage]);

  React.useEffect(() => {
    const position = limitChatPositionInWindow(
      marginLeft,
      containerRef.current?.clientWidth ?? 0,
    );
    setMarginLeft(position);
  }, [isChatOpen]);

  return (
    <>
      <div
        className={`absolute -bottom-0 left-0 z-[100] min-w-[15rem] rounded-t-2xl border-primary-200 bg-white shadow-base ${getChatSize()}`}
        style={{
          marginLeft,
          borderLeftWidth: '0.5px',
        }}
      >
        <div
          ref={containerRef}
          className="relative w-[100%] flex-col justify-end rounded-t-2xl bg-white"
        >
          {isChatOpen && (
            <Movable
              onMove={handleMove}
              onMoveEnd={() => setIsMoving(false)}
              onMoveStart={() => setIsMoving(true)}
            >
              <div
                onClick={() =>
                  isChatOpen &&
                  !isMoving &&
                  setChat((prev) => ({ ...prev, isChatOpen: false }))
                }
                className="flex items-center justify-between rounded-t-2xl border-b-[1px] border-[#e5e7eb] bg-green-50 p-1"
              >
                <div className="flex items-center">
                  <IconButton
                    icon={Minus}
                    onClick={(e) => {
                      setChat((prev) => ({ ...prev, isChatOpen: false }));
                    }}
                  />
                  <IconButton
                    icon={isMaximized ? Minimize2 : Maximize2}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMaximize();
                    }}
                  />
                  {!qualityIsPending &&
                  hasFeature('chat-ai-quality-settings') ? (
                    <ToggleButton
                      className="ml-1 h-10"
                      value={quality}
                      onChange={changeQuality}
                      options={[
                        {
                          displayName: windowSize.width > 1800 ? 'Speed' : '',
                          icon: Zap,
                          value: false,
                        },
                        {
                          displayName: windowSize.width > 1800 ? 'Quality' : '',
                          icon: Activity,
                          value: true,
                        },
                      ]}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    clearChat({ pathParams: { document: documentId } });
                  }}
                  isLoading={isClearingChat}
                  prependIcon={Trash2}
                  text={windowSize.width > 1800 ? 'Clear chat' : undefined}
                  variant="ghost"
                />
              </div>
            </Movable>
          )}

          <div
            className={`${
              !isChatOpen ? 'shadow-base' : ''
            } group absolute bottom-0 left-[0.5%] flex w-[99%] flex-col rounded-t-2xl`}
          >
            <div
              className={`absolute bottom-[100%] left-0 right-0 z-50 flex justify-center opacity-0 group-hover:opacity-100 ${
                isChatOpen ? 'hidden' : ''
              }`}
            >
              <Movable
                onMove={(e) =>
                  handleMove({
                    ...e,
                    dimensions: {
                      x: containerRef.current?.clientWidth ?? 0,
                      y: containerRef.current?.clientHeight ?? 0,
                    },
                  })
                }
                onInit={handleMove}
                onResize={handleMove}
                onMoveEnd={() => setIsMoving(false)}
                onMoveStart={() => setIsMoving(true)}
              >
                <GripHorizontal className="w-12 rounded-t border border-primary-200 bg-primary-25 px-2" />
              </Movable>
            </div>
            {isChatOpen && (
              <>
                <div
                  className={`absolute -top-12 right-4 z-40 animate-bounce rounded-3xl ${
                    isInView ? 'hidden' : 'block'
                  }`}
                >
                  <IconButton
                    icon={ArrowDown}
                    onClick={handleScrollIntoView}
                    color="primary"
                  />
                </div>
                <div
                  className="bottom-26 sticky right-0 z-10 w-[85%] self-center bg-white"
                  style={{
                    WebkitBoxShadow: '0px 0px 39px 39px #fff',
                    boxShadow: '0px 0px 39px 39px #fff',
                  }}
                />
              </>
            )}
            <div
              ref={bottomChatRef}
              className="flex items-end overflow-hidden rounded-t-2xl bg-white"
            >
              <div className="relative z-10 flex w-full flex-col bg-white">
                <div className="rounded-t-2xl bg-white">
                  <div
                    onClick={() =>
                      !isChatOpen &&
                      setChat((prev) => ({ ...prev, isChatOpen: true }))
                    }
                    className={`rounded-t-2xl ${getBackgroundColor()} px-1 py-1`}
                  >
                    <div
                      className={
                        showSelectionInChat
                          ? `relative mt-1 rounded-b-2xl rounded-t-xl border-2 border-b-0 border-dashed border-primary-200 bg-white`
                          : ''
                      }
                    >
                      {showSelectionInChat && (
                        <div
                          className={`p-1 ${
                            isSendingMessage ? 'opacity-50' : ''
                          }`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <label className="p-3 text-sm font-semibold text-primary-600">
                            SELECTED TEXT
                          </label>
                          <div
                            style={{ maxHeight: '3vh', height: '3vh' }}
                            className="slim-scroll mt-1 overflow-y-scroll rounded-t-2xl bg-white px-3"
                          >
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              className="prose text-base leading-tight"
                            >
                              {selection?.selected.text}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}
                      <Movable
                        onMove={handleMove}
                        onInit={handleMove}
                        onResize={handleMove}
                        onMoveEnd={() => setIsMoving(false)}
                        onMoveStart={() => setIsMoving(true)}
                      >
                        <div className={`p-1`}>
                          <form
                            tabIndex={0}
                            className={` relative -ml-[2px] -mr-[2px] rounded-2xl border-2 bg-white ${
                              highlight
                                ? 'border-green-400'
                                : 'border-green-200'
                            } p-1`}
                          >
                            <textarea
                              ref={textAreaRef}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  if (e.shiftKey) {
                                    return;
                                  }
                                  e.preventDefault();

                                  if (isValidChatMessage()) {
                                    handleSendMessage();
                                  }
                                }
                              }}
                              onFocus={() => {
                                setChat({ highlight: true, isChatOpen: true });
                              }}
                              onBlur={() => {
                                setChat((prev) => ({
                                  ...prev,
                                  highlight: false,
                                }));
                              }}
                              onMouseDown={(e) => e.stopPropagation()}
                              rows={1}
                              name="comment"
                              id="comment"
                              disabled={!!isGenerating || isSendingMessage}
                              placeholder="Message AI assistant..."
                              className={`${
                                isSendingMessage ? 'opacity-50' : ''
                              } slim-scroll w-full resize-none border-0 border-white p-0 pb-0.5 pl-4 pr-[4.5rem] pt-1.5 ring-0 placeholder:text-primary-500 focus:border-white sm:leading-6`}
                              onChange={(e) => setText(e.target.value)}
                              value={text.slice(0, limit)}
                            />
                            <ChatInputIcons
                              disablePrompts={
                                content !== null ||
                                isLoadingMessage ||
                                !!isGenerating ||
                                isMoving ||
                                isSendingMessage
                              }
                              prompts={createPrompts()}
                              onPromptClick={handlePrompt}
                              disableButton={
                                !isValidChatMessage() || isSendingMessage
                              }
                              onSendMessage={handleSendMessage}
                            />
                          </form>
                        </div>
                        <div />
                      </Movable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isChatOpen && (
            <div
              className="slim-scroll relative flex flex-col-reverse justify-between overflow-auto overflow-x-hidden"
              style={{ height: 'calc(70vh - 53px)' }}
            >
              <div className="bottom-64 flex flex-col-reverse">
                <div ref={scrollToDiv} />
                <div
                  className="mt-12"
                  style={{
                    height: (bottomChatRef.current?.clientHeight || 0) + 'px',
                  }}
                />

                {isLoading ? (
                  <div className="my-2 flex flex-col gap-2 px-6">
                    <div className="h-[15vh] w-full animate-pulse bg-primary-300" />
                    <div className="h-[15vh] w-full animate-pulse bg-primary-300" />
                    <div className="h-[15vh] w-full animate-pulse bg-primary-300" />
                    <div className="h-[15vh] w-full animate-pulse bg-primary-300" />
                    <div className="h-[15vh] w-full animate-pulse bg-primary-300" />
                  </div>
                ) : (
                  <>
                    {content && <AIMessageOverlay text={content} id={0} />}
                    {displayMessages()}
                    {noMessages && <GetStartedChatBubbleText />}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {chatMessages?.data &&
          draggableState &&
          createPortal(
            <DragOverlay adjustScale={false} zIndex={2} dropAnimation={null}>
              {chatMessages?.data && draggableState && (
                <AIMessageOverlay text={draggableState.activeElement.text} />
              )}
            </DragOverlay>,
            window.document.body,
          )}
      </div>
    </>
  );
}
