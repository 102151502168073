import { TabBarSkeleton } from './TabBarSkeleton';
import { TabItem, TabItemProps } from './TabItem';

type Props = {
  tabs: Omit<TabItemProps, 'grow' | 'active'>[];
  type?: 'center' | 'left' | 'right' | 'grow';
  onChange: (index: number) => void;
  disabled?: boolean;
  value: number;
};

export const Tabs = ({
  tabs,
  type = 'left',
  onChange,
  value,
  disabled,
}: Props) => {
  return (
    <TabBarSkeleton alignment={type} noBorder={tabs.length === 0}>
      {tabs.map((tab, index) => (
        <TabItem
          {...tab}
          disabled={disabled || tab.disabled}
          grow={type === 'grow'}
          active={value === index}
          onClick={tab.onClick ? tab.onClick : () => onChange(index)}
        />
      ))}
    </TabBarSkeleton>
  );
};
