import * as Table from '@/Components/Table';
import {
  RangeSelector,
  formatRange,
} from '@/Components/Table/components/RangeSelector';
import { TableState } from '@/Components/Table/hooks';

type Props = {
  tableState: TableState<
    'id',
    Partial<{
      search: string;
      change_min: number;
      change_max: number;
      position_min: number;
      position_max: number;
      search_volume_min: number;
      search_volume_max: number;
      difficulty_min: number;
      difficulty_max: number;
    }>
  >;
  setFilters: (
    filters: Partial<{
      search: string;
      change_min: number;
      change_max: number;
      position_min: number;
      position_max: number;
      search_volume_min: number;
      search_volume_max: number;
      difficulty_min: number;
      difficulty_max: number;
    }>,
  ) => void;
};

export const RankTrackingTableFilters = ({ tableState, setFilters }: Props) => {
  return (
    <div className="flex gap-2">
      {/* Position */}
      <Table.FilterPopover
        name="Position"
        onRemoveFilter={() =>
          setFilters({
            position_min: undefined,
            position_max: undefined,
          })
        }
        filterName={formatRange({
          max: tableState.filters?.position_max,
          min: tableState.filters?.position_min,
          name: 'Position',
        })}
      >
        <RangeSelector
          range={{ min: 1, max: 100 }}
          onAccept={(values) =>
            setFilters({
              position_min: values.min,
              position_max: values.max,
            })
          }
          values={{
            max: tableState.filters?.position_max,
            min: tableState.filters?.position_min,
          }}
        />
      </Table.FilterPopover>

      {/* Change */}
      <Table.FilterPopover
        name="Change"
        onRemoveFilter={() =>
          setFilters({
            change_min: undefined,
            change_max: undefined,
          })
        }
        filterName={formatRange({
          max: tableState.filters?.change_max,
          min: tableState.filters?.change_min,
          name: 'Change',
        })}
      >
        <RangeSelector
          range={{ min: -100, max: 100 }}
          onAccept={(values) =>
            setFilters({
              change_min: values.min,
              change_max: values.max,
            })
          }
          values={{
            max: tableState.filters?.change_max,
            min: tableState.filters?.change_min,
          }}
        />
      </Table.FilterPopover>

      {/* Search volume */}
      <Table.FilterPopover
        name="Search volume"
        onRemoveFilter={() =>
          setFilters({
            search_volume_min: undefined,
            search_volume_max: undefined,
          })
        }
        filterName={formatRange({
          max: tableState.filters?.search_volume_max,
          min: tableState.filters?.search_volume_min,
          name: 'Search volume',
        })}
      >
        <RangeSelector
          range={{ min: 0 }}
          onAccept={(values) =>
            setFilters({
              search_volume_min: values.min,
              search_volume_max: values.max,
            })
          }
          values={{
            max: tableState.filters?.search_volume_max,
            min: tableState.filters?.search_volume_min,
          }}
        />
      </Table.FilterPopover>

      {/* Difficulty */}
      <Table.FilterPopover
        name="Difficulty"
        onRemoveFilter={() =>
          setFilters({
            difficulty_min: undefined,
            difficulty_max: undefined,
          })
        }
        filterName={formatRange({
          max: tableState.filters?.difficulty_max,
          min: tableState.filters?.difficulty_min,
          name: 'Difficulty',
        })}
      >
        <RangeSelector
          range={{ min: 1, max: 100 }}
          onAccept={(values) =>
            setFilters({
              difficulty_min: values.min,
              difficulty_max: values.max,
            })
          }
          values={{
            max: tableState.filters?.difficulty_max,
            min: tableState.filters?.difficulty_min,
          }}
        />
      </Table.FilterPopover>
    </div>
  );
};
