import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Plus } from 'lucide-react';
import { useState } from 'react';

type Props = {
  keywords: { id: number; name: string }[];
  onClose: () => void;
  listItems: { title: string; value: number }[];
  isDisabled: boolean;
  error?: any;
  handleSubmit: (name: string, selectedListId: number) => Promise<void>;
  isPending: boolean;
};

export const AddKeywordsToListDialog = ({
  keywords,
  onClose,
  listItems,
  isDisabled,
  handleSubmit,
  error,
  isPending,
}: Props) => {
  const [name, setName] = useState('');
  const [selectedListId, setSelectedListId] = useState<number>(-1);

  const isOpen = keywords.length > 0;

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Add keywords to list"
      error={error}
      content={
        <>
          <p className="mb-2">
            Select a list to add{' '}
            {keywords.length > 5 ? keywords.length : 'the following'} keywords
            to:
          </p>
          {keywords.length <= 5 && (
            <ul>
              {keywords.map((keyword) => (
                <li key={keyword.id}>• {keyword.name}</li>
              ))}
            </ul>
          )}
          <div className="mt-6">
            <InputDecoration label="List" required>
              {isDisabled ? (
                <SkeletonLoader height="sm" />
              ) : (
                <Select
                  fullWidth
                  value={selectedListId}
                  onChange={(value) => setSelectedListId(Number(value))}
                  options={[
                    {
                      title: 'Create new list',
                      value: -1,
                      prependIcon: Plus,
                    },
                    ...listItems,
                  ]}
                />
              )}
            </InputDecoration>

            {selectedListId === -1 && (
              <div className="mt-4">
                <InputDecoration label="New list name" required>
                  <Input
                    value={name}
                    onChange={setName}
                    counter
                    counterMax={32}
                  />
                </InputDecoration>
              </div>
            )}
          </div>
        </>
      }
      isLoading={isPending}
      onClose={onClose}
      onConfirm={() => handleSubmit(name, selectedListId)}
      disabled={isDisabled || !selectedListId}
    />
  );
};
