import React from 'react';
import { Footer } from './components/Footer';
import { SortParameters } from './hooks';
import { ChevronsUpDown } from 'lucide-react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { SubRow, TableRow } from './components/TableRow';
import { NoContent } from './components/NoContent';
import { Header } from '.';

export type Column<T> = {
  property?: keyof T;
  rightAlign?: boolean;
  sortableHeader?: boolean | string;
  heading?: string | React.ReactNode;
  render?: (
    item: T,
    index: number,
    state: {
      toggleSubRows: () => void;
      isSubrowsOpen: boolean;
      hasSubrows: boolean;
      subrowCount: number;
    },
  ) => JSX.Element;
};

export type Meta = {
  current_page: number;
  from: number | null;
  last_page: number;
  path: string | null;
  per_page: number;
  to: number | null;
  total: number;
  has_more: boolean;
  has_less: boolean;
  next_page: number | null;
  previous_page: number | null;
};

type Props<T, SR> = {
  sorting?: SortParameters<any>;
  onSelectionChange?: (added: T[], removed: T[]) => void;
  selection?: T[];
  selectionIdentifyBy?: string;
  onSortChange?: (sorting: SortParameters<any>) => void;
  items: (T & { tableRowClassNames?: string })[] | undefined;
  skeletonLoaders?: number;
  columns: Column<T>[];
  isLoading?: boolean;
  meta?: Meta;
  dense?: boolean;
  renderRow?: (args: {
    item: T;
    cells: JSX.Element[];
    index: number;
    classNames: string;
  }) => JSX.Element;
  subRows?: SubRow<T, SR>;
  children?: JSX.Element | JSX.Element[];
};

export const Table = <T, SR>({
  columns,
  dense = false,
  skeletonLoaders = 25,
  isLoading,
  items = [],
  meta,
  sorting,
  onSelectionChange,
  selection,
  selectionIdentifyBy = 'id',
  onSortChange,
  subRows,
  renderRow,
  children,
}: Props<T, SR>) => {
  const selectionEnabled = !!onSelectionChange && !!selection;

  const renderHeading = (column: Column<T>) => {
    if (column.heading) {
      if (typeof column.heading === 'string') {
        return column.heading?.toUpperCase();
      } else {
        return column.heading;
      }
    }
    return '' + ((column?.property ?? '') as string).toUpperCase();
  };

  const sortFunction = (a: any, b: any) => {
    if (!sorting) {
      return;
    }
    if (typeof a[sorting.sortBy] === 'string') {
      if (sorting.sortDirection === 'ASC') {
        return a[sorting.sortBy].localeCompare(b[sorting.sortBy]);
      } else {
        return b[sorting.sortBy].localeCompare(a[sorting.sortBy]);
      }
    }
    if (typeof a[sorting.sortBy] === 'number') {
      if (sorting.sortDirection === 'ASC') {
        return a[sorting.sortBy] - b[sorting.sortBy];
      } else {
        return b[sorting.sortBy] - a[sorting.sortBy];
      }
    }
  };

  const sortedItems = sorting ? [...items].sort(sortFunction) : items;
  const allItemsAreSelected =
    sortedItems.length > 0 &&
    sortedItems.every(
      (item) =>
        selection?.find(
          (selectedItem) =>
            selectedItem[selectionIdentifyBy] === item[selectionIdentifyBy],
        ),
    );

  const handleSortChange = (column: Column<any>) => {
    if (!onSortChange) {
      return;
    }
    const sortBy =
      typeof column.sortableHeader === 'string'
        ? column.sortableHeader
        : column.property;
    if (sorting?.sortBy !== sortBy) {
      return onSortChange({
        sortBy,
        sortName: '' + (column.property as string) + ' (ASC)',
        sortDirection: 'ASC',
      });
    }
    if (sorting?.sortDirection === 'ASC') {
      return onSortChange({
        ...sorting,
        sortName: '' + (column.property as string) + ' (DESC)',
        sortDirection: 'DESC',
      });
    }
    if (sorting?.sortDirection === 'DESC') {
      return onSortChange({
        sortBy: undefined,
        sortName: undefined,
        sortDirection: undefined,
      });
    }
  };

  const handleSelectAll = (value: boolean) => {
    if (!onSelectionChange) return;

    if (allItemsAreSelected) {
      onSelectionChange([], sortedItems);
    } else {
      onSelectionChange(sortedItems, []);
    }
  };

  const handleSelect = (value: boolean, item: T) => {
    if (!onSelectionChange) return;
    if (value) {
      onSelectionChange([item], []);
    } else {
      onSelectionChange([], [item]);
    }
  };

  return (
    <div className="w-full rounded-lg border border-primary-200 bg-white">
      {Array.isArray(children)
        ? children.filter((child) => child.type === (<Header />).type)
        : children?.type === (<Header />).type
          ? children
          : null}

      <div
        className={`flex justify-end pr-8 pt-2 text-sm text-primary-500 ${
          meta?.total ? '' : 'invisible'
        } ${isLoading ? 'opacity-0' : ''}`}
      >
        {meta?.from} - {meta?.to} of {meta?.total} results{' '}
      </div>

      <div className="px-8 pb-8">
        <table className="w-full">
          <thead>
            {selectionEnabled && (
              <th className="border-b border-primary-200 py-4 text-left text-sm text-primary-500">
                <SimpleCheckbox
                  checked={allItemsAreSelected}
                  onChange={handleSelectAll}
                  disabled={isLoading}
                />
              </th>
            )}
            {columns.map((column) => (
              <th
                className={`border-b border-primary-200 py-4 text-left text-sm text-primary-500 ${
                  column.sortableHeader && onSortChange ? 'cursor-pointer' : ''
                }`}
                onClick={
                  column.sortableHeader && onSortChange
                    ? () => handleSortChange(column)
                    : undefined
                }
              >
                <div
                  className={`flex items-center gap-2 whitespace-nowrap pr-4 ${
                    column.rightAlign ? 'justify-end' : ''
                  }`}
                >
                  {renderHeading(column)}
                  {column.sortableHeader &&
                    ((column.property !== sorting?.sortBy &&
                      column.sortableHeader !== sorting?.sortBy) ||
                    sorting?.sortBy === undefined ? (
                      <ChevronsUpDown size={14} />
                    ) : sorting?.sortDirection === 'ASC' ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    ))}
                </div>
              </th>
            ))}
            {subRows && <th className="border-b border-primary-200" />}
          </thead>
          <tbody>
            {isLoading &&
              Array.from({ length: skeletonLoaders }).map(() => (
                <tr>
                  <td colSpan={columns.length} className="w-full">
                    <div className="mt-1 h-12 animate-pulse rounded-lg bg-primary-100" />
                  </td>
                </tr>
              ))}
            {!isLoading && sortedItems?.length === 0 && (
              <tr>
                {' '}
                <td
                  colSpan={columns.length}
                  className="pt-6 text-center text-primary-400"
                >
                  {(Array.isArray(children)
                    ? children.find(
                        (child) => child.type === (<NoContent />).type,
                      )
                    : children?.type === (<NoContent />).type) ?? 'No results'}
                </td>
              </tr>
            )}
            {!isLoading &&
              sortedItems?.map((item, rowIndex) => (
                <TableRow
                  key={item.id}
                  item={item}
                  columns={columns}
                  selectionEnabled={selectionEnabled}
                  selection={selection}
                  selectionIdentifyBy={selectionIdentifyBy}
                  handleSelect={handleSelect}
                  renderRow={renderRow}
                  subRows={subRows}
                  rowIndex={rowIndex}
                  dense={dense}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="mb-6">
        {Array.isArray(children)
          ? children.filter((child) => child.type === (<Footer />).type)
          : children?.type === (<Footer />).type
            ? children
            : null}
      </div>
    </div>
  );
};
