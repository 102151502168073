import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { Outlet, useNavigate, useRouter } from '@tanstack/react-router';
import { Globe, Mic, Settings2 } from 'lucide-react';
import { useState } from 'react';
import { useFeatureFlagging } from '../AppLoader';

export const Settings = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const { hasFeature } = useFeatureFlagging();
  const getValue = () => {
    const pathName = router.history.location.pathname;
    switch (pathName) {
      case '/settings/website-urls':
        return 1;
      case '/settings/brand-voice':
        return 2;
      case '/settings/connections':
        return 3;
      default:
        return 0;
    }
  };
  const [tab, setTab] = useState(getValue());

  const handleChange = (value: number) => {
    switch (value) {
      case 1:
        navigate({ to: '/settings/website-urls' });
        break;
      case 2:
        navigate({ to: '/settings/brand-voice' });
        break;
      case 3:
        navigate({ to: '/settings/connections' });
        break;
      default:
        navigate({ to: '/settings/edit-project' });
        break;
    }
    setTab(value);
  };

  return (
    <Menus>
      <PageContainer>
        <SimpleTitle>{`Settings`}</SimpleTitle>
        <div className="my-4">
          <Tabs
            type="grow"
            onChange={handleChange}
            value={tab}
            tabs={[
              {
                text: 'CONFIGURATION',
                prependIcon: Settings2,
              },
              {
                text: 'URLS',
                prependIcon: Globe,
              },
              {
                text: 'BRAND VOICE',
                prependIcon: Mic,
              },
              ...(hasFeature('connections-tab')
                ? [
                    {
                      text: 'CONNECTIONS',
                      prependIcon: Settings2,
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <Outlet />
      </PageContainer>
    </Menus>
  );
};
