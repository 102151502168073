import {
  ProductSettingResource,
  SubscriptionResource,
} from '@/api/openapiSchemas';

export const getFeatures = (
  settings: ProductSettingResource[],
  subscription?: SubscriptionResource,
) => {
  return [
    getFeature(settings, 'generated_article_limit', subscription),
    getFeature(settings, 'seo_audit_limit', subscription),
    getFeature(settings, 'generated_words_limit', subscription),
    getFeature(settings, 'tracked_keyword_limit', subscription),
    getFeature(settings, 'organisation_url_limit', subscription),
    getFeature(settings, 'organisation', subscription),
  ];
};

const getFeature = (
  settings: ProductSettingResource[],
  feature: string,
  subscription?: SubscriptionResource,
): { text: string; disabled: boolean; unlimitedOver?: number } => {
  if (feature === 'organisation') {
    return {
      text:
        (subscription?.usage.credits.projects.limit ??
          settings.find(
            (setting) => setting.setting?.name === 'organisation_project_limit',
          )?.default_value) +
        ' websites / ' +
        (subscription?.usage.credits.users.limit ??
          settings.find(
            (setting) => setting.setting?.name === 'organisation_user_limit',
          )?.default_value) +
        ' users',
      disabled: false,
    };
  }
  if (feature === 'generated_words_limit') {
    return {
      text: 'Unlimited AI chat & AI-writing',
      disabled: false,
    };
  }
  for (const setting of settings) {
    if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'seo_audit_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.content.limit ?? setting.default_value) +
          ' Content audits per month',
        disabled: setting.default_value === 0,
        unlimitedOver: 999,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'generated_article_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.generated_article.limit ??
            setting.default_value) + ' AI articles per month',
        disabled: setting.default_value === 0,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'tracked_keyword_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.tracked_keywords.limit ??
            setting.default_value) + ' Tracked keywords',
        disabled: setting.default_value === 0,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'organisation_url_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.urls.limit ?? setting.default_value) +
          ' internal linking URLs',
        disabled: setting.default_value === 0,
      };
    }
  }
  return { text: '', disabled: false };
};
