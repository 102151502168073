import { SendSearchResult } from '@/types';
import { useEffect } from 'react';
import Document from './Document';
import SimpleButton from '@/Components/SimpleButton';
import ValidationErrors from '@/Components/ValidationErrors';
import { useDocumentStore } from './stores';
import { useShallow } from 'zustand/react/shallow';
import { useAppStore } from '../AppLoader/stores';
import { useRouter } from '@tanstack/react-router';
import { DocumentLoading } from './DocumentLoading';
import { useGetDocument } from '@/api/openapiComponents';

type Props = {
  documentId: number;
};

export default function DocumentLoader({ documentId }: Props) {
  const router = useRouter();
  const appState = useAppStore();
  const { setInitialProps, isLoadingHeadingDescription, reset } =
    useDocumentStore(
      useShallow(({ setInitialProps, isLoadingHeadingDescription, reset }) => ({
        setInitialProps,
        isLoadingHeadingDescription,
        reset,
      })),
    );

  const documentQuery = useGetDocument(
    {
      pathParams: {
        document: documentId,
        project: appState.currentProject!.id,
      },
    },
    {
      staleTime: 10000,
      retry: false,
      gcTime: 0,
    },
  );

  useEffect(() => {
    if (!documentQuery.isLoading && documentQuery.data?.data) {
      const heading = documentQuery.data.data.title;
      const metaDescription = documentQuery.data.data.meta_description;
      const url = documentQuery.data.data.url ?? '';
      const metaTitle = documentQuery.data.data.meta_title ?? '';
      setInitialProps(heading, metaDescription, url, metaTitle);
    }
  }, [documentQuery.isLoading]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    const url = `documents.${documentId}`;
    window.Echo.private(url).listen<SendSearchResult>(
      `DocumentUpdatedEvent`,
      (e) => {
        documentQuery.refetch();
      },
    );
    return () => {
      window.Echo.leave(url);
    };
  }, []);

  if (documentQuery.error?.message === 'Not found') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        Document not found
        <SimpleButton
          className="rounded-lg border border-gray-200"
          onClick={() => router.history.back()}
        >
          Go back
        </SimpleButton>
      </div>
    );
  }

  if (documentQuery.error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ValidationErrors apiError={documentQuery.error} />
      </div>
    );
  }

  if (documentQuery.isLoading || isLoadingHeadingDescription) {
    return <DocumentLoading />;
  }

  return (
    <Document
      refetchDocument={documentQuery.refetch}
      options_type={[
        'blog post',
        'blog post (how to)',
        'blog post (listicle)',
        'blog post (best)',
        'blog post (alternatives)',
        'blog post (beginners guide)',
        'blog post (what is)',
        'blog post (comparison)',
        'blog post (product review)',
        'press release',
        'product description page',
        'products description (category page)',
        'service page',
      ]}
      currentProject={appState.currentProject!}
      auth={appState.auth!}
      document={documentQuery.data!.data}
    />
  );
}
