import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { ContentListResource } from '@/api/openapiSchemas';
import { MoreVertical, Pencil, Plus, Trash2 } from 'lucide-react';
import { EditListDialog } from './EditListDialog';
import { RemoveListDialog } from './RemoveListDialog';
import { AddListDialog } from './AddListDialog';

type Controller = {
  list: ContentListResource | undefined;
  handleSubmit: (name?: string) => void;
  error?: any;
  open: (list: ContentListResource) => void;
  isLoading: boolean;
};

type Props = {
  isLoading: boolean;
  onChange: (index: number) => void;
  selectedTabIndex: number;
  selectedList: ContentListResource | undefined;
  data: ContentListResource[] | undefined;
  editController: Controller;
  deleteController: Controller;
  addController: Omit<Controller, 'list' | 'isLoading' | 'open'> & {
    isOpen: boolean;
    open: () => void;
  };
  onCloseDialog: () => void;
};

export const ListTab = ({
  isLoading,
  selectedTabIndex,
  data,
  onChange,
  selectedList,
  onCloseDialog,
  editController,
  deleteController,
  addController,
}: Props) => {
  return (
    <>
      <EditListDialog
        editedList={editController.list}
        error={editController.error}
        handleSubmit={editController.handleSubmit}
        key={editController.list?.id}
        onClose={onCloseDialog}
      />
      <RemoveListDialog
        handleDeleteSubmit={deleteController.handleSubmit}
        isLoading={deleteController.isLoading}
        key={deleteController.list?.id}
        onClose={onCloseDialog}
        deletedList={deleteController.list}
      />
      <AddListDialog
        key={addController.isOpen ? 'open' : 'close'}
        error={addController.error}
        isOpen={addController.isOpen}
        onClose={onCloseDialog}
        handleSubmit={addController.handleSubmit}
      />
      <div className="mb-2">
        {isLoading ? (
          <SkeletonLoader height="2xs" />
        ) : (
          <Tabs
            onChange={onChange}
            value={selectedTabIndex}
            tabs={[
              {
                text: 'All',
              },
              ...(data?.map((list) => ({
                text: list.name,
                appendIcon:
                  list.id === selectedList?.id ? (
                    <PopoverMenu
                      key={list.id}
                      trigger={
                        <IconButton size="sm" dense icon={MoreVertical} />
                      }
                      items={[
                        {
                          title: 'Edit list',
                          prependIcon: Pencil,
                          onClick: () => editController.open(list),
                        },
                        {
                          title: 'Delete list',
                          prependIcon: Trash2,
                          onClick: () => deleteController.open(list),
                        },
                      ]}
                    />
                  ) : null,
              })) ?? []),
              {
                prependIcon: Plus,
                text: 'Add new list',
                disabled: (data?.length ?? 0) >= 100,
                onClick: addController.open,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};
