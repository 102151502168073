import { CreatePageContainer } from '@/Pages/components';
import { TemplateSlideOver } from './components/TemplateSlideOver';
import { TemplateItem } from './components/TemplateItem';
import { TemplateDrop } from './components/TemplateDrop';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import {
  DocumentElementActionType,
  DocumentElementResource,
  DocumentElementType,
  DocumentTemplateElementResource,
  DocumentTemplateResource,
  HeadingType,
} from '@/api/openapiSchemas';
import { useEffect, useRef, useState } from 'react';
import { TemplateDragItem } from './components/TemplateDragItem';
import { SortableContext } from '@dnd-kit/sortable';
import { createUniqueIdentifier } from '@/Pages/Document/components/PlateEditor/lib/transform';
import { PlusIconDrop } from './components/PlusIconDrop';
import {
  useListDocumentElementByCategory,
  useListDocuments,
  useUpdateDocumentTemplate,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { TemplateMetaField } from './components/TemplateMetaField';
import { Pencil } from 'lucide-react';
import { useDebounce } from '@/Hooks/useDebounce';

type SortableContext = {
  containerId: string;
  index: number;
  items: string[];
};

type Props = {
  template: DocumentTemplateResource | undefined;
  isLoading?: boolean;
};

const values: DocumentTemplateElementResource = {
  prompt_instruction: null,
  raw_markdown: null,
  subheading: null,
};

export const Template = ({ template, isLoading }: Props) => {
  const [activeElement, setActiveElement] =
    useState<DocumentElementResource | null>(null);
  const [activeMetaFieldSlideOver, setActiveMetaFieldSlideOver] =
    useState<DocumentElementType | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const [templateInfo, setTemplateInfo] = useState({
    id: template?.id ?? -1,
    name: template?.name ?? '',
    description: template?.description ?? '',
  });
  const initialState = {
    uniqueId: '',
    documentId: '',
    preview: '',
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = '1px';
      descriptionRef.current.style.height =
        (descriptionRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  const project = useAppStore((state) => state.currentProject!.id);
  useListDocuments({ pathParams: { project } });

  const [testPromptDialog, setTestPromptDialog] = useState<{
    documentId: string;
    uniqueId: string;
    preview: string;
  }>(initialState);

  const h1TitleSection =
    template?.elements?.find((el) => el.element_type === 'title') ?? null;

  const metaTitleDescription =
    template?.elements?.find((el) => el.element_type === 'meta_description') ??
    null;

  const metaTitleSection =
    template?.elements?.find((el) => el.element_type === 'meta_title') ?? null;

  const [templateElements, setTemplateElements] = useState<{
    h1TitleSection: (DocumentElementResource & { uniqueId: string }) | null;
    metaTitleSection: (DocumentElementResource & { uniqueId: string }) | null;
    metaDescriptionSection:
      | (DocumentElementResource & { uniqueId: string })
      | null;
    bodySections: (DocumentElementResource & { uniqueId: string })[];
  }>({
    h1TitleSection: h1TitleSection
      ? { ...h1TitleSection, uniqueId: createUniqueIdentifier() }
      : null,
    metaDescriptionSection: metaTitleDescription
      ? { ...metaTitleDescription, uniqueId: createUniqueIdentifier() }
      : null,
    metaTitleSection: metaTitleSection
      ? { ...metaTitleSection, uniqueId: createUniqueIdentifier() }
      : null,
    bodySections: template?.elements
      ? template.elements
          .filter((el) => el.element_type === 'body')
          .map((el) => ({
            ...el,
            uniqueId: createUniqueIdentifier(),
          }))
      : [],
  });

  const projectId = useAppStore((state) => state.currentProject!.id);

  const listElementsQuery = useListDocumentElementByCategory({});

  const getFilteredSections = (type: DocumentElementType) =>
    (listElementsQuery.data?.data ?? [])
      .filter(
        (el) =>
          (el.elements ?? []).filter(
            (sectionElement) => sectionElement.element_type === type,
          ).length > 0,
      )
      .map((elementWithBodySections) => ({
        ...elementWithBodySections,
        elements: (elementWithBodySections.elements ?? []).filter(
          (sectionElement) => sectionElement.element_type === type,
        ),
      }));

  const updateTemplateMutation = useUpdateDocumentTemplate();

  const getElementType = (
    type: 'title' | 'meta_title' | 'meta_description',
  ) => {
    switch (type) {
      case 'title':
        return 'h1TitleSection';
      case 'meta_title':
        return 'metaTitleSection';
      default:
        return 'metaDescriptionSection';
    }
  };

  const handleSelect = (
    element: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => {
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: {
          ...element,
          values: element.values
            ? {
                ...element.values,
                subheading: {
                  title: element.values.subheading?.title ?? '',
                  type: value,
                },
              }
            : undefined,
        },
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: templateElements.bodySections.map((el) => {
        if (el.uniqueId === element.uniqueId) {
          return {
            ...el,
            values: el.values
              ? {
                  ...el.values,
                  subheading: {
                    title: el.values.subheading?.title ?? '',
                    type: value,
                  },
                }
              : undefined,
          };
        }
        return el;
      }),
    }));
  };

  const handleChange = (
    type: DocumentElementActionType,
    element: DocumentElementResource & { uniqueId: string },
    value: string,
    subHeading: boolean = false,
  ) => {
    const isPromptInstruction =
      type === 'prompt' || type === 'prompt_with_subheading';
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: {
          ...element,
          values: {
            prompt_instruction: isPromptInstruction
              ? !subHeading
                ? value
                : null
              : null,
            raw_markdown: type === 'raw' ? value : null,
            subheading:
              type === 'prompt_with_subheading'
                ? {
                    title: subHeading
                      ? value
                      : element.values?.subheading?.title ?? '',
                    type: element.values?.subheading?.type ?? 'H2',
                  }
                : null,
          },
        },
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: templateElements.bodySections.map((el) => {
        if (el.uniqueId === element.uniqueId) {
          return {
            ...el,
            values: {
              prompt_instruction: isPromptInstruction
                ? !subHeading
                  ? value
                  : null
                : null,
              raw_markdown: type === 'raw' ? value : null,
              subheading:
                type === 'prompt_with_subheading'
                  ? {
                      title: subHeading
                        ? value
                        : el.values?.subheading?.title ?? '',
                      type: el.values?.subheading?.type ?? 'H2',
                    }
                  : null,
            },
          };
        }
        return el;
      }),
    }));
  };

  const handleSaveTemplate = () => {
    const getElementBodyInTheRightOrder = [
      templateElements.h1TitleSection,
      ...templateElements.bodySections,
      templateElements.metaTitleSection,
      templateElements.metaDescriptionSection,
    ].filter((el) => el !== null) as DocumentElementResource[];

    const body = {
      description: templateInfo.description,
      name: templateInfo.name,
      elements: getElementBodyInTheRightOrder.map((el) => ({
        id: el.id,
        values: el.values
          ? {
              prompt_instruction: el.values.prompt_instruction,
              raw_markdown: el.values.raw_markdown,
              subheading: el.values.subheading
                ? {
                    title: el.values.subheading?.title,
                    type: el.values.subheading?.type,
                  }
                : undefined,
            }
          : undefined,
      })),
    };
    updateTemplateMutation.mutate({
      pathParams: {
        documentTemplate: templateInfo.id,
        project: projectId,
      },
      body,
    });
  };

  useDebounce(handleSaveTemplate, [templateElements, templateInfo]);

  const handleDelete = (
    element: DocumentElementResource & { uniqueId: string },
  ) => {
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: null,
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: prev.bodySections.filter(
        (el) => el.uniqueId !== element.uniqueId,
      ),
    }));
  };

  const handleCloseMetaFields = () => setActiveMetaFieldSlideOver(null);

  const getMetaFieldProps = (key: number, field: DocumentElementType) => ({
    key,
    onClose: handleCloseMetaFields,
    isOpen: activeMetaFieldSlideOver === field,
    elements: getFilteredSections(field),
    isLoading: listElementsQuery.isLoading,
    onClick: (el: DocumentElementResource) => {
      if (el.element_type !== 'body') {
        const key = getElementType(el.element_type);
        setTemplateElements((prev) => ({
          ...prev,
          [key]: { ...el, values, uniqueId: createUniqueIdentifier() },
        }));
        handleCloseMetaFields();
        return;
      }
      setTemplateElements((prev) => ({
        ...prev,
        bodySections: [
          ...prev.bodySections,
          { ...el, values, uniqueId: createUniqueIdentifier() },
        ],
      }));
      handleCloseMetaFields();
    },
    removeDrag: true,
  });

  const dialogProps = {
    testPromptDialog: testPromptDialog,
    onAddPreview: (preview: string) => {
      setTestPromptDialog((prev) => ({ ...prev, preview }));
    },
    onCloseDialog: () => {
      setTestPromptDialog((prev) => ({
        ...prev,
        uniqueId: '',
        preview: '',
      }));
    },
    onOpenDialog: (uniqueId: string) => {
      setTestPromptDialog((prev) => ({
        ...prev,
        uniqueId,
      }));
    },
    onAddDocumentId: (documentId?: string) => {
      setTestPromptDialog((prev) => ({
        ...prev,
        preview: '',
        documentId: documentId ?? '',
      }));
    },
  };
  return (
    <CreatePageContainer hasSlideover>
      <DndContext
        onDragStart={(e) => setActiveElement(e.active.data.current?.el)}
        onDragEnd={(e) => {
          const over = e.over;

          if (over) {
            const element = over.data.current?.el as DocumentElementResource & {
              uniqueId: string;
            };
            if (over.id === 'add-new-droppable' && activeElement) {
              setTemplateElements((prev) => ({
                ...prev,
                bodySections: [
                  ...prev.bodySections,
                  { ...element, values, uniqueId: createUniqueIdentifier() },
                ],
              }));
            }

            if (over.id.toString().includes('sortable')) {
              const elementToSwitchWith = over.data.current
                ?.sortable as SortableContext;

              const draggedElement = e.active.data.current
                ?.sortable as SortableContext;

              setTemplateElements((prev) => {
                const newElements = [...prev.bodySections];
                const element = newElements.splice(draggedElement.index, 1)[0];

                newElements.splice(elementToSwitchWith.index, 0, element);

                return { ...prev, bodySections: newElements };
              });
            }
            if (over.id.toString().includes('add-item-and-change-order-drop')) {
              const newElement = e.active.data?.current
                ?.el as DocumentElementResource;
              setTemplateElements((prev) => {
                const previousElements = prev.bodySections;
                const newElements = [...previousElements];

                const startIndex = newElements.findIndex(
                  (el) => element.uniqueId === el.uniqueId,
                );
                const start = newElements.slice(0, startIndex + 1);
                const end = newElements.slice(
                  startIndex + 1,
                  newElements.length,
                );
                return {
                  ...prev,
                  bodySections: [
                    ...start,
                    {
                      ...newElement,
                      values,
                      uniqueId: createUniqueIdentifier(),
                    },
                    ...end,
                  ],
                };
              });
            }
          }

          setActiveElement(null);
        }}
      >
        <TemplateSlideOver
          key={1}
          elements={getFilteredSections('body')}
          isLoading={listElementsQuery.isLoading}
          title="Drag elements in to include them in the template."
        />
        <TemplateSlideOver
          {...getMetaFieldProps(2, 'title')}
          title="Click on a element to include in the template."
        />
        <TemplateSlideOver
          {...getMetaFieldProps(3, 'meta_title')}
          title="Click on a element to include in the template."
        />
        <TemplateSlideOver
          {...getMetaFieldProps(4, 'meta_description')}
          title="Click on a element to include in the template."
        />
        <div className="flex w-full flex-grow flex-col justify-start overflow-y-auto pb-6">
          <div className="flex w-full justify-center">
            <div className="w-full max-w-6xl px-16">
              <div className="flex flex-col gap-4">
                {isLoading ? (
                  <SkeletonLoader />
                ) : (
                  <div className="group relative overflow-hidden">
                    <input
                      placeholder="Untitled"
                      className="w-full overflow-hidden border-none bg-primary-50 px-0 text-2xl font-bold placeholder:text-primary-400 hover:bg-primary-100 focus:bg-primary-100 focus:outline-none"
                      value={templateInfo.name}
                      onChange={(e) =>
                        setTemplateInfo((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                    <Pencil className="absolute bottom-1 right-1 top-1 my-auto font-normal text-primary-600 opacity-0 group-hover:opacity-100" />
                  </div>
                )}

                {isLoading ? (
                  <SkeletonLoader height="md" />
                ) : (
                  <div className="group relative overflow-hidden">
                    <textarea
                      placeholder="description of your template..."
                      rows={3}
                      className="w-full resize-none overflow-hidden border-0 bg-primary-50 px-0 placeholder:text-primary-400 hover:bg-primary-100 focus:bg-primary-100"
                      value={templateInfo.description}
                      ref={descriptionRef}
                      onChange={(e) =>
                        setTemplateInfo((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                    <Pencil className="absolute bottom-1 right-1 top-1 my-auto text-primary-600 opacity-0 group-hover:opacity-100" />
                  </div>
                )}

                <InputDecoration label="H1">
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : !templateElements.h1TitleSection ? (
                    <TemplateMetaField
                      text="CLICK TO ADD H1"
                      onClick={() => setActiveMetaFieldSlideOver('title')}
                    />
                  ) : (
                    <TemplateItem
                      {...dialogProps}
                      removeDrag
                      onSelect={handleSelect}
                      onDelete={handleDelete}
                      el={templateElements.h1TitleSection}
                      onChange={handleChange}
                    />
                  )}
                </InputDecoration>

                <InputDecoration label="SEO Title">
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : !templateElements.metaTitleSection ? (
                    <TemplateMetaField
                      text="CLICK TO ADD SEO TITLE"
                      onClick={() => setActiveMetaFieldSlideOver('meta_title')}
                    />
                  ) : (
                    <TemplateItem
                      {...dialogProps}
                      removeDrag
                      onSelect={handleSelect}
                      onDelete={handleDelete}
                      el={templateElements.metaTitleSection}
                      onChange={handleChange}
                    />
                  )}
                </InputDecoration>

                <InputDecoration label="Meta Description">
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : !templateElements.metaDescriptionSection ? (
                    <TemplateMetaField
                      text="CLICK TO ADD META DESCRIPTION"
                      onClick={() =>
                        setActiveMetaFieldSlideOver('meta_description')
                      }
                    />
                  ) : (
                    <TemplateItem
                      {...dialogProps}
                      removeDrag
                      onSelect={handleSelect}
                      onDelete={handleDelete}
                      el={templateElements.metaDescriptionSection}
                      onChange={handleChange}
                    />
                  )}
                </InputDecoration>
              </div>
              <div className="mt-8">
                <SortableContext
                  items={templateElements.bodySections.map(
                    (el) => 'sortable ' + el.uniqueId,
                  )}
                >
                  {isLoading ? (
                    <div className="flex flex-col">
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    templateElements.bodySections.map((el, i, array) => (
                      <div key={el.uniqueId}>
                        <TemplateItem
                          {...dialogProps}
                          onSelect={handleSelect}
                          onDelete={handleDelete}
                          el={el}
                          onChange={handleChange}
                        />
                        <PlusIconDrop
                          activeElement={activeElement}
                          el={el}
                          noIcon={i === array.length - 1}
                        />
                      </div>
                    ))
                  )}
                </SortableContext>
                {isLoading ? (
                  <SkeletonLoader height="md" />
                ) : (
                  <TemplateDrop el={activeElement} />
                )}
              </div>
            </div>
          </div>
          <DragOverlay>
            {activeElement && <TemplateDragItem el={activeElement} />}
          </DragOverlay>
        </div>
      </DndContext>
    </CreatePageContainer>
  );
};
