import { Banner } from '../Banner';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { useOrganisationSubscriptionIndex } from '@/api/openapiComponents';

type Props = {
  organisationId: number;
};

export const OutOfCreditsBanner = ({ organisationId }: Props) => {
  const navigate = useNavigate();

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: organisationId,
    },
  });

  return (
    <Banner
      isOpen={
        subscriptionQuery.data?.data.usage.credits.content.is_allowed ===
          false && subscriptionQuery.data?.data.is_canceled !== true
      }
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      Oops! You've hit your credit limit. Upgrade for more.
      <Button
        color="white"
        text="VIEW PLANS"
        size="sm"
        dense
        onClick={() => navigate({ to: '/account/subscription' })}
      />
    </Banner>
  );
};
