import * as React from 'react';
import AppLabel, { Variant } from '../../../../Components/Labels/AppLabel';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';
import {
  BrandVoiceIndexResponse,
  useProjectsBrandVoicesDestroy,
  useProjectsUpdate,
} from '@/api/openapiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeyFn } from '@/api/openapiContext';
import TextAnimation from '@/Components/TextAnimation';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { MoreVertical } from 'lucide-react';
import { BrandVoiceResource } from '@/api/openapiSchemas';

type Props = {
  bgColor?: string;
  brandVoice: BrandVoiceResource;
  description: string | JSX.Element;
  isCustomBrandVoice?: boolean;
  handleOpenDialog?: (
    mode: 'ADD' | 'EDIT' | 'EDIT_NAME',
    body?: { input: string; urls: string[]; id: number },
  ) => void;
};
const BrandVoiceCard = ({
  bgColor,
  description,
  isCustomBrandVoice,
  brandVoice,
  handleOpenDialog,
}: Props) => {
  const [active, setActive] = React.useState(false);
  const client = useQueryClient();
  const { setCurrentProject, currentProject } = useAppStore(
    useShallow((state) => ({
      setCurrentProject: state.setCurrentProject,
      currentProject: state.currentProject,
    })),
  );
  const { mutate: updateProject } = useProjectsUpdate();
  const { mutate: deleteBrandVoice } = useProjectsBrandVoicesDestroy({
    onMutate: (variables) => {
      client.setQueryData<BrandVoiceIndexResponse>(
        queryKeyFn({
          operationId: 'brandVoiceIndex',
          path: '/brand-voices',
          variables: {
            queryParams: {
              filters: { project_id: currentProject?.id },
              sort_by: 'created_at',
              sort_direction: 'DESC',
            },
          },
        }),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            data: prev.data.filter(
              (voice) => voice.id !== variables.pathParams.brandVoice,
            ),
          };
        },
      );
    },
  });

  const isDefault = currentProject?.default_brand_voice?.id === brandVoice.id;

  const isTraining = brandVoice.status === 'in_progress';

  const isFailed = brandVoice.status === 'failed';

  const handleSetAsDefault = () => {
    updateProject({
      pathParams: { project: currentProject!.id },
      body: { brand_voice_id: brandVoice.id },
    });
    setCurrentProject({
      ...currentProject!,
      default_brand_voice: brandVoice,
    });
  };

  const handleAdjustTraining = () => {
    handleOpenDialog &&
      handleOpenDialog('EDIT', {
        id: brandVoice.id,
        input: brandVoice.name,
        urls: brandVoice.urls,
      });
  };

  const handleEditName = () => {
    handleOpenDialog &&
      handleOpenDialog('EDIT_NAME', {
        id: brandVoice.id,
        input: brandVoice.name,
        urls: brandVoice.urls,
      });
  };

  const handleDelete = () => {
    deleteBrandVoice({
      pathParams: { project: currentProject!.id, brandVoice: brandVoice.id },
    });
  };

  const showDefaultButton = brandVoice.status === 'active' && !isDefault;

  return (
    <div
      className={`flex flex-col rounded-lg p-4 px-6 ${
        bgColor ? bgColor : ''
      } border shadow-xl`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {isDefault && <AppLabel variant={Variant.green}>Default</AppLabel>}
          {isTraining && (
            <AppLabel variant={Variant.yellow}>
              <div className="w-[3.6rem]">
                <TextAnimation>Training</TextAnimation>
              </div>
            </AppLabel>
          )}
          {isFailed && <AppLabel variant={Variant.red}>Failed</AppLabel>}
        </div>
        {!isDefault && (
          <PopoverMenu
            isOpen={active}
            onClose={() => setActive(false)}
            trigger={
              <IconButton
                icon={MoreVertical}
                onClick={() => setActive(!active)}
              />
            }
            items={[
              ...(showDefaultButton
                ? [
                    {
                      title: 'Set as default',
                      onClick: handleSetAsDefault,
                      disabled: isFailed || isTraining,
                    },
                  ]
                : []),
              ...(isCustomBrandVoice
                ? [
                    {
                      title: 'Adjust training',
                      onClick: handleAdjustTraining,
                      disabled: isTraining,
                    },
                    {
                      title: 'Edit name',
                      onClick: handleEditName,
                    },
                    {
                      title: 'Delete voice',
                      onClick: handleDelete,
                    },
                  ]
                : []),
            ]}
          />
        )}
      </div>
      <h1 className="text-lg font-extrabold">{brandVoice.name}</h1>
      <p className="pb-12 text-gray-500">{description}</p>
    </div>
  );
};

BrandVoiceCard.displayName = 'BrandVoiceCard';

export { BrandVoiceCard };
