import React from 'react';
import { Button, ButtonProps } from '../Button';
import { LucideProps } from 'lucide-react';

type IconButtonProps = {
  icon: React.ComponentType<LucideProps>;
} & Omit<
  ButtonProps,
  | 'title'
  | 'fullwidth'
  | 'prependIcon'
  | 'appendIcon'
  | 'alignment'
  | 'round'
  | 'fixedWidth'
>;

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => {
    const { icon, variant = 'ghost', color = 'primary' } = props;

    return (
      <Button
        ref={ref}
        round
        prependIcon={icon}
        variant={variant}
        color={color}
        {...props}
      />
    );
  },
);
