import { Calendar } from 'lucide-react';
import { Check } from 'lucide-react';
import { Button } from '@/Components/v2/Button';
import { ProjectKeywordResource, ProjectResource } from '@/api/openapiSchemas';
import { useCreate } from '@/api/openapiComponents';

type Props = {
  current_project: ProjectResource;
  keyword: ProjectKeywordResource['keyword'];
};

export const CreateKeywordButton = ({ current_project, keyword }: Props) => {
  const createPlannedContentKeyword = useCreate();

  const handleCreateKeyword = () => {
    createPlannedContentKeyword.mutate({
      pathParams: {
        project: current_project.id,
      },
      body: {
        items: [
          {
            keyword: keyword?.name,
          },
        ],
      },
    });
  };

  return (
    <Button
      onClick={handleCreateKeyword}
      size="xs"
      dense
      variant="outline"
      color="gray"
      text="Plan"
      disabled={createPlannedContentKeyword.isSuccess}
      isLoading={createPlannedContentKeyword.isPending}
      prependIcon={createPlannedContentKeyword.isSuccess ? Check : Calendar}
    />
  );
};
