import { Collapsable } from '@/Components/v2/Collapsable';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDraggable } from '@dnd-kit/core';
import { GripVertical, Plus } from 'lucide-react';

type Props = {
  el: DocumentElementResource;
  onClick?: (el: DocumentElementResource) => void;
};

export const TemplateDragItem = ({ el, onClick }: Props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'draggable' + el.id,
    data: { el },
  });

  return (
    <div className="relative w-[25rem] rounded-md border py-1.5 font-bold">
      {el.element_type === 'body' ? (
        <div
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          className="absolute top-[0.2rem]"
        >
          <IconButton icon={GripVertical} size="sm" />
        </div>
      ) : (
        <div className="absolute top-[0.2rem]">
          <IconButton
            icon={Plus}
            size="sm"
            onClick={() => onClick && onClick(el)}
          />
        </div>
      )}

      <div className="ml-6">
        <Collapsable fullWidth title={el.display_name}>
          <div className="mt-2 px-2 font-normal text-primary">
            {el.display_description}
          </div>
        </Collapsable>
      </div>
    </div>
  );
};
