import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { CardHeader } from '@/Components/v2/Card/components/CardTitle';
import { VerticalMenuItem } from '@/Components/v2/Menu/VerticalMenu';
type Props = {
  title: string;
  description: string;
  items: VerticalMenuItem[];
  onTitleClick?: () => void;
};
export const ActionCard = ({
  title,
  description,
  items,
  onTitleClick,
}: Props) => {
  return (
    <Card elevated>
      <CardHeader
        displayUntitled
        title={title}
        actions={items}
        onTitleClick={onTitleClick}
      />

      <CardBody>
        <p className="py-3 pb-12">{description}</p>
      </CardBody>
    </Card>
  );
};
