import { Dialog } from '@/Components/Dialog';
import { FormEvent, useEffect, useState } from 'react';
import ValidationErrors from '@/Components/ValidationErrors';
import { Input } from '@/Components/v2/Input/Input';
import { Button } from '@/Components/v2/Button';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useOrganisationsOrganisationInvitesStore } from '@/api/openapiComponents';

type Props = {
  isOpen: boolean;
  organisationId: number;
  onClose: () => void;
};

export const InviteUserDialog = ({
  isOpen,
  onClose,
  organisationId,
}: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const inviteMutation = useOrganisationsOrganisationInvitesStore({
    onSuccess: () => onClose(),
  });

  useEffect(() => {
    if (isOpen) {
      inviteMutation.reset();
    }
  }, [isOpen]);

  const handleInvite = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    inviteMutation.mutate({
      pathParams: {
        organisation: organisationId,
      },
      body: {
        email,
        name,
      },
    });
  };

  return (
    <Dialog
      handleClose={onClose}
      isOpen={isOpen}
      persistent={inviteMutation.isPending || inviteMutation.isSuccess}
      title="Invite user"
    >
      <form className="flex w-96 flex-col gap-4" onSubmit={handleInvite}>
        <InputDecoration label="Name" required>
          <Input placeholder="Name" value={name} onChange={setName} />
        </InputDecoration>
        <InputDecoration label="Email" required>
          <Input placeholder="Email" value={email} onChange={setEmail} />
        </InputDecoration>
        <Button
          fullWidth
          color="secondary"
          isLoading={inviteMutation.isPending || inviteMutation.isSuccess}
          type="submit"
          text="Invite"
        />
        <ValidationErrors
          apiError={inviteMutation.error}
          errors={inviteMutation.error?.errors}
        />
      </form>
    </Dialog>
  );
};
