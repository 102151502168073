import { CardSection } from '../../components/CardSection';
import { Subheading } from '../components/Subheading';
import { Pencil, Unlink } from 'lucide-react';
import { ConnectionCard } from './components/ConnectionCard';
import GoogleIcon from '../../../../../public/svg/google-icon.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { useConnectToGoogleSearchConsole } from '../hooks';
import { GSCChooseSiteDialog } from './components/GSCChooseSiteDialog';

type Props = { projectId: number };

export const Connections = ({ projectId }: Props) => {
  const {
    disconnect,
    googleDialog,
    googleSearchConsoleData,
    isGettingGoogleSearchConsoleData,
    isSelectingSiteForGoogleSearchConsole,
    isSettingUpGoogleSearchConsole,
    selectSiteForGoogleSearchConsole,
    setGoogleDialog,
    setupGoogleConsole,
    initialState,
  } = useConnectToGoogleSearchConsole(projectId);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setupGoogleConsole({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const hasNoAvailableSites =
    googleSearchConsoleData?.data.settings.available_sites.length === 0;

  return (
    <>
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSites}
        onClose={() => setGoogleDialog(initialState)}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: projectId,
            },
            body: {
              site: googleDialog.selectedSite!,
            },
          });
          setGoogleDialog(initialState);
        }}
        setGoogleDialog={setGoogleDialog}
        googleDialog={googleDialog}
      />
      <Subheading text="Simplify your workflow with our different integrations. Connect and keep everything in sync." />
      <CardSection title="Data sources">
        <>
          <ConnectionCard
            connection={{
              isLoadingInitialConnection: isGettingGoogleSearchConsoleData,
              isFetchingConnection:
                isSettingUpGoogleSearchConsole ||
                isGettingGoogleSearchConsoleData,
              onClick: login,
              state: googleSearchConsoleData?.data?.state,
            }}
            items={[
              {
                title: 'Disconnect',
                onClick: () => {
                  disconnect({
                    pathParams: {
                      project: projectId,
                      connection: googleSearchConsoleData?.data.id!,
                    },
                  });
                },
                prependIcon: Unlink,
              },
              {
                title: 'Edit',
                onClick: () => {
                  setGoogleDialog({
                    isOpen: true,
                    sites:
                      googleSearchConsoleData?.data.settings.available_sites ??
                      [],
                    selectedSite: googleSearchConsoleData?.data.settings.site,
                  });
                },
                prependIcon: Pencil,
              },
            ]}
            icon={GoogleIcon}
            title="Google Search Console"
            description="Identify keyword gaps in your content by connecting to Google
            Search Console."
          />
        </>
      </CardSection>
    </>
  );
};
