import { useDebounce } from '@/Hooks/useDebounce';
import { useEffect, useRef, useState } from 'react';

type Props = {
  text: string;
  onChange: (text: string) => void;
  onDebounceChange: (text: string) => void;
}

export const EditableText = ({
  text: propText,
  onDebounceChange,
  onChange,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setText(propText);
  }, [propText]);

  useEffect(() => {
    if (isEditing) {
      onChange(text);
    }
  }, [text]);

  useDebounce(
    () => {
      if (isEditing) {
        onDebounceChange(text);
      }
    },
    [text],
    500,
  );

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
    if (inputRef.current && !isEditing) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!rootRef.current?.contains(e.target as HTMLDivElement) && isEditing) {
        setIsEditing(false);
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [rootRef, isEditing]);

  return (
    <div
      ref={rootRef}
      className={`relative flex min-h-[2.5rem] w-full cursor-text items-center rounded-md px-1.5 py-1  ${
        isEditing
          ? 'border border-green-400'
          : 'border border-gray-200 hover:bg-gray-100'
      }`}
      onClick={handleClick}
    >
      <div className={`${isEditing ? 'hidden h-0 w-0' : 'w-full'}`}>{text}</div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setIsEditing(false);
        }}
        className="w-full"
      >
        <input
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={`border-0 border-transparent p-0 py-0  focus:ring-0 ${
            !isEditing ? 'h-0 w-0 opacity-0' : ' w-full'
          }`}
        />
        <button type="submit" className="hidden" />
      </form>
    </div>
  );
};
