import { useEffect, useRef } from 'react';

type Props = {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleOpenSlideOver?: () => void;
  placeholder: string;
};

export const CollapsableInputField = ({
  onChange,
  value,
  label,
  handleOpenSlideOver,
  placeholder,
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = '1px';
      inputRef.current.style.height =
        (inputRef.current?.scrollHeight ?? 24) + 'px';
    }
  });
  return (
    <div className="ml-1 flex">
      <p className="-ml-14 mt-1 w-9 text-end text-sm">{label}</p>
      <textarea
        placeholder={placeholder}
        onFocus={() => {
          handleOpenSlideOver && handleOpenSlideOver();
        }}
        ref={inputRef}
        className="ml-5 w-full cursor-pointer resize-none overflow-y-hidden rounded border-0 p-0 px-2 hover:bg-primary-50 focus:bg-primary-50"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
