import { Destination } from '../Destinations';
import mixPanelClient from 'mixpanel-browser';

export const mixPanel: Destination = {
  name: 'mixpanel',
  enabledByEnvKey: 'VITE_MIXPANEL_TOKEN',
  initialize: () => {
    mixPanelClient.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: !!import.meta.env.VITE_MIXPANEL_DEBUG,
    });
  },
  identify: (userId: number) => {},
  reset: () => {
    mixPanelClient.reset();
  },
  trackEvent: (event, userId) => {
    const payload = userId
      ? { ...event.payload, $user_id: `${userId}` }
      : event.payload;

    if (event.name === 'page') {
      mixPanelClient.track_pageview(payload);
      return;
    }
    mixPanelClient.track(event.displayName, payload);
  },
  ignore: ['subscription_started', 'onboarding_create_project'],
};
