import { Banner } from '../Banner';
import dayjs from 'dayjs';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { useOrganisationSubscriptionIndex } from '@/api/openapiComponents';

type Props = {
  organisationId: number;
};

export const TrialPeriodBanner = ({ organisationId }: Props) => {
  const navigate = useNavigate();

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: organisationId,
    },
  });

  return (
    <Banner
      isOpen={
        subscriptionQuery.data?.data.is_trialing &&
        subscriptionQuery.data?.data.is_canceled === false &&
        subscriptionQuery.data?.data.usage.credits.content.is_allowed === true
      }
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      <p>
        You have{' '}
        <b>
          {dayjs(subscriptionQuery.data?.data.usage.period_end).diff(
            dayjs(),
            'days',
          )}{' '}
          days{' '}
        </b>
        left of your trial subscription.
      </p>

      <Button
        color="white"
        text="UPGRADE NOW"
        size="sm"
        dense
        onClick={() => navigate({ to: '/account/subscription' })}
      />

      <Button
        color="gray"
        variant="outline"
        text="BOOK A DEMO"
        size="sm"
        dense
        onClick={() => window.open('https://seo.ai/demo')}
      />
    </Banner>
  );
};
