import SimpleInput from '@/Components/SimpleInput';
import { Button } from '@/Components/v2/Button';
import { useDebounce } from '@/Hooks/useDebounce';
import { FileDown } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { Search } from 'lucide-react';

type Props = {
  children?: ReactNode;
  search?: string;
  onSearchChange?: (value?: string) => void;
  debounceDelay?: number;
  onExportCSV?: {
    onClick: () => void;
    isLoading?: boolean;
  };
};

export const Header = ({
  children,
  search,
  onSearchChange,
  debounceDelay = 500,
  onExportCSV,
}: Props) => {
  const [searchValue, setSearchValue] = useState(search ?? '');

  useEffect(() => {
    setSearchValue(search ?? '');
  }, [search]);

  useDebounce(
    () => {
      if (onSearchChange)
        onSearchChange(searchValue.length > 0 ? searchValue : undefined);
    },
    [searchValue],
    debounceDelay,
  );

  return (
    <div className="flex min-h-[49px] items-center justify-between border-b border-gray-200 bg-gray-100">
      <div className="flex flex-grow gap-2 px-8">{children}</div>
      <div className="flex items-center gap-2">
        {onExportCSV && (
          <div>
            <Button
              variant="ghost"
              text="EXPORT TO CSV"
              prependIcon={FileDown}
              size="sm"
              isLoading={onExportCSV.isLoading}
              disabled={onExportCSV.isLoading}
              onClick={onExportCSV.onClick}
            />
          </div>
        )}

        {onSearchChange && (
          <SimpleInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            icon={<Search size={16} className="text-gray-600" />}
            containerClassname="mb-2"
            placeholder="Search..."
            className="mr-8 h-8 text-gray-500"
          />
        )}
      </div>
    </div>
  );
};
