import { Banner } from '../Banner';

import dayjs from 'dayjs';
import { Button } from '@/Components/v2/Button';
import {
  useOrganisationSubscriptionIndex,
  useOrganisationSubscriptionResume,
} from '@/api/openapiComponents';

type Props = {
  organisationId: number;
};

export const ResumeSubscriptionBanner = ({ organisationId }: Props) => {
  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: organisationId,
    },
  });

  const resumeMutation = useOrganisationSubscriptionResume({
    onSuccess: () => subscriptionQuery.refetch(),
  });

  const handleResume = () => {
    resumeMutation.mutate({
      pathParams: {
        organisation: organisationId,
      },
    });
  };

  return (
    <Banner
      isOpen={
        !!subscriptionQuery.data?.data.is_canceled && !resumeMutation.isSuccess
      }
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      <p>
        Your subscription is cancelled. You will lose access to the platform in{' '}
        <b>
          {dayjs(subscriptionQuery.data?.data.usage.period_end).diff(
            dayjs(),
            'days',
          )}{' '}
          days
        </b>
      </p>
      <Button
        color="white"
        text="REACTIVATE PLAN"
        size="sm"
        dense
        onClick={handleResume}
      />
    </Banner>
  );
};
