import { events } from './Events';
import { TrackingEvent } from './Tracking';
import { consoleDestination } from './destinations/Console';
import { googleTagManager } from './destinations/GoogleTagManager';
import { mixPanel } from './destinations/Mixpanel';
import { segment } from './destinations/Segment';

export type Destination = {
  name: string;
  enabledByEnvKey: string;
  initialize: () => Promise<void> | void;
  identify: (userId: number) => Promise<void> | void;
  reset: () => Promise<void> | void;
  trackEvent: (event: TrackingEvent, userId?: number) => Promise<void> | void;
  onlyAllow?: (keyof typeof events)[];
  ignore?: (keyof typeof events)[];
};

export const destinations = [
  segment,
  mixPanel,
  consoleDestination,
  googleTagManager,
];
