import * as React from 'react';
import { nextLine } from '@/data/initialPlateJSValue';
import deserialize from '@/Pages/Document/components/PlateEditor/lib/deserialize';
import { createUniqueIdentifier } from '@/Pages/Document/components/PlateEditor/lib/transform';
import {
  BlockType,
  InternalLinkingResource,
  LeafType,
  NodeTypesEnum,
  SlateBlockNode,
  SlateTypes,
  isLeafNode,
  isPlateTypeAlready,
  isSlateLeafNode,
} from '@/types';
import { PlateEditor } from '@udecode/plate-core';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import {
  getAnchorAndFocusPos,
  getParagraphText,
  isParagraphNode,
} from './components/PlateEditor/lib/utils';
import { BaseElement } from 'slate';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Settings } from 'lucide-react';

export const removeSpecialCharacters = (text: string) =>
  text
    .replaceAll('\n', '')
    .replaceAll('?', '')
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll(':', '')
    .replaceAll(';', '')
    .replaceAll('!', '')
    .replaceAll('"', '')
    .replaceAll("'", '')
    .replaceAll('#', '')
    .replaceAll('%', '')
    .replaceAll('&', '')
    .replaceAll('/', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('{', '')
    .replaceAll('}', '')
    .replaceAll('=', '');

export const text2words = (text: string) =>
  text === ''
    ? []
    : removeSpecialCharacters(text.toLowerCase())
        .split(' ')
        .filter((word) => word !== '');

export const getPlateJSContentWordCount = (nodes: BlockType[]) => {
  const words = [];
  nodes.forEach((node) => {
    lookForText(words, node.children);
  });
  return words.reduce((prev, curr) => {
    return prev + text2words(curr).length;
  }, 0);
};

export const getPlateJSContentWords = (nodes?: BlockType[]) => {
  if (!nodes) {
    return [];
  }
  const words: string[] = [];
  nodes.forEach((node) => {
    lookForText(words, node.children);
  });
  return words.reduce((prev, curr) => {
    return [...prev, ...text2words(curr)];
  }, [] as string[]);
};

const lookForText = (
  wordsString: string[],
  nodes: (BlockType | LeafType)[],
) => {
  return nodes.forEach((node) => {
    if (!node) {
      return nextLine;
    }
    if (!isLeafNode(node)) {
      lookForText(wordsString, node.children);
    }
    if (isLeafNode(node)) {
      wordsString.push(node.text);
    }
  });
};

export const getWordCount = (heading: string, editor: PlateEditor | null) => {
  if (!editor) {
    return 0;
  } else {
    return (
      text2words(heading).length +
      getPlateJSContentWordCount((editor?.children || []) as BlockType[])
    );
  }
};

export const getInitialPlateJSValue = (text: string) => {
  try {
    const parsedSlateASTTree: SlateBlockNode[] = JSON.parse(text);

    return parsedSlateASTTree.map((node) =>
      lookUpAndTransformSlateToPlateType(node),
    );
  } catch (error) {
    return deserialize(text);
  }
};

const lookUpAndTransformSlateToPlateType = (node: any): any => {
  if (isPlateTypeAlready(node as BlockType | SlateBlockNode)) {
    return node;
  }

  if (isSlateLeafNode(node)) {
    const { id, link, ...rest } = node;
    if (link) {
      return {
        type: NodeTypesEnum.A,
        id: createUniqueIdentifier(),
        target: undefined,
        url: link,
        children: [{ ...rest }],
      };
    }
    return {
      id: createUniqueIdentifier(),
      ...rest,
    };
  }
  switch (node.type) {
    case SlateTypes.paragraph:
      return {
        type: NodeTypesEnum.P,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_one:
      return {
        type: NodeTypesEnum.H1,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_two:
      return {
        type: NodeTypesEnum.H2,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_three:
      return {
        type: NodeTypesEnum.H3,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_four:
      return {
        type: NodeTypesEnum.H4,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_five:
      return {
        type: NodeTypesEnum.H5,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.heading_six:
      return {
        type: NodeTypesEnum.H6,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.block_quote:
      return {
        type: NodeTypesEnum.BLOCKQUOTE,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.ol_list:
      return {
        type: NodeTypesEnum.OL,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.ul_list:
      return {
        type: NodeTypesEnum.UL,
        id: createUniqueIdentifier(),
        children: node.children.map((node) =>
          lookUpAndTransformSlateToPlateType(node),
        ),
      };
    case SlateTypes.list_item:
      return {
        type: NodeTypesEnum.LI,
        id: createUniqueIdentifier(),
        children: [
          {
            type: NodeTypesEnum.LIC,
            id: createUniqueIdentifier(),
            children: node.children.map((node) =>
              lookUpAndTransformSlateToPlateType(node),
            ),
          },
        ],
      };
    default:
      return nextLine;
  }
};

export const plateToHTML = (
  children: (BlockType | LeafType)[],
  html = '',
  indent = 0,
) => {
  if (!Array.isArray(children)) {
    return '';
  }

  for (const child of children) {
    if (isLeafNode(child)) {
      if (child.bold) html += '<b>';
      if (child.italic) html += '<i>';
      if (child.underline) html += '<u>';
      if (child.strikethrough) html += '<s>';
      html += child.text.replace(/([^\n])\n([^\n])/g, '$1<br>$2');
      if (child.bold) html += '</b>';
      if (child.italic) html += '</i>';
      if (child.underline) html += '</u>';
      if (child.strikethrough) html += '</s>';
    }
    if (!isLeafNode(child)) {
      if (child.type !== 'lic')
        html += `<${child.type} ${child.url ? `href="${child.url}"` : ''}>`;

      // Handle all children of current child
      if (child.children?.length > 0) {
        html = plateToHTML(child.children, html, indent + 1);
      }

      if (child.type !== 'lic') html += `</${child.type}>`;

      if (indent === 0) {
        html += '\n\n';
      }
    }
  }

  return html;
};

export const isMarkAllText = (editorRef: PlateEditor) => {
  const selection = editorRef.selection;
  const children = editorRef.children;
  if (selection) {
    return (
      selection.anchor.offset === 0 &&
      selection.anchor.path[0] === 0 &&
      selection.anchor.path[1] === 0 &&
      selection.focus.offset === 0 &&
      selection.focus.path[0] === children.length - 1 &&
      selection.focus.path[1] === 0
    );
  }
};

export const hasNoTextMarked = (editorRef: PlateEditor) => {
  const selection = editorRef.selection;
  if (selection) {
    return (
      selection.anchor.offset === selection.focus.offset &&
      selection.anchor.path[0] === selection.focus.path[0] &&
      selection.anchor.path[1] === selection.focus.path[1]
    );
  }
};

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const getIndexRangesOfKeyword = (keyword: string, sentence: string) => {
  const searchTerm = keyword;
  const startIndex = sentence.indexOf(searchTerm);
  const endIndex = startIndex + searchTerm.length;
  return { startIndex, endIndex };
};

export const checkSpecialCharactersRegex = /.*(\,|\.|\!|\?)$/;
export const checkIfEndsWithSpecialCharacters = (sentence: string) =>
  checkSpecialCharactersRegex.test(sentence);

export const getKeywordPosInsideSentence = (
  r: InternalLinkingResource,
  paragraphText: string,
  children: (LeafType | BlockType)[],
  path: any[],
) => {
  const { startIndex } = getIndexRangesOfKeyword(
    r.linkResource.sentence,
    paragraphText,
  );

  const words = r.linkResource.sentence.split(' ');
  const start =
    startIndex + words.slice(0, r.anchorTextStartIndex!).join(' ').length + 1;
  const anchorEnd = words
    .slice(r.anchorTextStartIndex!, r.anchorTextEndIndex! + 1)
    .join(' ');
  const end = checkIfEndsWithSpecialCharacters(anchorEnd)
    ? start + anchorEnd.length - 1
    : start + anchorEnd.length;

  try {
    return getAnchorAndFocusPos(
      children,
      r.anchorTextStartIndex === 0 ? start - 1 : start,
      r.anchorTextStartIndex === 0 ? end - 1 : end,
      path,
    );
  } catch (error) {
    return null;
  }
};

export const getAnchorAndFocusPosForSentence = (
  editor: PlateEditor,
  sentence: string,
) => {
  const result = editor
    ?.nodes<BlockType | LeafType>({
      at: {
        anchor: { path: [0], offset: 0 },
        focus: { path: [editor.children.length - 1], offset: 0 },
      },
      match: (node) =>
        (isParagraphNode(node) || node.type === NodeTypesEnum.BLOCKQUOTE) &&
        getParagraphText((node as BlockType).children).includes(sentence),
    })
    .next();

  const path = [...result?.value[1]];

  const paragraphText = getParagraphText(result?.value[0].children);
  const textNodes: (BlockType | LeafType)[] = result?.value[0].children;

  const { startIndex, endIndex } = getIndexRangesOfKeyword(
    sentence,
    paragraphText,
  );
  const positions = getAnchorAndFocusPos(
    textNodes,
    startIndex,
    endIndex,
    path,
    isLeafNode(textNodes[0]) && textNodes[0].text === '',
  );
  return positions;
};

export const convertMarkdownToHTML = (markdown: string) =>
  unified()
    .use(remarkParse)
    .use(remarkGfm) // Parse markdown content to a syntax tree
    .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
    .use(rehypeStringify)
    .processSync(markdown).value as string; // Serialize HTML syntax tree

export const getMarkdownText = (markdownText: string, keyword: string) => {
  const regex = new RegExp(`\\b(${keyword})\\b`, 'gi');
  return markdownText.replace(regex, '<span class="bg-green-200">$1</span>');
};

export const isInList = (editor: PlateEditor) => {
  const [isListItem] = editor.nodes({
    match: (node) =>
      editor.isBlock(node as BaseElement) &&
      (node as BaseElement).type === NodeTypesEnum.LIC,
  });

  return !!isListItem;
};

export const sentenceExistsInEditor = (editor: PlateEditor, text: string) => {
  const lookUp = editor.nodes<BlockType | LeafType>({
    at: {
      anchor: { path: [0], offset: 0 },
      focus: { path: [editor.children.length - 1], offset: 0 },
    },
    match: (node) =>
      (isParagraphNode(node) || node.type === NodeTypesEnum.BLOCKQUOTE) &&
      getParagraphText((node as BlockType).children).includes(text),
  });
  return lookUp.next().value !== undefined;
};

export const findParagraphInEditor = (editor: PlateEditor, text: string) =>
  editor
    .nodes<BlockType | LeafType>({
      at: {
        anchor: { path: [0], offset: 0 },
        focus: { path: [editor.children.length - 1], offset: 0 },
      },
      match: (node) =>
        (isParagraphNode(node) || node.type === NodeTypesEnum.BLOCKQUOTE) &&
        getParagraphText((node as BlockType).children).includes(text),
    })
    .next();

export const sortSuggestions = (
  content: string,
  aText: string,
  bText: string,
  aKeyword?: string,
  bKeyword?: string,
) => {
  const aIndex = content.indexOf(aText);
  const bIndex = content.indexOf(bText);
  if (aKeyword && bKeyword && aIndex === bIndex) {
    const aKeywordIndex = content.indexOf(aKeyword);
    const bKeywordIndex = content.indexOf(bKeyword);
    return aKeywordIndex - bKeywordIndex;
  }
  return aIndex - bIndex;
};

export const createExportOption = ({
  title,
  mode,
  publishingDocument,
  handleEditConnection,
  icon,
  handlePublishDocument,
  handleAddConnection,
}: {
  title: string;
  mode: 'ADD_CONNECTION' | 'EDIT_CONNECTION';
  publishingDocument: boolean;
  handleEditConnection: () => void;
  icon: React.ReactNode;
  handlePublishDocument: () => void;
  handleAddConnection: () => void;
}) => ({
  title,
  subtitle: mode === 'ADD_CONNECTION' ? 'Click to Begin Setup' : undefined,
  appendIcon:
    mode === 'EDIT_CONNECTION' ? (
      <IconButton
        icon={Settings}
        isLoading={publishingDocument}
        disabled={publishingDocument}
        onClick={(e) => {
          handleEditConnection();
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    ) : undefined,
  prependIcon: icon,
  onClick:
    mode === 'ADD_CONNECTION' ? handleAddConnection : handlePublishDocument,
});

export const getStartOfDocument = (
  block: BlockType | LeafType,
  path: number[] = [0],
): number[] =>
  isLeafNode(block)
    ? [...path, 0]
    : getStartOfDocument(block.children[0], [...path, 0]);
