import { useState } from 'react';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { Column } from '../Table';

export type SubRow<T, SR> = {
  columns: {
    rightAlign?: boolean;
    render: (item: SR, row: T) => JSX.Element;
    colSpan?: number;
  }[];
  rows: (item: T) => SR[];
};

type Props<T, SR> = {
  item: T;
  renderRow?: (args: {
    item: T;
    cells: JSX.Element[];
    index: number;
    classNames: string;
  }) => JSX.Element;
  columns: Column<T>[];
  subRows?: SubRow<T, SR>;
  selectionEnabled?: boolean;
  selection?: T[];
  selectionIdentifyBy: string;
  handleSelect: (value: boolean, item: T) => void;
  rowIndex: number;
  dense: boolean;
};

const TableRow = <T, SR>({
  selection,
  selectionEnabled,
  item,
  subRows,
  renderRow,
  handleSelect,
  selectionIdentifyBy,
  columns,
  rowIndex,
  dense,
}: Props<T, SR>) => {
  const [isSubrowsOpen, setIsSubrowsOpen] = useState(false);

  const classNames = `border-b border-gray-200 ${
    item.tableRowClassNames ?? ''
  }`;

  const subRowData = subRows?.rows(item) ?? [];

  const cells = [
    ...(selectionEnabled
      ? [
          <td key={'select' + item[selectionIdentifyBy]}>
            <SimpleCheckbox
              checked={
                !!selection?.find(
                  (selectedItem) =>
                    selectedItem[selectionIdentifyBy] ===
                    item[selectionIdentifyBy],
                )
              }
              onChange={(value) => handleSelect(value, item)}
            />
          </td>,
        ]
      : []),
    ...columns.map((column, index) => (
      <td
        key={index + item[selectionIdentifyBy]}
        className={` ${dense ? 'py-0' : 'py-2'}`}
      >
        {column.render ? (
          <div className={`${column.rightAlign ? 'flex justify-end' : ''}`}>
            {column.render(item, index, {
              toggleSubRows: () => setIsSubrowsOpen(!isSubrowsOpen),
              hasSubrows: subRowData.length > 0,
              isSubrowsOpen,
              subrowCount: subRowData.length,
            })}
          </div>
        ) : (
          <div
            className={`pr-2 ${column.rightAlign ? 'flex justify-end' : ''}`}
          >
            {column.property ? (item[column.property] as string) : ''}
          </div>
        )}
      </td>
    )),
  ];

  return renderRow ? (
    <>
      {renderRow({
        cells,
        classNames,
        index: rowIndex,
        item,
      })}
      {subRows &&
        isSubrowsOpen &&
        subRowData.map((data, index) => (
          <tr key={'tr-subrow' + item[selectionIdentifyBy] + index}>
            {selectionEnabled && <td />}
            {subRows.columns.map((subRow, i) => (
              <td
                key={'td-subrow ' + item[selectionIdentifyBy] + i}
                className={` ${dense ? 'py-0' : 'py-2'} bg-primary-25`}
                colSpan={subRow.colSpan}
              >
                <div
                  className={`${subRow.rightAlign ? 'flex justify-end' : ''}`}
                >
                  {subRow.render(data, item)}
                </div>
              </td>
            ))}
          </tr>
        ))}
    </>
  ) : (
    <>
      <tr className={classNames}>{cells}</tr>
      {subRows &&
        isSubrowsOpen &&
        subRowData.map((data, index) => (
          <tr
            key={'tr-subrow' + item[selectionIdentifyBy] + index}
            className="border-b"
          >
            {selectionEnabled && <td />}
            {subRows.columns.map((subRow, i) => (
              <td
                key={'td-subrow' + item[selectionIdentifyBy] + i}
                className={` ${dense ? 'py-0' : 'py-2'} bg-primary-25`}
                colSpan={subRow.colSpan}
              >
                <div
                  className={`${subRow.rightAlign ? 'flex justify-end' : ''}`}
                >
                  {subRow.render(data, item)}
                </div>
              </td>
            ))}
          </tr>
        ))}
    </>
  );
};

TableRow.displayName = 'TableRow';

export { TableRow };
