import SimpleInput from '@/Components/SimpleInput';
import { useContext, useState } from 'react';
import { PopoverContext } from '@/Components/SimplePopover';
import { Button } from '@/Components/v2/Button';

type Props = {
  range?: {
    min?: number;
    max?: number;
  };
  values: {
    min?: number | string;
    max?: number | string;
  };
  onAccept: (values: { min?: number; max?: number }) => void;
};

export const formatRange = (values: {
  name?: string;
  min?: number;
  max?: number;
}) => {
  if (values.min === undefined && values.max === undefined) {
    return;
  }
  if (values.min !== undefined && values.max !== undefined) {
    return `${values.name ? `${values.name}: ` : ''}${values.min} - ${
      values.max
    }`;
  }
  return `${values.name ? `${values.name}: ` : ''}${
    values.min !== undefined ? `Min. ${values.min}` : ''
  } ${values.max !== undefined ? `Max. ${values.max}` : ''}`;
};

export const RangeSelector = ({ values, onAccept, range }: Props) => {
  const [min, setMin] = useState('' + (values.min ?? ''));
  const [max, setMax] = useState('' + (values.max ?? ''));

  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);

  const closePopover = useContext(PopoverContext);

  const handleAccept = () => {
    const minError = Number.isNaN(Number(min)) && min !== '';
    const maxError = Number.isNaN(Number(max)) && max !== '';
    setMinError(minError);
    setMaxError(maxError);

    if (!minError && !maxError) {
      closePopover();
      onAccept({
        min: min === '' ? undefined : Number(min),
        max: max === '' ? undefined : Number(max),
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAccept();
      }}
    >
      <div className="flex justify-between gap-2 px-2 pb-2">
        <div className="flex flex-col text-sm">
          <p className="py-1">Min</p>
          <SimpleInput
            type="number"
            value={min}
            min={range?.min}
            max={range?.max}
            onChange={(e) => setMin(e.target.value)}
            className={`-mt-2 h-8 w-24 rounded-md px-2 ${
              minError ? 'bg-red-100' : ''
            }`}
          />
        </div>
        <div className="flex flex-col text-sm">
          <p className="py-1">Max</p>
          <SimpleInput
            type="number"
            value={max}
            min={range?.min}
            max={range?.max}
            onChange={(e) => setMax(e.target.value)}
            className={`-mt-2 h-8 w-24 rounded-md px-2 ${
              maxError ? 'bg-red-100' : ''
            }`}
          />
        </div>
      </div>
      <Button
        variant="ghost"
        fullWidth
        size="sm"
        dense
        type="submit"
        text="Accept"
      />
    </form>
  );
};
