import { Dialog } from '@/Components/Dialog';
import { Collapsable } from '@/Components/v2/Collapsable';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useListDocuments, useTestSection } from '@/api/openapiComponents';
import {
  DocumentElementActionType,
  DocumentElementResource,
  HeadingType,
} from '@/api/openapiSchemas';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FlaskConical, GripVertical, Trash2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { TemplateItemInputs } from './TemplateItemInputs';
import { useEffect, useState } from 'react';
import { Button } from '@/Components/v2/Button';

type Props = {
  el: DocumentElementResource & { uniqueId: string };
  onChange: (
    type: DocumentElementActionType,
    el: DocumentElementResource & { uniqueId: string },
    value: string,
    isSubheading?: boolean,
  ) => void;
  onDelete: (el: DocumentElementResource & { uniqueId: string }) => void;
  onSelect: (
    el: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => void;
  removeDrag?: boolean;
  testPromptDialog: {
    documentId: string;
    uniqueId: string;
    preview: string;
  };
  onCloseDialog: () => void;
  onAddPreview: (preview: string) => void;
  onOpenDialog: (uniqueId: string) => void;
  onAddDocumentId: (documentId: string | undefined) => void;
};

export const TemplateItem = ({
  el,
  onChange,
  onDelete,
  onSelect,
  removeDrag,
  testPromptDialog,
  onAddPreview,
  onCloseDialog,
  onOpenDialog,
  onAddDocumentId,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: 'sortable ' + el.uniqueId });
  const project = useAppStore((state) => state.currentProject!.id);
  const [search, setSearch] = useState('');
  const documentsQuery = useListDocuments({
    pathParams: { project },
    queryParams: { filters: { search: search ? search : undefined } },
  });

  const testPromptMutation = useTestSection({
    onSuccess: (data) => {
      const key = el.element_type === 'body' ? 'text' : el.element_type;
      const preview = data.data[key];
      onAddPreview(preview);
    },
  });

  const isOpen = testPromptDialog.uniqueId === el.uniqueId;

  const handleTestPrompt = () => {
    testPromptMutation.mutate({
      pathParams: { project },
      body: {
        element_id: el.id,
        document_id: parseInt(testPromptDialog.documentId),
        values: {
          prompt_instruction: el.values?.prompt_instruction,
          raw_markdown: el.values?.raw_markdown,
          subheading: el.values?.subheading
            ? {
                title: el.values.subheading?.title,
                type: el.values.subheading?.type,
              }
            : undefined,
        },
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      if (testPromptDialog.documentId !== '' && el.prompt !== '') {
        handleTestPrompt();
      }
    }
  }, [isOpen]);

  const style = !removeDrag
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : {};

  return (
    <>
      <Dialog isOpen={isOpen} title="Test Prompt" handleClose={onCloseDialog}>
        <InputDecoration label="Document" required>
          <Autocomplete
            searchValue={search}
            onSearchChange={setSearch}
            backendSearch
            isLoading={documentsQuery.isLoading}
            options={
              documentsQuery.data?.data.map((doc) => ({
                value: doc.id,
                title: doc.title ? doc.title : 'Untitled',
                subtitle: doc.keyword.name,
              })) ?? []
            }
            onChange={(value) => {
              onAddDocumentId(value);
            }}
            value={testPromptDialog.documentId}
          />
          <div className="mt-4">
            <TemplateItemInputs
              onSelect={onSelect}
              el={el}
              onChange={onChange}
            />
            <div className="mt-4 flex w-full justify-end">
              <Button
                color="secondary"
                text={testPromptDialog.preview !== '' ? 'Test again' : 'Test'}
                onClick={handleTestPrompt}
                disabled={testPromptDialog.documentId === ''}
                isLoading={
                  documentsQuery.isLoading || testPromptMutation.isPending
                }
              />
            </div>
          </div>
          {testPromptDialog.preview !== '' && (
            <div className="mt-8 rounded-md border p-4">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose text-base leading-tight"
              >
                {testPromptDialog.preview}
              </ReactMarkdown>
            </div>
          )}
        </InputDecoration>
      </Dialog>

      <div
        style={style}
        className="relative rounded-md border bg-white px-2 py-4"
      >
        {!removeDrag && (
          <div
            className="absolute -left-10 top-2.5"
            ref={setNodeRef}
            {...attributes}
            {...listeners}
          >
            <IconButton icon={GripVertical} />
          </div>
        )}

        <Collapsable fullWidth title={el.display_name}>
          <div className="mt-2 flex flex-col gap-6 px-2">
            <TemplateItemInputs
              onSelect={onSelect}
              el={el}
              onChange={onChange}
            />
          </div>
        </Collapsable>
        <div className="absolute -right-10 top-2.5">
          <IconButton icon={Trash2} onClick={() => onDelete(el)} />
        </div>
        <div className="absolute right-10 top-2.5">
          <IconButton
            icon={FlaskConical}
            onClick={() => onOpenDialog(el.uniqueId)}
          />
        </div>
      </div>
    </>
  );
};
