import { Card } from '@/Components/v2/Card';

type Props = {
  text: string;
  onClick: () => void;
};
export const TemplateMetaField = ({ text, onClick }: Props) => {
  return (
    <div onClick={onClick} className="cursor-pointer hover:bg-secondary-100">
      <Card border="dashed" variant="outline" color="white">
        <div className="flex h-full flex-col overflow-hidden p-4">
          <div className="flex items-center justify-center gap-2">
            <p className="font-extrabold text-primary">{text}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
