import * as React from 'react';
import { BrandVoiceCard } from './components';
import { Dialog } from '@/Components/Dialog';
import { Plus, X } from 'lucide-react';
import { BrandVoiceControllerState } from '@/types';
import {
  BrandVoiceIndexResponse,
  useBrandVoiceIndex,
  useProjectsBrandVoicesUpdate,
  useProjectsBrandVoicesStore,
} from '@/api/openapiComponents';
import { useAppStore } from '../../AppLoader/stores';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeyFn } from '@/api/openapiContext';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useTrackCustomBrandVoices } from './hooks/useTrackCustomBrandVoices';
import TextAnimation from '@/Components/TextAnimation';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Subheading } from '../components/Subheading';
import { CardSection } from '../../components/CardSection';

const initialState: BrandVoiceControllerState = {
  id: -1,
  input: '',
  isOpen: false,
  urls: [''],
  mode: 'ADD',
};
const BrandVoice = () => {
  const currentProject = useAppStore((state) => state.currentProject);
  const client = useQueryClient();
  const { data: defaultBrandVoices, isLoading: isLoadingDefaultBrandVoices } =
    useBrandVoiceIndex({
      queryParams: { sort_direction: 'ASC', sort_by: 'id' },
    });

  const { data: customBrandVoices, isLoading: isLoadingCustomBrandVoices } =
    useTrackCustomBrandVoices();

  const [brandVoiceController, setBrandVoiceController] =
    React.useState<BrandVoiceControllerState>(initialState);

  const handleOpenDialog = (
    mode: 'ADD' | 'EDIT' | 'EDIT_NAME',
    body?: { input: string; urls: string[]; id: number },
  ) =>
    setBrandVoiceController((prev) => ({
      ...prev,
      isOpen: true,
      mode,
      ...body,
    }));

  const handleCloseDialog = () => setBrandVoiceController(initialState);

  const { mutate: addBrandVoice, isPending: isAddingNewBrandVoice } =
    useProjectsBrandVoicesStore({
      onSuccess: (data) => {
        client.setQueryData<BrandVoiceIndexResponse>(
          queryKeyFn({
            operationId: 'brandVoiceIndex',
            path: '/brand-voices',
            variables: {
              queryParams: {
                filters: { project_id: currentProject?.id },
                sort_by: 'created_at',
                sort_direction: 'DESC',
              },
            },
          }),
          (prev) => {
            if (!prev) {
              return prev;
            }
            return { ...prev, data: [data.data, ...prev.data] };
          },
        );
        handleCloseDialog();
      },
    });

  const { mutate: editBrandVoice, isPending: isEditingNewBrandVoice } =
    useProjectsBrandVoicesUpdate({
      onSuccess: (data) => {
        client.setQueryData<BrandVoiceIndexResponse>(
          queryKeyFn({
            operationId: 'brandVoiceIndex',
            path: '/brand-voices',
            variables: {
              queryParams: {
                filters: { project_id: currentProject?.id },
                sort_by: 'created_at',
                sort_direction: 'DESC',
              },
            },
          }),
          (prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              data: prev.data.map((voice) =>
                voice.id === data.data.id ? data.data : voice,
              ),
            };
          },
        );
        handleCloseDialog();
      },
    });

  const handleClickAddURL = () => {
    setBrandVoiceController((prev) => ({ ...prev, urls: [...prev.urls, ''] }));
  };

  const handleChangeInput = (value: string) =>
    setBrandVoiceController((prev) => ({
      ...prev,
      input: value,
    }));

  const handleChangeSpecificURL = (v: string, i: number) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      urls: prev.urls.map((value, index) => (index === i ? v : value)),
    }));
  };

  const handleRemoveSpecificURL = (i: number) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      urls: prev.urls.filter((_, index) => index !== i),
    }));
  };

  const customBrandVoicesLength = customBrandVoices?.data?.length ?? 0;

  const isTrainingMode = brandVoiceController.mode !== 'EDIT_NAME';
  return (
    <>
      <Dialog
        isOpen={brandVoiceController.isOpen}
        handleClose={handleCloseDialog}
        title={
          brandVoiceController.mode === 'ADD'
            ? 'Train a brand voice'
            : `Adjust brand voice`
        }
        size={'sm'}
      >
        <div>
          <InputDecoration label="Name" required>
            <Input
              value={brandVoiceController.input}
              onChange={handleChangeInput}
              placeholder="Professional, friendly, casual, etc."
            />
          </InputDecoration>
          {isTrainingMode && (
            <>
              <div className="mt-8">
                <InputDecoration
                  label="Input URL to train on"
                  required
                  description="Provide up to 3 URLs for the AI to learn style and tone of voice."
                >
                  <div className="flex flex-col gap-2">
                    {brandVoiceController.urls.map((url, i) => (
                      <div className="flex flex-row items-center gap-2">
                        <div className={'grow'}>
                          <Input
                            value={url}
                            placeholder="e.g. https://cnn.com/moon-landing"
                            onChange={(value) =>
                              handleChangeSpecificURL(value, i)
                            }
                          />
                        </div>
                        {i !== 0 && (
                          <div className="justify-center">
                            <IconButton
                              icon={X}
                              size={'sm'}
                              onClick={() => handleRemoveSpecificURL(i)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </InputDecoration>
              </div>
              <div className="mt-4 flex justify-center">
                <Button
                  variant={'outline'}
                  fullWidth={true}
                  text="Add Another URL"
                  prependIcon={Plus}
                  disabled={brandVoiceController.urls.length >= 3}
                  onClick={handleClickAddURL}
                />
              </div>
            </>
          )}

          <div
            className={`${
              isTrainingMode ? 'mt-12' : 'mt-6'
            } flex justify-center`}
          >
            <Button
              isLoading={isAddingNewBrandVoice || isEditingNewBrandVoice}
              onClick={() => {
                const body = {
                  name: brandVoiceController.input,
                  urls: brandVoiceController.urls,
                };

                if (brandVoiceController.mode === 'ADD') {
                  addBrandVoice({
                    pathParams: { project: currentProject!.id },
                    body,
                  });
                }
                if (brandVoiceController.mode === 'EDIT') {
                  editBrandVoice({
                    pathParams: {
                      brandVoice: brandVoiceController.id,
                      project: currentProject!.id,
                    },
                    body: { ...body, train: true },
                  });
                }
                if (brandVoiceController.mode === 'EDIT_NAME') {
                  editBrandVoice({
                    pathParams: {
                      brandVoice: brandVoiceController.id,
                      project: currentProject!.id,
                    },
                    body: { ...body, train: false },
                  });
                }
              }}
              text={isTrainingMode ? 'Start training' : 'Save'}
              color="secondary"
            />
          </div>
          {isTrainingMode && (
            <p className="mt-3 text-center text-sm text-gray-500">
              Training takes around 45-60 seconds
            </p>
          )}
        </div>
      </Dialog>
      <>
        <Subheading
          text="The brand voice controls how the AI writes. You can train the AI to
          write in a specific style and tone of voice."
          link={{ href: 'https://docs.seo.ai/brand-voice', text: 'Read more' }}
        />
        <CardSection
          isEmptyProps={{
            isEmpty: customBrandVoicesLength === 0,
            onClick: () => handleOpenDialog('ADD'),
            text: 'No custom brand voices trained yet',
            buttonText: 'ADD NEW',
          }}
          title="Custom"
          buttonProps={{
            toolTip: {
              description: 'Delete a custom brand voice to create a new one',
              title: 'Maximum reached',
            },
            text: 'ADD NEW',
            onClick: () => handleOpenDialog('ADD'),
            disabled:
              !isLoadingCustomBrandVoices && customBrandVoicesLength >= 3,
          }}
          isLoading={isLoadingCustomBrandVoices}
        >
          {customBrandVoices?.data.map((customBrandVoice) => (
            <BrandVoiceCard
              handleOpenDialog={handleOpenDialog}
              brandVoice={customBrandVoice}
              isCustomBrandVoice
              bgColor="bg-white"
              description={
                customBrandVoice.prompt_meta ? (
                  <div className="flex">
                    {customBrandVoice.status === 'in_progress'
                      ? 'Training'
                      : 'Trained'}{' '}
                    on {customBrandVoice.prompt_meta.word_count} words
                    {customBrandVoice.status === 'in_progress' ? (
                      <TextAnimation />
                    ) : (
                      '.'
                    )}
                  </div>
                ) : customBrandVoice.status === 'failed' ? (
                  ''
                ) : (
                  <TextAnimation />
                )
              }
            />
          ))}
        </CardSection>
        <CardSection
          title="Pre-defined"
          isLoading={isLoadingDefaultBrandVoices}
        >
          {defaultBrandVoices?.data.map((defaultBrandVoice) => (
            <BrandVoiceCard
              brandVoice={defaultBrandVoice}
              description={defaultBrandVoice.description}
            />
          ))}
        </CardSection>
      </>
    </>
  );
};

BrandVoice.displayName = 'BrandVoice';

export { BrandVoice };
