import { useMetaTitleState } from '@/Pages/Document/hooks';
import { CollapsableInputField } from './collapsable-input-field';

type Props = { handleOpenSlideOver: () => void };
export const TitleInput = (props: Props) => {
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  return (
    <CollapsableInputField
      {...props}
      placeholder="Click here to get SEO title ideas"
      label="TITLE"
      value={metaTitle}
      onChange={(e) => {
        setMetaTitle(e.target.value);
      }}
    />
  );
};
