import React, { ReactNode } from 'react';
import { X } from 'lucide-react';

type Props = {
  children: ReactNode | ReactNode[];
  onRemove: () => void;
};

export const FilterItem = ({ children, onRemove }: Props) => {
  const handleXClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div className="flex cursor-pointer items-center gap-2 rounded-full bg-white px-2 py-1 text-sm text-gray-600 outline outline-1 outline-gray-100 hover:outline-gray-200">
      {children} <X onClick={handleXClick} size={16} className="mt-[1px]" />
    </div>
  );
};
