import { useEffect, useState } from 'react';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { handleSplitOnPaste as handleSplitOnPaste } from '@/utils';
import { Accordion } from '@/Components/v2/Accordion';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { ListItem } from '@/Components/v2/Listitem';
import {
  useGetSuggestions,
  useProjectsTrackedKeywordsStore,
  useRankTrackingListIndex,
  useRankTrackingListItemStore,
  useRankTrackingListStore,
} from '@/api/openapiComponents';
import { KeywordResource, TrackedKeywordResource } from '@/api/openapiSchemas';
import { WarningAlert } from '@/Components/v2/Alert';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Select } from '@/Components/v2/Select';
import { Plus } from 'lucide-react';
import { Input } from '@/Components/v2/Input/Input';

type Props = {
  trackedKeywords: TrackedKeywordResource[];
  isOpen: boolean;
  onSuccess: (listId?: number) => void;
  onClose: () => void;
};

export const AddTrackedKeywordsDialog = ({
  isOpen,
  onClose,
  onSuccess,
  trackedKeywords,
}: Props) => {
  const appState = useAppStore();

  const listQuery = useRankTrackingListIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const [keywordInput, setKeywordInput] = useState('');
  const [listNameInput, setListNameInput] = useState('');
  const [selectedListId, setSelectedListId] = useState<number>();

  const createListMutation = useRankTrackingListStore();
  const addKeywordsToListMutation = useRankTrackingListItemStore();
  const rankTrackingMutation = useProjectsTrackedKeywordsStore();

  const keywords = [
    ...new Set(keywordInput.split('\n').filter((k) => k.trim() !== '')),
  ];

  const [keywordSuggestions, setKeywordSuggestions] = useState<
    (KeywordResource & { checked: boolean })[]
  >([]);

  const [currentPage, setCurrentPage] = useState(1);

  const variables = {
    pathParams: { project: appState.currentProject!.id },
  };

  const resetKeywordSuggestions = () => {
    setKeywordSuggestions([]);
    setCurrentPage(1);
    setKeywordInput('');
  };

  const {
    data: keywordSuggestionsData,
    isLoading: isLoadingKeywordSuggestions,
    isFetching: isFetchingKeywordSuggestions,
    refetch: refetchKeywordSuggestions,
  } = useGetSuggestions(variables, { enabled: false });

  const keywordsLoaded =
    !isFetchingKeywordSuggestions && !isLoadingKeywordSuggestions;

  const hasNoKeywords =
    keywordsLoaded && keywordSuggestionsData?.data.length === 0;

  useEffect(() => {
    if (isOpen) {
      refetchKeywordSuggestions().then((response) => {
        setKeywordSuggestions(
          response?.data?.data?.map((keywordSuggestion) => ({
            ...keywordSuggestion,
            checked: false,
          })) ?? [],
        );
      });
    }
  }, [isOpen]);

  const handleAddKeywords = async () => {
    if (keywordInput === '') {
      return;
    }

    const rankTrackingResponse = await rankTrackingMutation.mutateAsync({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: {
        keywords: keywords.map((keyword) => ({
          keyword,
          tags: [], // TODO: Remove when backend is ready
        })),
      },
    });

    let newListId: number | undefined;

    if (selectedListId === -1) {
      const listResponse = await createListMutation.mutateAsync({
        body: {
          name: listNameInput,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      });

      newListId = listResponse.data.id;
    }
    if (selectedListId !== undefined) {
      await addKeywordsToListMutation.mutateAsync({
        body: {
          items: rankTrackingResponse.data.map((keyword) => keyword.id),
        },
        pathParams: {
          project: appState.currentProject!.id,
          rankTrackingList: newListId ?? selectedListId!,
        },
      });
    }
    appState.refreshSubscription();
    resetKeywordSuggestions();
    onClose();
    onSuccess(newListId ?? selectedListId!);
  };

  const startOfKeywordSuggestions = currentPage * 20 - 20;
  const endOfKeywordSuggestions = currentPage * 20;

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Add keywords"
      onClose={() => {
        resetKeywordSuggestions();
        onClose();
      }}
      content={
        <div className="mb-4">
          <InputDecoration label="Keywords" required>
            <TextArea
              dense
              rows={10}
              placeholder="One keyword per line"
              value={keywordInput}
              onChange={(value) => {
                const keywords = value.split('\n');
                setKeywordSuggestions((prev) => {
                  return prev.map((keyword) => {
                    if (keywords.includes(keyword.name)) {
                      return { ...keyword, checked: true };
                    }
                    return { ...keyword, checked: false };
                  });
                });
                setKeywordInput(value);
              }}
              persistentHint
              hint={`${
                appState.subscription?.usage.credits.tracked_keywords
                  .available - keywords.length
              } keywords available`}
              onPaste={async (e) => {
                const value = await handleSplitOnPaste(e, keywordInput);
                setKeywordInput(value);
              }}
            />
          </InputDecoration>
          <div className="mt-6">
            <InputDecoration label="Add to list">
              {listQuery.isLoading ? (
                <SkeletonLoader height="sm" />
              ) : (
                <Select
                  fullWidth
                  clearable
                  value={selectedListId}
                  placeholder="No list"
                  onChange={(value) =>
                    setSelectedListId(value ? Number(value) : undefined)
                  }
                  options={[
                    {
                      title: 'Create new list',
                      value: -1,
                      prependIcon: Plus,
                    },
                    ...(listQuery.data?.data.map((list) => ({
                      title: list.name,
                      value: list.id,
                    })) ?? []),
                  ]}
                />
              )}
            </InputDecoration>

            {selectedListId === -1 && (
              <div className="mt-4">
                <InputDecoration label="New list name" required>
                  <Input
                    value={listNameInput}
                    onChange={setListNameInput}
                    counter
                    counterMax={32}
                  />
                </InputDecoration>
              </div>
            )}
          </div>
          <div className="mt-4 min-h-[calc(25vh+35px+52px+8px)]">
            <Accordion
              defaultOpen={trackedKeywords.length === 0}
              title={(open) => (
                <div className="flex h-9">
                  <ListItem
                    dense
                    prependIcon={
                      open ? (
                        <SimpleCheckbox
                          checked={
                            keywordSuggestions.length > 0 &&
                            keywordSuggestions.every(
                              (keyword) => keyword.checked,
                            )
                          }
                          onChange={(checked) => {
                            setKeywordInput(() => {
                              if (checked) {
                                return `${keywordSuggestions
                                  .map((keyword) => keyword.name)
                                  .join('\n')}`.trim();
                              }
                              return '';
                            });
                            setKeywordSuggestions((prev) =>
                              prev.map((keyword) =>
                                checked
                                  ? { ...keyword, checked: true }
                                  : { ...keyword, checked: false },
                              ),
                            );
                          }}
                        />
                      ) : undefined
                    }
                    title="Keywords you might want to track"
                  />
                </div>
              )}
            >
              {hasNoKeywords ? (
                <WarningAlert title="No suggestions found" />
              ) : (
                <div className="max-h-[25vh] overflow-y-scroll">
                  {isFetchingKeywordSuggestions && (
                    <div className="mt-2 flex animate-pulse flex-col gap-2">
                      <div className="h-9 rounded-md bg-gray-300" />
                      <div className="h-9 rounded-md bg-gray-300" />
                      <div className="h-9 rounded-md bg-gray-300" />
                      <div className="h-9 rounded-md bg-gray-300" />
                    </div>
                  )}
                  {keywordSuggestions
                    .slice(startOfKeywordSuggestions, endOfKeywordSuggestions)
                    .map((suggestion) => (
                      <ListItem
                        key={suggestion.id}
                        dense
                        prependIcon={
                          <SimpleCheckbox
                            checked={suggestion.checked}
                            onChange={() => {
                              setKeywordInput((prev) => {
                                if (
                                  prev.split('\n').includes(suggestion.name)
                                ) {
                                  return prev
                                    .split('\n')
                                    .filter((k) => k !== suggestion.name)
                                    .join('\n');
                                }
                                return `${prev}\n${suggestion.name}`.trim();
                              });
                              setKeywordSuggestions((prev) =>
                                prev.map((k) =>
                                  k.id === suggestion.id
                                    ? { ...k, checked: !k.checked }
                                    : k,
                                ),
                              );
                            }}
                          />
                        }
                        title={suggestion.name}
                      />
                    ))}
                </div>
              )}
              <div className="mt-2">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={(newPage) => setCurrentPage(newPage)}
                  lastPage={Math.floor(keywordSuggestions.length / 20) + 1}
                />
              </div>
            </Accordion>
          </div>
        </div>
      }
      confirmText="Add keywords"
      onConfirm={() => {
        handleAddKeywords().then(() => {
          resetKeywordSuggestions();
        });
      }}
      disabled={
        (appState.subscription?.usage.credits.tracked_keywords.available ?? 0) <
        keywords.length
      }
      error={rankTrackingMutation.error}
      isLoading={rankTrackingMutation.isPending}
    />
  );
};
