import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDroppable } from '@dnd-kit/core';
import { PlusCircleIcon } from 'lucide-react';
type Props = {
  noIcon?: boolean;
  el: DocumentElementResource & {
    uniqueId: string;
  };
  activeElement: DocumentElementResource | null;
};
export const PlusIconDrop = ({ noIcon, el, activeElement }: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'add-item-and-change-order-drop ' + el.uniqueId,
    data: { el },
  });

  const color = isOver ? 'bg-secondary-100 rounded-md' : '';

  return (
    <div className="relative flex w-full justify-center">
      {isOver && (
        <div className="absolute top-2 h-6 w-full rounded-xl bg-secondary-100" />
      )}
      <div className="relative h-10 w-0.5 border border-primary-300 text-center">
        {activeElement ? (
          !noIcon ? (
            <div
              ref={setNodeRef}
              className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"
            >
              <PlusCircleIcon className={'text-primary ' + color} />
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};
