import { ConfirmDialog } from '@/Components/ConfirmDialog';

import { ContentListResource } from '@/api/openapiSchemas';

type Props = {
  list?: ContentListResource;
  isLoading: boolean;
  error: any;
  keywordsToBeDeleted: { name: string; id: number }[];
  onClose: () => void;
  onSubmit: () => void;
};

export const RemoveKeywordsFromListDialog = ({
  keywordsToBeDeleted,
  onClose,
  list,
  onSubmit,
  isLoading,
  error,
}: Props) => {
  return (
    <ConfirmDialog
      isOpen={keywordsToBeDeleted.length > 0 && !!list}
      title="Remove keywords"
      confirmColor="red"
      isLoading={isLoading}
      confirmText="Remove"
      onClose={onClose}
      error={error}
      content={
        <div>
          <p className="mb-2">
            Are you sure you want to remove{' '}
            {keywordsToBeDeleted.length < 5
              ? 'the following'
              : keywordsToBeDeleted.length}{' '}
            keywords from the list <b>{list?.name}</b>?
          </p>

          {keywordsToBeDeleted.length < 5 && (
            <ul>
              {keywordsToBeDeleted.map((keyword) => (
                <li key={keyword.id}>• {keyword.name}</li>
              ))}
            </ul>
          )}
        </div>
      }
      onConfirm={onSubmit}
    />
  );
};
