import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useGetTrackedKeywordSerpOverview } from '@/api/openapiComponents';

type Props = {
  trackedKeywordId: number;
};

export const TrackedKeywordSerpOverview = ({ trackedKeywordId }: Props) => {
  const appState = useAppStore();

  const serpOverviewQuery = useGetTrackedKeywordSerpOverview(
    {
      pathParams: {
        trackedKeyword: trackedKeywordId,
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: !!trackedKeywordId,
    },
  );

  return (
    <div className="mt-4">
      {serpOverviewQuery.isLoading ? (
        <div className="flex flex-col gap-2">
          {Array.from({ length: 100 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </div>
      ) : (
        <div className="w-full overflow-hidden text-primary-600">
          <div className="flex">
            <div className="w-12 text-end">#</div>
            <div className="pl-4 text-start">SEARCH RESULTS</div>
          </div>
          <div className="flex flex-col">
            {serpOverviewQuery.data?.data.map((serp, index) => (
              <div
                key={index}
                className={`flex flex-grow items-center overflow-hidden border-b border-primary-200 ${
                  serp.is_ours ? 'bg-secondary-75' : ''
                }`}
              >
                <div className="w-12 flex-shrink-0 text-end">{index + 1}</div>
                <div className="flex flex-grow items-center gap-4 overflow-hidden py-2 pl-4">
                  <img
                    src={serp.favicon}
                    className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-full border border-primary-200"
                  />
                  <div className="flex flex-grow flex-col overflow-hidden">
                    <div className="truncate text-blue-500">{serp.title}</div>
                    <a
                      href={serp.url}
                      target="_blank"
                      className="truncate hover:opacity-50" rel="noreferrer"
                    >
                      {serp.url}
                    </a>
                  </div>
                  {serp.is_ours && (
                    <div className="pr-4 text-secondary-500">YOU</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
