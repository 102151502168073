import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { TableState } from '@/Components/Table/hooks';
import {
  ProjectDomainUrlResource,
  ProjectDomainUrlService,
} from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useProjectsDomainUrlsUpdate } from '@/api/openapiComponents';

type Props = {
  projectId: number;
  item: ProjectDomainUrlResource;
  tableState: TableState<
    'id' | 'title' | 'url' | 'primary_keyword',
    Partial<{
      search: string;
      is_active: boolean;
    }>
  >;
};

export const ProjectUrlCheckBoxButton = ({
  item,
  tableState,
  projectId,
}: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();
  const { mutate: updateTargetIsActive } = useProjectsDomainUrlsUpdate();

  const handleUpdateActive = () => {
    client.setQueriesData(
      {
        predicate: (query) =>
          query.queryKey.includes('projectsDomainUrlsIndex'),
      },
      (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: prev.data.map((currentItem) => {
            if (item.id === currentItem.id) {
              return { ...currentItem, is_active: !currentItem.is_active };
            }
            return currentItem;
          }),
        };
      },
    );
  };

  return (
    <SimpleCheckbox
      onChange={(isActive) => {
        updateTargetIsActive({
          pathParams: {
            project: projectId,
            domainUrl: item.id.toString(),
          },
          body: {
            is_active: !isActive,
            primary_keyword: item.primary_keyword ? item.primary_keyword : null,
          },
        });
        handleUpdateActive();
      }}
      checked={!item.is_active}
    />
  );
};
