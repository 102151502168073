import { ReactNode, createContext } from 'react';
import { ChevronDown } from 'lucide-react';
import { FilterItem } from './FilterItem';
import { SimplePopover } from '@/Components/SimplePopover';

export const FilterPopoverContext = createContext(() => {});

type Props = {
  name: string;
  filterName?: string | number;
  children: ReactNode;
  onRemoveFilter: () => void;
};

export const FilterPopover = ({
  children,
  onRemoveFilter,
  name,
  filterName,
}: Props) => {
  const filter = '' + (filterName ? filterName : '');

  return (
    <SimplePopover
      trigger={
        <>
          {filter && filter.length > 0 ? (
            <FilterItem onRemove={onRemoveFilter}>{filter}</FilterItem>
          ) : (
            <div className="flex items-center gap-1 text-primary-600">
              {name} <ChevronDown size={16} />
            </div>
          )}
        </>
      }
    >
      {children}
    </SimplePopover>
  );
};
