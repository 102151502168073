import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import ContentCreated from './ContentCreated';
import ContentPlanned from './ContentPlanned';
import { validateTanStackSearch } from '@/utils';

export type ContentCreatedSearchParams =
  | 'id'
  | 'title'
  | 'keyword'
  | 'score'
  | 'word_count';
export const ContentCreatedRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: validateTanStackSearch<ContentCreatedSearchParams>,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/created-content',
  component: () => <ContentCreated />,
});
export const routes = [
  ContentCreatedRoute,
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/planned-content',
    component: () => <ContentPlanned />,
  }),
];
