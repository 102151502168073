import { destinations } from './Destinations';
import { events } from './Events';

export type TrackingEvent = {
  name: keyof typeof events;
  displayName: string;
  payload: any;
};

let identifiedUserId: number | undefined = undefined;

const initialize = async () => {
  const promises: (Promise<any> | void)[] = [];
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      promises.push(destination.initialize());
    } catch (e) {
      console.error(`Error initializing ${destination.name}`, e);
    }
  }

  await Promise.all(promises);
};

const identify = (userId: number) => {
  identifiedUserId = userId;
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.identify(userId);
    } catch (e) {
      console.error(
        `Error identifying user ${userId} in ${destination.name}`,
        e,
      );
    }
  }
};

const reset = () => {
  identifiedUserId = undefined;
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.reset();
    } catch (e) {
      console.error(`Error resetting user in ${destination.name}`, e);
    }
  }
};

const event = (name: keyof typeof events, payload?: any) => {
  const event: TrackingEvent = {
    name,
    displayName: events[name],
    payload,
  };

  for (const destination of destinations) {
    if (destination.onlyAllow && !destination.onlyAllow.includes(event.name)) {
      continue;
    }
    if (destination.ignore && destination.ignore.includes(event.name)) {
      continue;
    }
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }

    try {
      destination.trackEvent(event, identifiedUserId);
    } catch (e) {
      console.error(
        `Error tracking event ${event.name} in ${destination.name}`,
        e,
      );
    }
  }
};

initialize();

export const tracking = {
  reset,
  identify,
  event,
};
