import * as React from 'react';
import {
  CalendarDays,
  Files,
  ScanSearch,
  Settings,
  UserCircle,
  ChevronDown,
  ChevronUp,
  Search,
  PlusIcon,
  LineChart,
  MessageCircleQuestion,
  Plus,
  ChevronsRight,
  ChevronsLeft,
} from 'lucide-react';
import ReactCountryFlag from 'react-country-flag';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  ProjectResource,
  SubscriptionResource,
  UserResource,
} from '@/api/openapiSchemas';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton.tsx';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { SidebarMenuItem } from './SidebarMenuItem';

const checkForHighlight = (pathName: string) =>
  window.location.pathname === pathName ? true : false;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  projects: ProjectResource[];
  current_project: ProjectResource;
  usage: SubscriptionResource['usage'];
  auth: { user: UserResource & { org_id: number } };
  subscription: SubscriptionResource;
};

const Sidebar = (props: Props) => {
  const appState = useAppStore();
  const { hasFeature } = useFeatureFlagging();
  const [projectPopup, setProjectPopup] = React.useState(false);
  const [projects, setProjects] = React.useState(props.projects);
  const [smallScreen, setSmallScreen] = React.useState({
    isOpen: false,
    isSmallScreen: false,
  });

  const navigate = useNavigate();

  const projectMenuRef = React.useRef<HTMLDivElement | null>(null);

  const firstNavigationMenu = [
    {
      name: 'Created content',
      href: '/created-content',
      icon: Files,
      current: checkForHighlight('/created-content'),
    },
    {
      name: 'Planned content',
      href: '/planned-content',
      icon: CalendarDays,
      current: checkForHighlight('/planned-content'),
    },
    {
      name: 'Rank tracking',
      href: '/rank-tracking',
      icon: LineChart,
      current: checkForHighlight('/rank-tracking'),
    },
    ...(hasFeature('new-templates')
      ? [
          {
            name: 'Templates',
            href: '/templates',
            icon: Files,
            tooltip: appState.subscription?.permissions
              .custom_document_templates
              ? undefined
              : {
                  text: 'Upgrade to a higher plan to get access to this feature.',
                  navLink: '/account/subscription',
                  navLinkText: 'See pricing',
                },
            disabled:
              !appState.subscription?.permissions.custom_document_templates,
            current: checkForHighlight('/templates'),
          },
        ]
      : []),
    {
      name: 'Settings',
      href: '/settings/edit-project',
      icon: Settings,
      current: window.location.pathname.includes('settings'),
    },
  ];

  const secondNavigationMenu = [
    {
      name: 'Keyword research',
      href: '/keyword-research/ai-keywords',
      icon: ScanSearch,
      current: window.location.pathname.includes('keyword-research'),
    },
  ];

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setProjects(
      props.projects.filter(
        (org) =>
          org.domain.toLowerCase().includes(value) ||
          org.name.toLowerCase().includes(value),
      ),
    );
  };

  const navigateToCreateProject = () => {
    navigate({ to: `/create-project` });
  };

  const toggleProjectPopup = () => {
    setProjectPopup((prev) => !prev);
  };

  const thirdNavigationMenu = [
    {
      name: 'Support & tutorials',
      href: 'https://docs.seo.ai/',
      icon: MessageCircleQuestion,
      current: false,
    },

    {
      name: 'Account & credits',
      href: '/account',
      icon: UserCircle,
      current: checkForHighlight('/account'),
    },
  ];

  const handleNewDocument = () => {
    navigate({ to: '/create-content' });
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setSmallScreen((prev) => ({ ...prev, isSmallScreen: false }));
    } else {
      setSmallScreen((prev) => ({ ...prev, isSmallScreen: true }));
    }
  };

  React.useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!projectMenuRef.current?.contains(e.target as HTMLDivElement)) {
        const element = e.target as HTMLDivElement;
        if (element.id !== 'project-menu-dropdown') {
          setProjectPopup(false);
        }
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [projectMenuRef]);

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {smallScreen.isSmallScreen && !smallScreen.isOpen && (
        <div className="w-[108px] bg-primary-100 lg:hidden">
          <IconButton
            onClick={() =>
              setSmallScreen((prev) => ({ ...prev, isOpen: true }))
            }
            icon={ChevronsRight}
          />
        </div>
      )}

      <div
        className={`${
          smallScreen.isOpen && smallScreen.isSmallScreen
            ? 'flex flex-col'
            : 'hidden border-r border-primary-200 lg:flex lg:flex-col'
        }`}
      >
        {smallScreen.isSmallScreen && (
          <div className="bg-primary-50">
            <div className={'float-right'}>
              <IconButton
                onClick={() =>
                  setSmallScreen((prev) => ({ ...prev, isOpen: false }))
                }
                size="xl"
                icon={ChevronsLeft}
              />
            </div>
          </div>
        )}

        <div className="flex w-72 grow flex-col gap-y-7 overflow-y-auto bg-primary-50 px-4 py-4">
          <div
            ref={projectMenuRef}
            id="project-menu-dropdown"
            className="relative -mb-4"
          >
            {projectPopup && (
              <div
                className={`absolute left-0 right-0 top-[100%] z-20 transition-opacity duration-300`}
              >
                <div className="z-20 rounded-lg border border-primary-200 bg-white pt-4 text-primary">
                  <div className="flex w-full px-4">
                    <div className="flex h-9 w-full items-center rounded-full border bg-white px-4">
                      <div className="mr-3 flex h-5 items-center">
                        <Search width={16} height={16} />
                      </div>
                      <div className="flex h-5 items-center">
                        <input
                          onChange={(e) => handleFilter(e)}
                          type="text"
                          className="h-full w-full border-0 p-0  placeholder:text-gray-300 focus:outline-none focus:ring-0"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slim-scroll my-3 flex max-h-[200px] w-full flex-wrap overflow-y-auto px-4">
                    {projects.map((project, index) => {
                      const { name, language } = project;
                      return (
                        <div
                          key={index}
                          className={'group flex w-full justify-between py-1.5'}
                        >
                          <div className="mr-3 flex h-6 w-6 shrink-0 items-center">
                            <ReactCountryFlag
                              countryCode={language?.country_code!}
                              svg
                              style={{
                                height: '18px',
                                width: '23px',
                              }}
                            />
                          </div>
                          <div
                            onClick={() => {
                              appState.setCurrentProject(project);
                              setProjectPopup(false);
                            }}
                            className="mr-2 flex flex-grow cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-primary hover:text-primary-800"
                          >
                            {name}
                          </div>
                          <div
                            onClick={() => {
                              appState.setCurrentProject(project);
                              setProjectPopup(false);
                              navigate({ to: `/settings/edit-project` });
                            }}
                            className="flex shrink-0 cursor-pointer items-center"
                          >
                            <Settings
                              className="invisible h-4 w-4 stroke-gray-400 group-hover:visible"
                              width={16}
                              height={16}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={navigateToCreateProject}
                    className="border-button-grey group flex w-full cursor-pointer gap-3 border-t p-3"
                  >
                    <PlusIcon className="stroke-gray-400" />
                    <div className="text-primary hover:text-primary-800">
                      Create website
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              onClick={toggleProjectPopup}
              className="group mb-2 flex w-full cursor-pointer justify-between rounded-lg py-2 font-work hover:bg-primary-125"
            >
              <div className="flex w-full items-center px-2">
                <div className="mr-3 flex h-6 w-6 shrink-0 items-center">
                  <ReactCountryFlag
                    countryCode={props.current_project.language.country_code!}
                    svg
                    style={{
                      height: '18px',
                      width: '23px',
                    }}
                  />
                </div>
                <div className="flex flex-grow flex-col overflow-hidden">
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap font-bold">
                    {props.current_project.name}
                  </div>
                </div>
                <div className="mr-2 flex w-max flex-shrink-0 items-center justify-center">
                  {projectPopup ? (
                    <ChevronUp className="pointer-events-none stroke-primary" />
                  ) : (
                    <ChevronDown className="pointer-events-none stroke-primary" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <Button
            prependIcon={Plus}
            color={'green'}
            variant={'outline'}
            text={'Create content'}
            onClick={handleNewDocument}
          />

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="space-y-1">
                  {firstNavigationMenu.map((item) => (
                    <SidebarMenuItem item={item} key={item.name} />
                  ))}
                </ul>
              </li>
              <li>
                <div className="ml-2 text-sm font-semibold leading-6 text-primary">
                  TOOLS
                </div>
                <ul role="list" className="mt-2 space-y-1">
                  {secondNavigationMenu.map((item) => (
                    <SidebarMenuItem item={item} key={item.name} />
                  ))}
                </ul>
              </li>
              <ul className="mb-4 mt-auto space-y-1">
                {thirdNavigationMenu.map((item) => (
                  <li key={item.name} className="mt-auto">
                    <Link
                      target={item.href.includes('http') ? '_blank' : ''}
                      to={item.href}
                      onClick={(e) => {
                        if (item.name === 'Website settings') {
                          e.preventDefault();
                          navigate({ to: `/settings/edit-project` });
                        }
                      }}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-primary-125 text-primary-800'
                          : 'text-primary-500 hover:bg-primary-125 hover:text-primary-800',
                        'group flex gap-x-3 rounded-md p-2 font-bold leading-6',
                      )}
                    >
                      <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

Sidebar.displayName = 'Sidebar';

export { Sidebar };
